import React, { useContext } from 'react'
import styled from 'styled-components'
import { HelpInformation } from '@/main/components/help-information'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { DigitalStoreStatusDefaultContentMessages } from '@/domain/models/messages/messages'
import { DigitalStoreCurrentStatus } from '@/domain/models/digital-store'
import { IdentityContext } from '@/main/contexts'
import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import { DigitalStoreStatusWarningPanel } from '../components/digital-store-status-warning-panel'
import { DigitalStoreAddressPanel } from '../components/digital-store-address-panel'
import { DigitalStoreStatusFooter } from '../components/digital-store-status-footer'

import { DigitalStoreStatusBaseCard } from '../components/digital-store-status-base-card'

const DigitalStoreAddressContainer = styled.span`
  margin-bottom: 4rem;
`

export const DigitalStoreStatusDefaultContent = ({
 isLoading, digitalStoreStatus, hasDigitalStoreApproved,
}) => {
  const { tenantId } = useContext(IdentityContext)
  const vendor = useTenantVendor(tenantId)
  const pageMessagesOptions = { vendorName: vendor.name }
  const status: DigitalStoreCurrentStatus = digitalStoreStatus?.status || DigitalStoreCurrentStatus.NOT_STARTED
  const messages = usePageMessages(Page.DigitalStoreStatusDefaultContent, pageMessagesOptions).messages as DigitalStoreStatusDefaultContentMessages
  const statusMessages = messages.statusMessages[status]
  const digitalStoreDomain = `${messages.digitalStoreUrl}/${digitalStoreStatus?.digitalStoreName}`

  return (
    <DigitalStoreStatusBaseCard
      isLoading={isLoading}
      statusType={statusMessages?.type}
      statusMessage={statusMessages?.message}
      title={statusMessages?.title}
      subtitle={statusMessages?.subtitle}
      hasDigitalStoreApproved={hasDigitalStoreApproved}
    >
      {statusMessages?.warning?.title && statusMessages?.warning?.message && <DigitalStoreStatusWarningPanel title={statusMessages.warning.title} content={statusMessages.warning?.message} />}
      {hasDigitalStoreApproved && (
        <DigitalStoreAddressContainer>
          <DigitalStoreAddressPanel digitalStoreDomain={digitalStoreDomain} digitalStoreAddressText={messages.digitalStoreDigitalSpaceAddress} />
        </DigitalStoreAddressContainer>
      )}
      <HelpInformation
        title={messages.contactInfoHelpTitle}
        content={messages.contactInfoHelp}
      />
      {statusMessages?.footer && <DigitalStoreStatusFooter content={statusMessages.footer} />}
    </DigitalStoreStatusBaseCard>
  )
}

import React from 'react'
import { ControlledTextField } from '@/main/components'
import { useFormContext } from 'react-hook-form'
import { RequiredErrorMessage } from '@/domain/models/messages/messages'

export function validateZipCode(lengthValue: number): (value: number) => boolean {
  return (value) => value.toString().length >= lengthValue
}

export type ZipCodeMessages = {
    label:string,
    placeholder: string,
    errorMessage:string
  } & RequiredErrorMessage

export type ZipCodeTextFieldProps = {
  messages: ZipCodeMessages;
  showAsteriskInRequiredLabel: boolean;
}

export const ZipCodeTextField: React.FC<ZipCodeTextFieldProps> = ({ messages, showAsteriskInRequiredLabel }) => {
  const zipCodeValidChars = new RegExp(/^\d{0,5}$/)
  const zipCodeRules = {
    rules: {
      required: messages.requiredErrorMessage,
      validate: validateZipCode(5),
    },
  }
  const { control } = useFormContext()

  return (
    <ControlledTextField
      id="zipCode"
      label={messages.label}
      control={control}
      placeholder={messages.placeholder}
      errorMessage={messages.errorMessage}
      rules={zipCodeRules.rules}
      validChars={zipCodeValidChars}
      showAsterisk={showAsteriskInRequiredLabel}
      required
    />
  )
}

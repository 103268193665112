import React from 'react'
import { DigitalStoreUrlSection, DigitalStoreAddressLabel, BoldTextParagraph } from './digital-store-url.styles'

export type DigitalStoreUrlProps = {
  className?: string
  addressLabel: string
  baseUrl: string
  digitalStoreName: string
}

export const DigitalStoreUrl = ({
 className, addressLabel, baseUrl, digitalStoreName,
} : DigitalStoreUrlProps) => (
  <DigitalStoreUrlSection className={className}>
    <DigitalStoreAddressLabel content={addressLabel} />
    <BoldTextParagraph
      content={`${baseUrl}${digitalStoreName}`}
    />
  </DigitalStoreUrlSection>
)

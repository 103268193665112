import { HttpPostClient } from '@/data/protocols/http'
import { CompanyId } from '@/domain/models/company/company-id'
import {
  SendAuthorizationCode,
  SendAuthorizationCodeParams,
} from '@/domain/use-cases/authorization-code/authorization-code'
import { CountryId } from '@/domain/models/country'
import {
  BUSINESS_MODELS,
  FUNCTION_IDS,
  PhoneType,
  ROLES,
} from '@/domain/models/person'
import { isAuthorizationCodeEmailToggleActive } from '@/toggles-config'

type AuthorizationCodeServiceHttpRequestHeaders = {
  companyId: CompanyId
  userId: string
  countryId: CountryId
  authorization: string
  businessModel: BUSINESS_MODELS,
  role: ROLES,
  functionId: FUNCTION_IDS,
  sourceSystem: string,
}

type AuthorizationCodeServiceHttpRequestBody = {
  personId: string
  phoneNumber?: string
  email?: string
}

export class RemoteSendAuthorizationCode implements SendAuthorizationCode {
  constructor(
    private readonly httpPostClient: HttpPostClient<AuthorizationCodeServiceHttpRequestHeaders, AuthorizationCodeServiceHttpRequestBody>,
  ) {
  }

  async sendAuthorizationCode(sendAuthorizationCodeParams: SendAuthorizationCodeParams): Promise<boolean> {
    const headers = {
      companyId: sendAuthorizationCodeParams.companyId,
      userId: sendAuthorizationCodeParams.userId,
      countryId: sendAuthorizationCodeParams.countryId,
      authorization: sendAuthorizationCodeParams.authorization,
      businessModel: sendAuthorizationCodeParams.businessModel,
      role: sendAuthorizationCodeParams.role,
      functionId: sendAuthorizationCodeParams.functionId,
      sourceSystem: sendAuthorizationCodeParams.sourceSystem,
    }

    const body: AuthorizationCodeServiceHttpRequestBody = this.buildRequestBody(sendAuthorizationCodeParams)

    const response = await this.httpPostClient.post({
      headers,
      body,
      url: '/authorization-code/send-sms',
    })

    return response.body?.showAuthorizationCodePage
  }

  private buildRequestBody(sendAuthorizationCodeParams: SendAuthorizationCodeParams): AuthorizationCodeServiceHttpRequestBody {
    const {
      personId, email, phoneNumber, phoneType,
    } = sendAuthorizationCodeParams
    if (isAuthorizationCodeEmailToggleActive()) {
      return phoneType === PhoneType.CELLULAR
        ? { personId, phoneNumber, email }
        : { personId, email }
    }
    return { personId, phoneNumber }
  }
}

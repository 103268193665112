import React, { useContext, useEffect, useState } from 'react'
import { useI18n } from '@/main/hooks/useI18n'
import { Approved } from '@/main/pages/confirmation/approved/approved'
import { CountryId } from '@/domain/models/country'
import { IndicationInfo } from '@/data/use-cases/indication/remote-search-indication'
import { FormContext, FormContextProps } from '@/main/contexts'
import { CompanyId } from '@/domain/models/company/company-id'
import { STEPS_BY_COUNTRY } from '@/main/pages/confirmation/approved/steps-by-country'
import { makeRemoteSearchIndication } from '@/main/factories/remote/indication'
import { useGetDigitalStoreStatusesSWR } from '@/main/hooks/swrServices'
import { PaymentPlanId } from '@/domain/models/paymentPlan/payment-plan-id'

export type ApprovedMessages = {
  title: string
  subtitle: string
  description: string
  consultantCodeLabel: string
  paymentLabel: string
  indicationLabel: string,
  digitalStore?: {
    title: string,
    description: string,
    linkTitle: string,
    link: string,
    warningTitle: string,
    warningDescription: string
  },
  nextSteps: {
    title: string
    step1: string
    step2: string
    step3: string
  }
  businessLeader: {
    title: string
    description: string
  }
  footer: {
    description: string
    appleStoreHref: string
    playStoreHref: string
  }
  paymentPlan: {
    prePaid: string
    credit: string
    denied: string
    unset: string
  }
  iconAlts: {
    approved: string
    consultant: string
    appleBanner: string
    playBanner: string
  }
}

type ApprovedWrapperProps = {
  personId: string,
  approvedCandidateId?: string
  countryId?: CountryId
  companyId?: CompanyId
  paymentPlanId?: PaymentPlanId
}

export const ApprovedWrapper: React.FC<ApprovedWrapperProps> = ({
  countryId,
  companyId,
  approvedCandidateId,
  personId,
  paymentPlanId,
}) => {
  const { translate } = useI18n()
  const {
    person,
    completedInformation: {
      shouldSearchIndication,
    },
    hasDigitalStore,
  } = useContext<FormContextProps>(FormContext)

  const { digitalStoreStatus } = useGetDigitalStoreStatusesSWR({
    countryId, personId, shouldFetchOnStart: hasDigitalStore,
  })

  const digitalStoreName = digitalStoreStatus?.digitalStoreName || ''

  const messagesWithoutDigitalStore: ApprovedMessages = {
    title: translate('completedPage.approved.title'),
    subtitle: translate('completedPage.approved.subtitle'),
    description: translate('completedPage.approved.description'),
    consultantCodeLabel: translate(
      'completedPage.approved.consultantCodeLabel',
    ),
    paymentLabel: translate('completedPage.approved.paymentLabel'),
    indicationLabel: translate('completedPage.approved.indicationLabel'),
    nextSteps: {
      title: translate('completedPage.approved.nextSteps.title'),
      step1: translate('completedPage.approved.nextSteps.step1'),
      step2: translate('completedPage.approved.nextSteps.step2'),
      step3: translate('completedPage.approved.nextSteps.step3'),
    },
    businessLeader: {
      title: translate('completedPage.approved.businessLeader.title'),
      description: translate(
        'completedPage.approved.businessLeader.description',
      ),
    },
    footer: {
      description: translate('completedPage.approved.footer.description'),
      playStoreHref: translate('completedPage.approved.footer.playStoreHref'),
      appleStoreHref: translate('completedPage.approved.footer.appleStoreHref'),
    },
    paymentPlan: {
      credit: translate('completedPage.approved.paymentPlan.credit'),
      prePaid: translate('completedPage.approved.paymentPlan.prePaid'),
      unset: translate('completedPage.approved.paymentPlan.unset'),
      denied: translate('completedPage.approved.paymentPlan.denied'),
    },
    iconAlts: {
      approved: translate('completedPage.approved.iconAlts.approved'),
      consultant: translate('completedPage.approved.iconAlts.consultant'),
      appleBanner: translate('completedPage.approved.iconAlts.appleBanner'),
      playBanner: translate('completedPage.approved.iconAlts.playBanner'),
    },
  }

  const messagesWithDigitalStore: ApprovedMessages = {
    ...messagesWithoutDigitalStore,
    digitalStore: {
      title: translate('completedPage.approved.digitalStore.title'),
      description: translate('completedPage.approved.digitalStore.description'),
      linkTitle: translate('completedPage.approved.digitalStore.linkTitle'),
      link: translate('completedPage.approved.digitalStore.link', { digitalStoreName }),
      warningTitle: translate('completedPage.approved.digitalStore.warningTitle'),
      warningDescription: translate('completedPage.approved.digitalStore.warningDescription'),
    },
  }

  const [indication, setIndication] = useState<IndicationInfo>()
  const remoteSearchIndication = makeRemoteSearchIndication()

  const messages: ApprovedMessages = hasDigitalStore
    ? messagesWithDigitalStore
    : messagesWithoutDigitalStore

  const hasIndication = person?.introducerSellerInformation?.introducerSellerId

  const getPaymentPlanTranslationKey = () => {
    const paymentPlanTranslation = {
      [PaymentPlanId.Credit]: 'credit',
      [PaymentPlanId.PrePaid]: 'prePaid',
      [PaymentPlanId.Denied]: 'denied',
    }
    if (paymentPlanId) {
      return paymentPlanTranslation[paymentPlanId]
    }
    return 'unset'
  }

  useEffect(() => {
    if (hasIndication && shouldSearchIndication) {
      fetchIndication()
    }

    async function fetchIndication() {
      try {
        const indicationResponse = await remoteSearchIndication.search({
          id: person.introducerSellerInformation.introducerSellerId,
          companyId,
          countryId,
        })
        setIndication(indicationResponse.data)
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
    }
  }, [person, companyId, countryId, shouldSearchIndication, hasIndication])

  return (
    <Approved
      messages={messages}
      approvedCandidateId={approvedCandidateId}
      indicationName={indication?.name}
      steps={STEPS_BY_COUNTRY[countryId]}
      paymentPlanKey={getPaymentPlanTranslationKey()}
      hasDigitalStore={hasDigitalStore}
    />
  )
}

import React, { useContext, useState } from 'react'
import { Divider, Button } from '@naturacosmeticos/natds-web'

import { makeConvertCountryIdFromAlpha2ToAlpha3Presenter } from '@/main/factories/presenters'
import { CountryIdAlpha2 } from '@/domain/models/country'
import { AgreementProduct } from '@/domain/models/agreement/agreement-product'
import { IdentityContext } from '@/main/contexts/identity-context'
import { AcceptTermsComponent } from '@/main/components/accept-terms'
import { HelpInformation } from '@/main/components/help-information'

import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { DigitalStoreStatusPendingAgreementsMessages } from '@/domain/models/messages/messages'
import { DigitalStoreCurrentStatus } from '@/domain/models/digital-store'
import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import { DigitalStoreAddressPanel } from '../components/digital-store-address-panel'
import { DigitalStoreStatusFooter } from '../components/digital-store-status-footer'
import { DigitalStoreStatusBaseCard } from '../components/digital-store-status-base-card'

import { AgreementsPanelContainer } from './digital-store-status-page.styles'
import { useSaveDigitalStore } from '../hooks/use-save-digital-store'

export const DigitalStoreStatusPendingAgreements = ({
 digitalStoreStatus, isLoading, hasDigitalStoreApproved, onSave, hasCustomFlowAgreementsEnabled,
}) => {
  const status: DigitalStoreCurrentStatus = digitalStoreStatus?.status || DigitalStoreCurrentStatus.NOT_STARTED
  const { countryId, personId, tenantId } = useContext(IdentityContext)
  const vendor = useTenantVendor(tenantId)
  const pageMessagesOptions = { vendorName: vendor.name }
  const messages = usePageMessages(Page.DigitalStoreStatusPendingAgreements, pageMessagesOptions).messages as DigitalStoreStatusPendingAgreementsMessages
  const statusMessages = messages.statusMessages[status]

  const convertCountryIdFromAlpha2ToAlpha3Presenter = makeConvertCountryIdFromAlpha2ToAlpha3Presenter()
  const country3AlphaCode = convertCountryIdFromAlpha2ToAlpha3Presenter.handle({ countryId: countryId as unknown as CountryIdAlpha2 })

  const { saveDigitalStore } = useSaveDigitalStore({
    countryId: country3AlphaCode,
    personId,
    digitalStoreName: digitalStoreStatus?.digitalStoreName,
  })
  const [isAgreementsAccepted, setIsAgreementsAccepted] = useState(false)
  const [userNeedsToAcceptTerms, setUserNeedsToAcceptTerms] = useState(true)
  const [isLoadingAgreements, setIsLoadingAgreements] = useState(true)
  const [agreementId, setAgreementsId] = useState<string[]>([])

  const getDigitalStoreAddressPanel = () => {
    const digitalStoreDomain = `${messages.digitalStoreUrl}/${digitalStoreStatus?.digitalStoreName}`
    return (
      <DigitalStoreAddressPanel disableLink digitalStoreDomain={digitalStoreDomain} digitalStoreAddressText={messages.digitalStoreDigitalSpaceAddress} />
      )
  }

  const onClickAcceptAgreements = async (agreementId, accepted) => {
    await saveDigitalStore({ agreementId, accepted })
    onSave()
  }

  const getCustomFlowAgreementsContent = () => (
    <>
      <AgreementsPanelContainer data-testid="agreements-panel-container">
        <Divider />
        <AcceptTermsComponent
          personId={personId}
          countryId={countryId}
          products={[AgreementProduct.DIGITAL_STORE]}
          setAgreementId={setAgreementsId}
          setUserNeedsToAcceptTerms={setUserNeedsToAcceptTerms}
          shouldHide={!userNeedsToAcceptTerms}
          agreementLabel={messages.digitalStoreTermsAcceptance}
          agreementLabelLink={messages.digitalStoreTermsAcceptanceLink}
          termsInfo={messages.digitalStoreTermsInfo}
          modalTitle={messages.digitalStoreTermsAcceptanceModalTitle}
          modalAccept={messages.digitalStoreTermsAcceptanceModalAccept}
          modalClose={messages.digitalStoreTermsAcceptanceModalClose}
          onLoading={setIsLoadingAgreements}
          CheckboxProps={{
              value: isAgreementsAccepted,
              onChange: (hasAcceptedAgreement) => setIsAgreementsAccepted(hasAcceptedAgreement),
            }}
        />
        <Button
          color="primary"
          size="large"
          variant="contained"
          disabled={isLoadingAgreements || !isAgreementsAccepted}
          onClick={() => onClickAcceptAgreements(agreementId[0], isAgreementsAccepted)}
          data-testid="accept-terms-button"
        >
          ACEPTAR
        </Button>
        <Divider />
      </AgreementsPanelContainer>
    </>
  )

  return (
    <DigitalStoreStatusBaseCard
      isLoading={isLoading}
      statusMessage={statusMessages.description}
      title={statusMessages.title}
      hasDigitalStoreApproved={hasDigitalStoreApproved}
    >
      {getDigitalStoreAddressPanel()}
      {hasCustomFlowAgreementsEnabled && getCustomFlowAgreementsContent()}
      <HelpInformation
        title={messages.contactInfoHelpTitle}
        content={messages.contactInfoHelp}
      />
      {statusMessages?.footer && <DigitalStoreStatusFooter content={statusMessages.footer} />}
    </DigitalStoreStatusBaseCard>
  )
}

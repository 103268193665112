import styled from 'styled-components'
import { Typography } from '@naturacosmeticos/natds-web'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'
import { Card } from '@/main/components'

export const ContentSuccess = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  border-radius: 6px;
  max-width: 608px;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    padding-top: 36px;
  }
`

export const SuccessTitle = styled(Typography)`
  &.success_title{
    color: ${({ theme }) => theme.color.success};
    font-weight: bold;
    margin-top: ${({ theme }) => theme.sizes.tiny}px;
    text-align: center;
  }
`

export const DescriptionContainer = styled.div`
  margin:  ${({ theme }) => theme.sizes.small}px 0;
`

export const Number = styled(Typography)`
  background-color: ${({ theme }) => theme.color.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;

  &.step_number {
    margin-right: 16px;
  }
`

export const StepContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
  padding: 10px 0;
`

export const TextDescription = styled(Typography)`
  &.step__description {
    width: calc(100% - 56px);

    & strong {
      color: ${({ theme }) => theme.color.mediumEmphasis};
    }
  }
`

export const StepContent = styled.div`
  margin-top: 20px;
`

export const StepTitle = styled(Typography)`
  &.step_title {
    margin-bottom: 16px;
    align-self: baseline;
    left: 0;

    &:before {
      content: '';
      border-right: ${({ theme }) => `4px solid ${theme.color.primary}`};
      border-radius: 0 3px 3px 0;
      margin-right: 16px;
    }
  }
`

export const Divider = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.separator {
    border-bottom: 1px solid ${({ theme }) => theme.color.lowEmphasis};
    width: 300px;
    padding: 0;
    margin-bottom: 24px;
  }

  @media ${minDeviceSizeForMediaQueries.tablet} {
    padding: 0 64px;
  }
`

import styled from 'styled-components'
import { FormControlLabel } from '@material-ui/core'
import { TextParagraph } from '@/main/components'

export const ContainerVertical = styled.div`
  justify-content: center;
  align-text: center;
  display: flex;
  flex-direction: column;
  font-family: Roboto,sans-serif;
  line-height: 20px;
  color: #777777;
`
export const ContainerHorizontal = styled.div`
  display: flex;
  font-size: 4rem;
`

export const StyledFormControlLabel = styled(FormControlLabel)`
  align-items: flex-start;
  margin: 0;

  .MuiCheckbox-root {
    margin-right: 8px;
    padding: 0;

    .MuiSvgIcon-root {
      width: 2rem;
      height: 2rem;
    }
  }
`

export const StyledLabel = styled.span`
  font-size: 1.4rem;
  letter-spacing: unset !important;
`
export const StyledTextParagraph = styled(TextParagraph)`
  font-size: 1.4rem;
`
export const FalseLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: #227bbd;
`

import React, { useContext } from 'react'

import { CircularProgress, Typography } from '@naturacosmeticos/natds-web'

import styled from 'styled-components'

import { IdentityContext } from '@/main/contexts/identity-context'
import { CompanyId } from '@/domain/models/company/company-id'
import { CountryIdAlpha2 } from '@/domain/models/country'

import { StepConfirmation } from '@/main/pages/digital-store/components/digital-store-status-page-self-service-helper/step-confirmation'
import ResetInstructions from '@/main/assets/gifs/instructions-account-reset.gif'
import { useGetPersonData } from '@/main/pages/digital-store/hooks/use-get-person-data'
import { makeConvertCountryIdFromAlpha2ToAlpha3Presenter } from '@/main/factories/presenters'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'

import { usePageMessages } from '@/main/hooks/usePageMessages'
import { DigitalStoreStatusAccountResetStepMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import { useAccountBindLink } from '@/main/pages/digital-store/hooks/use-get-account-bind-link'

const convertCountryIdFromAlpha2ToAlpha3Presenter = makeConvertCountryIdFromAlpha2ToAlpha3Presenter()

const StyledContainer = styled.section`
  --marginHorizontal: -16px;
  margin-left: var(--marginHorizontal);
  margin-right: var(--marginHorizontal);

  padding: 12px 32px;
  text-align: left;
  background-color: #EEEEEE;
  margin-bottom: 32px;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    --marginHorizontal: 0;
  }
`

export const AccountResetStep = () => {
  const {
 countryId, personId, companyId, tenantId,
} = useContext(IdentityContext)
  const vendor = useTenantVendor(tenantId)
  const pageMessagesOptions = { vendorName: vendor.name }
  const messages = usePageMessages(Page.DigitalStoreStatusAccountResetStep, pageMessagesOptions).messages as DigitalStoreStatusAccountResetStepMessages

  const { accountBindLinkData, accountBindLinkLoading } = useAccountBindLink({
    personId,
    countryCode: (countryId as unknown) as CountryIdAlpha2,
    companyId: CompanyId.Natura,
  })

  const country3AlphaCode = convertCountryIdFromAlpha2ToAlpha3Presenter.handle({
    countryId: (countryId as unknown) as CountryIdAlpha2,
  })
  const { personData, personDataLoading } = useGetPersonData({
    relations: ['emails'],
    personId,
    countryId: country3AlphaCode,
    companyId,
  })
  const userEmail = personData?.person?.emails[0]?.email

  const onConfirm = () => window.open(accountBindLinkData, '_blank')

  if (accountBindLinkLoading || personDataLoading) return <CircularProgress />
  return (
    <StepConfirmation
      title={messages.title}
      confirmText={messages.buttonConfirmLabel}
      isFloatingButton
      onConfirm={onConfirm}
    >
      <>
        <StyledContainer>
          <Typography>
            <b>
              {messages.accountInformations.emailLabel}
              :
            </b>
            {' '}
            {userEmail}
          </Typography>
          <Typography>
            <b>
              {messages.accountInformations.keyLabel}
              :
            </b>
            {' '}
            {messages.accountInformations.keyLabelHint}
          </Typography>
        </StyledContainer>
        <Typography variant="body1" color="textSecondary" align="center" style={{ marginBottom: '32px' }}>
          {messages.hint.text}
        </Typography>
        <img
          alt={messages.hint.imageAlt}
          src={ResetInstructions}
          width="182px"
        />
      </>
    </StepConfirmation>
  )
}

import React from 'react'
import {
  ContentSuccess,
  DescriptionContainer,
  Divider,
  Number,
  StepContainer,
  StepContent,
  StepTitle,
  SuccessTitle,
} from '@/main/pages/confirmation/success-status-card.styles'
import ApprovedIcon from '@/main/assets/svgs/approved_icon_thin.svg'
import { DescriptionTexts } from '@/main/pages/confirmation/status-card.styles'
import { Link, Typography } from '@naturacosmeticos/natds-web'
import {
  colorFromStatus,
  StatusCardContainer,
} from '@/main/components/status-card-container'
import { TypographyWithHTMLParser } from '@/main/pages/confirmation/approved'
import { RegisterConfirmationMessages } from '@/main/pages/confirmation/register-confirmation/register-confirmation-wrapper'
import {
  ContactInfoContainer, ContactInfoData,
} from '@/main/pages/confirmation/register-confirmation/register-confirmation.styles'

type RegisterConfirmationProps = {
  messages: RegisterConfirmationMessages
}

export const RegisterConfirmation: React.FC<RegisterConfirmationProps> = ({ messages }) => (
  <StatusCardContainer status={colorFromStatus.PRIMARY}>
    <ContentSuccess>
      <Divider className="separator">
        <img
          alt={messages.iconAlts.confirmation}
          src={ApprovedIcon}
          aria-label="confirmation-image"
          data-testid="confirmation-image"
        />
        <SuccessTitle variant="h5" className="success_title">{messages.title}</SuccessTitle>
        <DescriptionContainer>
          <DescriptionTexts variant="body1" color="textSecondary">{messages.subtitle}</DescriptionTexts>
          <DescriptionTexts className="bold" variant="body1">{messages.description}</DescriptionTexts>
        </DescriptionContainer>
      </Divider>

      <StepContent>
        <StepTitle variant="h6" className="step_title">{messages.nextSteps.title}</StepTitle>
        <Divider>
          <StepContainer>
            <Number variant="h6" className="step_number">1</Number>
            <TypographyWithHTMLParser color="textSecondary" variant="body2" className="step__description" text={messages.nextSteps.step1} />
          </StepContainer>
        </Divider>
      </StepContent>

      <ContactInfoContainer>
        <DescriptionTexts variant="subtitle1">{messages.contactInfo.title}</DescriptionTexts>
        <ContactInfoData>
          <DescriptionTexts variant="subtitle2" className="bold">{messages.contactInfo.phoneNumber}</DescriptionTexts>
          <Link href={`mailto: ${messages.contactInfo.email}`} variant="body2" target="_blank">{messages.contactInfo.email}</Link>
          <Typography variant="body1">{messages.contactInfo.openingHours}</Typography>
        </ContactInfoData>
      </ContactInfoContainer>

    </ContentSuccess>
  </StatusCardContainer>
  )

import React, { useContext } from 'react'
import { useI18n } from '@/main/hooks/useI18n'
import { AuthorizationCodePage } from '@/main/pages'
import { IdentityContext } from '@/main/contexts'
import {
  makeRemoteAcceptAuthorizationCode,
  makeRemoteSendAuthorizationCode,
} from '@/main/factories/remote/authorization-code'
import { TenantId } from '@/domain/models/tenant/tenant-id'

export type AuthorizationInstructionsMessages = { emailAndPhoneNumber: string, phoneNumber: string, email: string }

export type AuthorizationCodeMessages = {
  title: string,
  authorizationCode: { label: string, placeholder: string },
  authorizationCodeMessages: { error: string, success: string },
  authorizationInstructions: AuthorizationInstructionsMessages,
  resendCodeMessage: string,
  resendCodeButton: string,
  nextButtonLabel: string,
  previousButtonLabel: string,
}

const formatPhoneNumber = (phoneNumber, shouldSliceNumber) => {
  if (shouldSliceNumber) {
    return (phoneNumber ? `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}` : '***-*******')
  }
  return phoneNumber || '**********'
}

export const AuthorizationCodeConfigWrapper = () => {
  const { translate } = useI18n()
  const {
    phoneNumber,
    email,
    tenantId,
  } = useContext(IdentityContext)
  const formatNumberConfigs = {
    [TenantId.NaturaCOL]: {
      shouldSliceNumber: true,
    },
    [TenantId.NaturaARG]: {
      shouldSliceNumber: false,
    },
  }
  const shouldSliceNumber = formatNumberConfigs[tenantId]?.shouldSliceNumber
  const formattedPhoneNumber = formatPhoneNumber(phoneNumber, shouldSliceNumber)

  const messages : AuthorizationCodeMessages = {
    title: translate('authorizationCodePage.title'),
    authorizationCode: {
      label: translate('authorizationCodePage.authorizationCode.label'),
      placeholder: translate('authorizationCodePage.authorizationCode.placeholder'),
    },
    authorizationCodeMessages: {
      error: translate('authorizationCodePage.authorizationCodeMessages.error'),
      success: translate('authorizationCodePage.authorizationCodeMessages.success'),
    },
    authorizationInstructions: {
      emailAndPhoneNumber: translate('authorizationCodePage.authorizationInstructions.emailAndPhoneNumber', { phone: formattedPhoneNumber, email: email || '' }),
      phoneNumber: translate('authorizationCodePage.authorizationInstructions.phoneNumber', { phone: formattedPhoneNumber }),
      email: translate('authorizationCodePage.authorizationInstructions.email', { email: email || '' }),
    },
    resendCodeMessage: translate('authorizationCodePage.resendCodeMessage'),
    resendCodeButton: translate('authorizationCodePage.resendCodeButton'),
    nextButtonLabel: translate('nextButtonLabel'),
    previousButtonLabel: translate('previousButtonLabel'),
  }

  return (
    <AuthorizationCodePage
      messages={messages}
      acceptAuthorizationCode={makeRemoteAcceptAuthorizationCode()}
      sendAuthorizationCode={makeRemoteSendAuthorizationCode()}
    />
  )
}

const translationChile = require('../../../../public/locales/translation.CHL.json')
const translationPeru = require('../../../../public/locales/translation.PER.json')
const translationColombia = require('../../../../public/locales/translation.COL.json')
const translationArgentina = require('../../../../public/locales/translation.ARG.json')
const translationMalaysia = require('../../../../public/locales/translation.MYS.json')
const translationMexico = require('../../../../public/locales/translation.MEX.json')
const translationBrasil = require('../../../../public/locales/translation.BRA.json')

const defaultLanguage = 'CHL'
const languages = ['PER', 'CHL', 'COL', 'ARG', 'MYS', 'MEX', 'BRA']

const namespaces = ['translation']
const namespacesSeparator = ':'

const resources = {
  PER: {
    translation: translationPeru,
  },
  CHL: {
    translation: translationChile,
  },
  COL: {
    translation: translationColombia,
  },
  ARG: {
    translation: translationArgentina,
  },
  MYS: {
    translation: translationMalaysia,
  },
  MEX: {
    translation: translationMexico,
  },
  BRA: {
    translation: translationBrasil,
  },
}

module.exports = {
  resources,
  defaultLanguage,
  languages,
  namespaces,
  namespacesSeparator,
}

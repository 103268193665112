import styled from 'styled-components'

import { Tooltip, Icon } from '@/main/components'

export const StyledContainer = styled.span`
  display: flex;
  align-items: center;
  margin-top: -${(props) => props.theme.spacing * 1.5}px;
`
export const StyledTooltip = styled(Tooltip)`
  .MuiTooltip-tooltip {
    padding: ${(props) => props.theme.spacing * 2}px;
  }
`

export const StyledIcon = styled(Icon)`
  margin-left: ${(props) => props.theme.spacing}px;
`

import styled from 'styled-components'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'
import { CardContent, CardHeader, TextParagraph } from '@/main/components'
import {
  Typography,
} from '@naturacosmeticos/natds-web'
import { FormControl } from '@naturacosmeticos/natds-web/dist/Components/FormControl'

export const StyledFormContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 0 8px;
    grid-template-areas:
      "birthday birthday birthday gender gender gender"
      "nationality nationality nationality nationality nationality nationality"
      "terms terms terms terms terms terms";

    @media ${minDeviceSizeForMediaQueries.tablet} {
      grid-template-areas:
        "birthday birthday gender gender gender gender"
        "nationality nationality nationality nationality nationality nationality"
        "terms terms terms terms terms terms";
    }
`

export const StyledLabel = styled(TextParagraph)`
    color: rgb(119, 119, 119);
    margin-bottom: 4px;
    padding: 0;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.11px;
`

export const HeaderTypography = styled(Typography)`
    font-weight: 500;
`

export const DescriptionTypography = styled(TextParagraph)`
    font-size: 16px;
    color: #777777;
    letter-spacing: 0.51px;
`

export const StyledFormControl = styled(FormControl)`
  padding: 12px 0 0;
`

export const StyledCardHeader = styled(CardHeader)`
  padding: 0;
  margin-bottom: 16px;
`

export const StyledCardContent = styled(CardContent)`
  padding: 0;
`

const selectGridArea = (gridArea: string) => {
  switch (gridArea) {
    case 'birthday':
      return 'grid-area: birthday'
    case 'gender':
      return 'grid-area: gender'
    case 'nationality':
      return 'grid-area: nationality'
    case 'terms':
      return 'grid-area: terms'
    default:
      return null
  }
}

export const StyledFormItem = styled.div<{ gridArea?: string, flex?: boolean }>`
  ${(props) => selectGridArea(props.gridArea)};
  ${(props) => ((props.flex) ? `
    display: flex;
    align-items: center;
  ` : '')
  }
`

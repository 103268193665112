import {
  DigitalSignatureMessages,
} from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import {
  Button,
  CardContent,
  CardHeader,
  Spacing,
} from '@/main/components'
import { Container } from '@/main/components/pages-structures'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { SignaturePadBoard } from '@/main/pages/digital-signature/components/signature-pad-board/signature-pad-board'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import SignatureDialog from './components/signature-dialog/signature-dialog'
import { SignatureData } from './models/signature-pad'

export const DigitalSignaturePage: React.FC = () => {
  const [showDialog, setShowDialog] = useState(false)
  const [signatureData, setSignatureData] = useState<SignatureData>(null)

  const messages = usePageMessages(Page.DigitalSignaturePage).messages as DigitalSignatureMessages
  const formMethods = useForm({
    mode: 'onTouched', defaultValues: { acceptedAgreements: false },
  })
  const { formState: { isValid } } = formMethods

  const onCloseSignatureDialog = () => {
    setShowDialog(false)
  }

  const onClickSignatureButton = () => {
    setShowDialog(true)
  }

  const goToPreviousPage = () => { }

  const onNextButtonClick = () => { }

  return (
    <>
      <Container
        previousButtonLabel={messages.previousButtonLabel}
        onPreviousButtonClick={goToPreviousPage}
        nextButtonLabel={messages.nextButtonLabel}
        onNextButtonClick={onNextButtonClick}
        disableNextButton={!isValid}
      >
        <CardHeader
          title={messages.title}
          subtitle={messages.subtitle}
        />
        <CardContent>
          <FormProvider {...formMethods}>
            <Spacing
              className="natds2"
              display="flex"
              marginY="standard"
            >
              <Button
                fullWidth
                color="primary"
                variant={signatureData ? 'outlined' : 'contained'}
                onClick={onClickSignatureButton}
              >
                {signatureData
                  ? (`${messages.redoSignatureButton}`)
                  : (`${messages.signatureButton}`)}
              </Button>
            </Spacing>
            <Button
              fullWidth
              color="primary"
              variant={signatureData ? 'contained' : 'outlined'}
              onClick={() => { }}
            >
              {messages.previewOfContractButton}
            </Button>
          </FormProvider>
        </CardContent>
      </Container>
      {showDialog && (
        <SignatureDialog>
          <SignaturePadBoard setData={setSignatureData} onClose={onCloseSignatureDialog} />
        </SignatureDialog>
      )}
    </>
  )
}

import produce, { Draft } from 'immer'
import { MessagesTypes, MessagesState } from './protocols'

export const initialState: MessagesState = {
  isLoading: true,
  messages: {},
}

export const reducer = produce((draft: Draft<MessagesState>, action) => {
  switch (action.type) {
    case MessagesTypes.GET_MESSAGES_BY_COUNTRY_REQUEST:
      draft.isLoading = true
      break
    case MessagesTypes.GET_MESSAGES_BY_COUNTRY_RESPONSE:
      draft.isLoading = false
      draft.messages = action.payload.messages
      break
    default:
      break
  }
}, initialState)

import React from 'react'
import RejectedIcon from '@/main/assets/svgs/rejection_icon.svg'
import { RejectedMessages } from '@/main/pages/confirmation/rejected/rejected-wrapper'
import {
  DescriptionTexts, Content, Anchor,
} from '@/main/pages/confirmation/status-card.styles'
import { Description } from '@/main/pages/confirmation/rejected/rejected.styles'
import {
  colorFromStatus,
  StatusCardContainer,
} from '@/main/components/status-card-container'
import { TypographyWithHTMLParser } from '@/main/pages/confirmation/approved'

type RejectedProps = {
  messages : RejectedMessages
}

export const Rejected : React.FC<RejectedProps> = ({ messages }) => (
  <StatusCardContainer status={colorFromStatus.ALERT}>
    <Content>
      <img
        src={RejectedIcon}
        aria-label="rejected-image"
        data-testid="rejected-image"
      />
      <DescriptionTexts className="bold" variant="h5" color="textSecondary">
        {messages.title}
      </DescriptionTexts>
      <Description variant="body2" className="description">
        {messages.description}
      </Description>
      <DescriptionTexts className="bold" variant="subtitle1" color="textSecondary">
        {messages.helpTitle}
      </DescriptionTexts>
      <TypographyWithHTMLParser variant="body1" color="textSecondary" text={messages.helpDescription} />
      <Anchor
        color="default"
        className="anchor_link"
        component="a"
        href={messages.helpHref}
        underline="none"
        variant="body2"
      >
        {messages.helpAnchorText}
      </Anchor>
    </Content>
  </StatusCardContainer>
)

import { StepsConfirmation } from '@/main/pages/digital-store/digital-store-status-page/vendors-linking-steps/mercado-pago/steps-confirmation'
import { getTenantVendor, vendorByTenantId } from '@/data/use-cases/vendor/get-tenant-vendor'
import { VendorDetails, VendorLinkingSteps } from '@/domain/models/vendor/vendor-details/details.d'
import { TenantId } from '@/domain/models/tenant/tenant-id'
import { VendorId } from '@/domain/models/vendor/vendor-id'

const linkingStepsByVendorId: { [ key in VendorId ]: VendorLinkingSteps } = {
    [VendorId.MERCADO_PAGO]: StepsConfirmation,
}

export const useTenantVendor = (tenantId: TenantId): VendorDetails => {
    const vendorId = vendorByTenantId[tenantId]
    const linkingSteps = linkingStepsByVendorId[vendorId]
    return getTenantVendor(tenantId, linkingSteps)
}

import { TFunction } from 'i18next'
import { AdressMessageOptions } from '@/domain/models/messages/messages'
import { AddressMessages } from '@/main/pages/address/models'

export const getAddressMessages = (translate: TFunction, options: AdressMessageOptions): AddressMessages => ({
  title: translate('address.title'),
  subtitle: translate('address.subtitle', options),
  mainStreet: translate('address.mainStreet'),
  number: translate('address.number'),
  complementaryData: translate('address.complementaryData'),
  colony: translate('address.colony'),
  state: translate('address.state'),
  checkBoxWithoutNumber: translate('address.checkBoxWithoutNumber'),
  references: translate('address.references'),
  referencesHelperText: translate('referencesHelperText'),
  confirmDeliveryAddressAsResidenceAddress: translate('confirmDeliveryAddressAsResidenceAddress'),
  previousButtonLabel: translate('previousButtonLabel'),
  nextButtonLabel: translate('nextButtonLabel'),
  addressRequiredError: translate('requiredErrorMessage'),
  optionalTextLabel: translate('optionalTextLabel'),
  zipCode: translate('zipCopde'),
  zipCodeDigitsError: translate('zipCodeDigitsError'),
  zipCodeLink: {
    text: translate('text'),
    url: translate('url'),
  },
  delivery: translate('delivery'),
  placeholders: {
    number: translate('address.placeholders.number'),
    zipCode: translate('address.placeholders.zipCode'),
    mainStreet: translate('address.placeholders.mainStreet'),
    complementaryData: translate('address.placeholders.complementaryData'),
    department: translate('address.placeholders.department'),
    city: translate('address.placeholders.city'),
    neighborhood: translate('address.placeholders.neighborhood'),
    references: translate('address.placeholders.references'),
    colony: translate('address.placeholders.colony'),
    state: translate('address.placeholders.state'),
  },
  geographicalLevels: translate('geographicalLevels'),
})

import React, { useContext } from 'react'
import { Typography } from '@naturacosmeticos/natds-web'
import { ProgressIndicator } from '@naturacosmeticos/natds-web/dist/Components/ProgressIndicator'

import { IdentityContext } from '@/main/contexts/identity-context'
import { CompanyId } from '@/domain/models/company/company-id'
import { CountryIdAlpha2 } from '@/domain/models/country'

import { StepConfirmation } from '@/main/pages/digital-store/components/digital-store-status-page-self-service-helper/step-confirmation'
import LinkInstructions from '@/main/assets/gifs/instructions-account-link.gif'

import { useGetPersonData } from '@/main/pages/digital-store/hooks/use-get-person-data'
import { makeConvertCountryIdFromAlpha2ToAlpha3Presenter } from '@/main/factories/presenters'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { DigitalStoreStatusAccountLinkStepMessages } from '@/domain/models/messages/messages'
import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import { useAccountBindLink } from '@/main/pages/digital-store/hooks/use-get-account-bind-link'
import { EmailBar } from '@/main/pages/digital-store/components/digital-store-status-page-self-service-helper/email-bar'

export const AccountLinkStep = () => {
  const { countryId, personId, tenantId } = useContext(IdentityContext)
  const vendor = useTenantVendor(tenantId)
  const pageMessagesOptions = { vendorName: vendor.name }
  const messages = usePageMessages(Page.DigitalStoreStatusAccountLinkStep, pageMessagesOptions).messages as DigitalStoreStatusAccountLinkStepMessages

  const { accountBindLinkData, accountBindLinkLoading } = useAccountBindLink({
    personId,
    countryCode: countryId as unknown as CountryIdAlpha2,
    companyId: CompanyId.Natura,
  })

  const convertCountryIdFromAlpha2ToAlpha3Presenter = makeConvertCountryIdFromAlpha2ToAlpha3Presenter()

  const country3AlphaCode = convertCountryIdFromAlpha2ToAlpha3Presenter.handle({
    countryId: (countryId as unknown) as CountryIdAlpha2,
  })

  const { personData, personDataLoading } = useGetPersonData({
    relations: ['emails'],
    personId,
    countryId: country3AlphaCode,
    companyId: CompanyId.Natura,
  })

  const userEmail = personData?.person?.emails[0]?.email

  const onConfirm = () => window.open(accountBindLinkData, '_blank')

  if (accountBindLinkLoading || personDataLoading) return <ProgressIndicator />

  return (
    <StepConfirmation
      title={messages.title}
      confirmText={messages.buttonConfirmLabel}
      onConfirm={onConfirm}
      isFloatingButton
    >
      <Typography color="textSecondary" align="center" style={{ marginBottom: '18px' }}>
        {messages.hint.text}
      </Typography>
      <EmailBar email={userEmail} />
      <img
        alt={messages.hint.imageAlt}
        src={LinkInstructions}
        width="182px"
      />
    </StepConfirmation>
)
}

import React from 'react'
import { Avatar, Typography } from '@naturacosmeticos/natds-web'
import styled from 'styled-components'

export type LetterAvatarProps = {
  name: string
  dataTestId?: string
}

const StyledTypography = styled(Typography)`
  color: #fff;
  font-weight: bolder;
`

export const LetterAvatar: React.FC<LetterAvatarProps> = ({ name, dataTestId = 'letter-avatar' }) => {
  const getInitials = () => {
    if (!name) return name

    const uppercaseName = name.toUpperCase()

    const names = uppercaseName.trim()
      .split(' ')

    if (names.length === 1) {
      return names[0].slice(0, 2)
    }

    const first = names[0]
    const last = names[names.length - 1]

    return first.slice(0, 1) + last.slice(0, 1)
  }

  return (
    <Avatar color="primary" size="large">
      <StyledTypography variant="body1" data-testid={dataTestId}>
        {getInitials()}
      </StyledTypography>
    </Avatar>
  )
}

import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  ProgressIndicator,
  Spacing,
} from '@naturacosmeticos/natds-web'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

type DialogWithLoaderProps = {
  componentClasses: ClassNameMap,
  text: string,
  isOpen: boolean,
}

export const DialogWithLoader: React.FC<DialogWithLoaderProps> = ({ componentClasses, isOpen, text }) => (
  <Dialog open={isOpen}>
    <DialogTitle>
      {text}
    </DialogTitle>
    <Spacing
      className="natds2"
      display="flex"
      marginBottom="standard"
    >
      <DialogContent className={componentClasses.dialogContent}>
        <ProgressIndicator />
      </DialogContent>
    </Spacing>
  </Dialog>
)

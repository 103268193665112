import styled from 'styled-components'

import { CardHeader } from '@/main/components'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'

export const Container = styled.div``

export const StyledCardHeader = styled(CardHeader)`
  display: 'block';
  padding: 0;
`

export const AdvantageBlock = styled.ol`
  list-style: none;
  padding-left: 40px;
  letter-spacing: 0.26px;
  color: ${({ theme }) => theme.color.mediumEmphasis};
  counter-reset: advantage-counter;
`

export const AdvantageItem = styled.li`
  margin: 0 0 0.5rem 0;
  position: relative;
  margin-bottom: 26px;
  counter-increment: advantage-counter;

  &:before {
    content: counter(advantage-counter);
    color: ${({ theme }) => theme.color.highEmphasis};
    font-size: 2rem;
    position: absolute;
    --size: 40px;
    left: calc(-1 * var(--size) - 10px);
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    top: 0;
    background: ${({ theme }) => theme.color.primary} 0 0 no-repeat padding-box;
    border-radius: 50%;
    text-align: center;
  }
`

export const AdvantageItemTitle = styled.span`
  line-height: 1.6rem;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.color.mediumEmphasis};
  display: flex;
  align-items: flex-end;
  padding: 0 0 0.5rem;
  font-weight: 500;
`

export const AdvantagesTitle = styled.div`
  display: flex;
  margin-left: -16px;
  padding-top: 36px;
  padding-bottom: 14px;

  h2 {
    font-size: 2rem;
  }

  @media ${minDeviceSizeForMediaQueries.tablet} {
    margin-left: -64px;
  }
`

export const AdvantagesTitleBar = styled.div`
  border-radius: 0 4px 4px 0;
  background: ${({ theme }) => theme.color.primary} 0 0 no-repeat padding-box;
  width: 0.4rem;
  height: 3rem;
  margin-right: 20px;
  display: inline-block
`

import React, { useEffect, useState } from 'react'
import i18n from 'i18next'

import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '@/infra/global-state/redux/store'
import { DigitalStoreState } from '@/infra/global-state/redux/store/ducks/digital-store'
import {
  checkDigitalStoreAvailabilityRequest,
  getDigitalStoreInfoRequest,
  resetDigitalStoreStateOnChangeRequest,
  saveDigitalStoreRequest,
  setDigitalStorehelperTextKey,
  checkDigitalStoreAvailabilityError,
} from '@/infra/global-state/redux/store/ducks/digital-store/actions'
import { CardHeader } from '@naturacosmeticos/natds-web'
import { ParseStringToReact } from '@/main/components/string-to-react'
import { Container } from '@/main/components/pages-structures'
import { CountryId } from '@/domain/models/country'
import { DigitalStoreMessages } from '@/domain/models/messages/messages'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { CompanyId } from '@/domain/models/company/company-id'
import { Country } from '@/domain/models/country/country'
import { Page } from '@/domain/models/page/page'
import {
  useDigitalStoreRenamePageStyles,
  Content,
} from './digital-store-rename-form.styles'
import { DigitalStoreUrl } from '../components/digital-store-url'
import { DigitalStoreNameValidation } from '../components/digital-store-name-validation'
import { useGetPersonData } from '../hooks/use-get-person-data'

type DigitalStoreRenamePageProps = {
  personId: string,
  onSave?: (savedName:string) => void
}

const country = new Country('br')

export const DigitalStoreRenameForm = ({ personId, onSave }: DigitalStoreRenamePageProps) => {
  const dispatch = useDispatch()
  const DigitalStoreRenamePageClasses = useDigitalStoreRenamePageStyles()

  const { personData, personDataLoading } = useGetPersonData({
    personId,
    countryId: CountryId.Brasil,
    companyId: CompanyId.Natura,
    relations: ['personRoles'],
  })

  const sourceSystemAutoRegisterBrasil = '5'
  const sourceSystem = sourceSystemAutoRegisterBrasil

  const {
    error,
    isLoading,
    isAvailable,
    helperTextKey,
    savedDigitalStoreName,
    saved,
  } = useSelector<ApplicationState, DigitalStoreState >(
    (state) => ({ ...state.digitalStore }),
  )

  useEffect(() => {
    i18n.changeLanguage(country.threeAlphaCountryCode)
  }, [])

  const [digitalStoreName, setDigitalStoreName] = useState(savedDigitalStoreName)
  const [lastCheckedDigitalStoreName, setLastCheckedDigitalStoreName] = useState(savedDigitalStoreName)

  useEffect(() => {
    setLastCheckedDigitalStoreName(digitalStoreName)
    // eslint-disable-next-line
  }, [isAvailable])

  useEffect(() => {
    dispatch(getDigitalStoreInfoRequest(personId))
  }, [dispatch, personId])

  useEffect(() => {
    setDigitalStoreName(savedDigitalStoreName)
  }, [savedDigitalStoreName])

  useEffect(() => {
    if (saved) {
      onSave(digitalStoreName)
    }
  }, [saved])

  const shouldDisableInput = Boolean(savedDigitalStoreName) || isLoading || personDataLoading
  const shouldDisableVerifyButton = shouldDisableInput || !digitalStoreName || error || isAvailable || personDataLoading

  const showLoadWhileSavingDigitalStore = (personDataLoading || isLoading) && !!isAvailable

  const countryId = CountryId.Brasil

  const checkAvailability = () => dispatch(
    checkDigitalStoreAvailabilityRequest(
      digitalStoreName,
      personId,
      countryId,
    ),
  )
  const handleSetHelperTextKey = (textKey: string) => dispatch(setDigitalStorehelperTextKey(textKey))

  const handleSaveDigitalStore = () => {
    const { roleId: role, businessModelId: businessModel, functionId } = personData.person.roles[0]

    dispatch(
      saveDigitalStoreRequest(
        digitalStoreName,
        personId,
        countryId,
        functionId,
        role,
        businessModel,
        sourceSystem,
        true,
        null,
        null,
      ),
    )
  }

  const checkNameCharacters = (name:string) => name.match(/^[a-z0-9_]+$/i) !== null

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDigitalStoreName(e.target.value)
    if (!checkNameCharacters(e.target.value)) {
      dispatch(checkDigitalStoreAvailabilityError('invalidNameSpace'))
    } else {
      dispatch(resetDigitalStoreStateOnChangeRequest())
    }
  }

  const handleSave = () => {
    handleSaveDigitalStore()
  }

  const digitalStoreTexts = usePageMessages(Page.DigitalStore).messages as DigitalStoreMessages
  const DigitalStoreUrlComponent = ({ shouldHide }) => !shouldHide && <DigitalStoreUrl addressLabel={digitalStoreTexts.addressLabel} baseUrl={digitalStoreTexts.baseUrl} digitalStoreName={lastCheckedDigitalStoreName} />
  const getTextFieldState = () => {
    if (error) return 'error'
    if (isAvailable) return 'success'
    return undefined
  }

  return (
    <Container
      nextButtonLabel={digitalStoreTexts.nextButtonLabel}
      onNextButtonClick={handleSave}
      disableNextButton={!isAvailable}
      showSkipButton={false}
      isLoading={showLoadWhileSavingDigitalStore}
    >
      <Content>
        <CardHeader
          title={digitalStoreTexts.title}
          classes={{
          root: DigitalStoreRenamePageClasses.cardHeader,
          title: DigitalStoreRenamePageClasses.title,
          subheader: DigitalStoreRenamePageClasses.subtitle,
        }}
          subheaderTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
          subheader={
            <ParseStringToReact stringToParse={digitalStoreTexts.description} />
        }
        />
        <DigitalStoreNameValidation
          isLoading={isLoading}
          buttonLabel={digitalStoreTexts.verifyButtonLabel}
          inputLabel={digitalStoreTexts.nameLabel}
          tooltipText={digitalStoreTexts.nameTip}
          hintText={digitalStoreTexts.hint}
          ButtonProps={{
            onClick: checkAvailability,
            onChange: () => handleSetHelperTextKey(''),
            disabled: shouldDisableVerifyButton,
          }}
          TextFieldProps={{
            helpText: digitalStoreTexts[helperTextKey],
            state: getTextFieldState(),
            onChange: handleOnChangeInput,
            disabled: shouldDisableInput,
            value: digitalStoreName,
          }}
        />
        <DigitalStoreUrlComponent shouldHide={!isAvailable} />
      </Content>

    </Container>
  )
}

import React, { useContext, useState } from 'react'
import { StepConfirmation } from '@/main/pages/digital-store/components/digital-store-status-page-self-service-helper/step-confirmation'
import { EmailBar } from '@/main/pages/digital-store/components/digital-store-status-page-self-service-helper/email-bar'
import { StepPropsType } from '@/main/pages/digital-store/digital-store-status-page/vendors-linking-steps/mercado-pago/step'
import { useGetPersonData } from '@/main/pages/digital-store/hooks/use-get-person-data'
import { IdentityContext } from '@/main/contexts'
import { CountryIdAlpha2 } from '@/domain/models/country'
import { makeConvertCountryIdFromAlpha2ToAlpha3Presenter } from '@/main/factories/presenters'
import { CircularProgress } from '@naturacosmeticos/natds-web'

import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { DigitalStoreStatusEmailStepMessages } from '@/domain/models/messages/messages'
import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import { NatDialog } from './nat-dialog/nat-dialog'

const convertCountryIdFromAlpha2ToAlpha3Presenter = makeConvertCountryIdFromAlpha2ToAlpha3Presenter()

export const EmailStep: React.FC<StepPropsType> = ({ advanceStep }) => {
  const {
 personId, countryId, companyId, tenantId,
} = useContext(IdentityContext)
  const vendor = useTenantVendor(tenantId)
  const pageMessagesOptions = { vendorName: vendor.name }
  const messages = usePageMessages(Page.DigitalStoreStatusEmailStep, pageMessagesOptions).messages as DigitalStoreStatusEmailStepMessages

  const [modalOpen, setModalOpen] = useState(false)

  const country3AlphaCode = convertCountryIdFromAlpha2ToAlpha3Presenter.handle({ countryId: countryId as unknown as CountryIdAlpha2 })
  const { personData, personDataLoading } = useGetPersonData({
    relations: ['emails'],
    personId,
    countryId: country3AlphaCode,
    companyId,
  })
  const userEmail = personData?.person?.emails[0]?.email

  if (personDataLoading) return <CircularProgress />
  return (
    <StepConfirmation
      title={messages.confirmation.question}
      confirmText={messages.confirmation.affirmativeAnswer}
      cancelText={messages.confirmation.negativeAnswer}
      onConfirm={() => advanceStep()}
      onCancel={() => setModalOpen(true)}
    >
      <>
        <EmailBar email={userEmail} />
        <NatDialog modalOpen={modalOpen} setModalOpen={setModalOpen} messages={messages} />
      </>
    </StepConfirmation>
  )
}

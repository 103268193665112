import React, { useContext } from 'react'
import { Typography, CircularProgress } from '@naturacosmeticos/natds-web'

import { IdentityContext } from '@/main/contexts/identity-context'
import { CompanyId } from '@/domain/models/company/company-id'
import { CountryIdAlpha2 } from '@/domain/models/country'
import { useGetPersonData } from '@/main/pages/digital-store/hooks/use-get-person-data'
import { makeConvertCountryIdFromAlpha2ToAlpha3Presenter } from '@/main/factories/presenters'

import { StepConfirmation } from '@/main/pages/digital-store/components/digital-store-status-page-self-service-helper/step-confirmation'
import { StepPropsType } from '@/main/pages/digital-store/digital-store-status-page/vendors-linking-steps/mercado-pago/step'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { DigitalStoreStatusAccountStepMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import { EmailBar } from '../../../../components/digital-store-status-page-self-service-helper/email-bar'

export const AccountStep: React.FC<StepPropsType> = ({ advanceStep }) => {
  const { countryId, personId, tenantId } = useContext(IdentityContext)
  const vendor = useTenantVendor(tenantId)
  const pageMessagesOptions = { vendorName: vendor.name }
  const messages = usePageMessages(Page.DigitalStoreStatusAccountStep, pageMessagesOptions).messages as DigitalStoreStatusAccountStepMessages

  const convertCountryIdFromAlpha2ToAlpha3Presenter = makeConvertCountryIdFromAlpha2ToAlpha3Presenter()

  const country3AlphaCode = convertCountryIdFromAlpha2ToAlpha3Presenter.handle({
    countryId: (countryId as unknown) as CountryIdAlpha2,
  })

  const { personData, personDataLoading } = useGetPersonData({
    relations: ['emails'],
    personId,
    countryId: country3AlphaCode,
    companyId: CompanyId.Natura,
  })

  const userEmail = personData?.person?.emails[0]?.email

  if (personDataLoading) return <CircularProgress />
  return (
    <StepConfirmation
      title={messages.confirmation.question}
      confirmText={messages.confirmation.affirmativeAnswer}
      cancelText={messages.confirmation.negativeAnswer}
      onConfirm={() => advanceStep()}
      onCancel={() => advanceStep(2)}
    >
      <Typography color="textSecondary" align="center" style={{ marginBottom: '18px' }}>
        {messages.hint}
      </Typography>
      <EmailBar email={userEmail} />
    </StepConfirmation>
  )
}

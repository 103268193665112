export enum Page {
  Documentation = 'documentation',
  DigitalStore = 'digitalStore',
  PersonalData = 'personalData',
  Attachments = 'attachments',
  DigitalSignaturePage = 'digitalSignaturePage',
  DigitalStoreBrasil = 'digitalStoreBrasil',
  PaymentAccountStatus= 'paymentAccountStatus',
  PaymentAccountStatusSuccess= 'paymentAccountStatusSuccess',
  Address = 'address',
  AuthorizationCode = 'authorizationCode',
  Completed = 'completed',
  Indication = 'indication',
  DigitalStoreStatus = 'digitalStoreStatus',
  PreRegister = 'preRegister',
  DigitalStoreStatusNotFound = 'digitalStoreStatusNotFound',
  DigitalStoreStatusPayment = 'digitalStoreStatusPayment',
  DigitalStoreStatusDefaultContent = 'digitalStoreStatusDefaultContent',
  DigitalStoreStatusPendingAgreements = 'digitalStoreStatusPendingAgreements',
  DigitalStoreStatusIntroductionStep = 'digitalStoreStatusIntroductionStep',
  DigitalStoreStatusEmailStep = 'digitalStoreStatusEmailStep',
  DigitalStoreStatusAccountStep = 'digitalStoreStatusAccountStep',
  DigitalStoreStatusAccountResetStep = 'digitalStoreStatusAccountResetStep',
  DigitalStoreStatusAccountLinkStep = 'digitalStoreStatusAccountLinkStep',
}

import React from 'react'
import {
  IndicationPage,
} from '@/main/pages/indication/indication-page'
import { useI18n } from '@/main/hooks/useI18n'

export type IndicationModalMessages = {
  labelCode: string,
  searchIndicationNotFound: string,
  searchIndicationError: string,
  okButtonLabel: string,
  cancelButtonLabel: string,
  confirmButtonLabel: string,
  alertTitle: string,
  instructionsTitle: string
}

export type IndicationMessages = {
  title: string,
  subtitle: string,
  label: string,
  searchButtonLabel: string,
  indicationLabel: string,
  nextButtonLabel: string,
  previousButtonLabel: string
  modal: IndicationModalMessages,
}

export const IndicationWrapper = () => {
  const { translate } = useI18n()

  const messages : IndicationMessages = {
    title: translate('indicationPage.title'),
    subtitle: translate('indicationPage.subtitle'),
    label: translate('indicationPage.label'),
    searchButtonLabel: translate('indicationPage.searchButtonLabel'),
    indicationLabel: translate('indicationPage.indicationLabel'),
    nextButtonLabel: translate('nextButtonLabel'),
    previousButtonLabel: translate('previousButtonLabel'),
    modal: {
      labelCode: translate('indicationPage.modal.labelCode'),
      searchIndicationNotFound: translate('indicationPage.modal.searchIndicationNotFound'),
      searchIndicationError: translate('indicationPage.modal.searchIndicationError'),
      okButtonLabel: translate('indicationPage.modal.okButtonLabel'),
      cancelButtonLabel: translate('indicationPage.modal.cancelButtonLabel'),
      confirmButtonLabel: translate('indicationPage.modal.confirmButtonLabel'),
      alertTitle: translate('indicationPage.modal.alertTitle'),
      instructionsTitle: translate('indicationPage.modal.instructionsTitle'),
    },
  }

  return <IndicationPage messages={messages} />
}

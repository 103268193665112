import { CountryId } from '@/domain/models/country'

export class Country {
  public readonly threeAlphaCountryCode

  public readonly twoAlphaCountryCode

  private readonly countryCodes = {
    MY: CountryId.Malaysia,
    CL: CountryId.Chile,
    PE: CountryId.Peru,
    BR: CountryId.Brasil,
  }

  constructor(twoAlphaCountryCode: string) {
    twoAlphaCountryCode = twoAlphaCountryCode
      ? twoAlphaCountryCode.toUpperCase()
      : null
    this.twoAlphaCountryCode = this.countryCodes[twoAlphaCountryCode]
      ? twoAlphaCountryCode
      : null
    this.threeAlphaCountryCode = this.countryCodes[this.twoAlphaCountryCode] || null
  }
}

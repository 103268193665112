import { Presenter } from '@/presentation/protocols/presenter'
import { LANGUAGES } from '@/domain/models/language'
import { GetLanguageByCountryParams, GetLanguageByCountry } from '@/domain/use-cases/language/get-language-by-country'

export class GetLanguageByCountryPresenter
  implements Presenter<GetLanguageByCountryParams, LANGUAGES> {
  constructor(
    private readonly getLanguageByCountry: GetLanguageByCountry,
  ) { }

  handle(params: GetLanguageByCountryParams): LANGUAGES {
    return this.getLanguageByCountry.get({
      countryId: params.countryId,
    })
  }
}

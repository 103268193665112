import React from 'react'
import { Controller, ControllerProps, FieldErrors } from 'react-hook-form'
import {
 Link, FormHelperText, InputLabel, Input, Icon,
} from '@naturacosmeticos/natds-web'
import { useControlledTextFieldWithLinkStyles } from '@/main/components/text-field/text-field-with-link/controlled-text-field-with-link.styles'
import { handleValidCharsOnChange } from '../helper/handle-valid-chars'
import { getFinalErrorMessage, StringOrObjectOfStrings } from '../helper/error-message-getter'

export type ControlledTextFieldWithLinkProps = {
  id: string;
  label: string;
  placeholder?: string;
  validChars?: RegExp;
  errors?: FieldErrors;
  errorMessage?: StringOrObjectOfStrings;
  linkText: string;
  href: string;
} & Pick<ControllerProps<'input'>, 'rules' | 'control'>

export const ControlledTextFieldWithLink: React.FC<ControlledTextFieldWithLinkProps> = ({
  id,
  control,
  label,
  placeholder,
  rules,
  validChars,
  errorMessage,
  errors,
  linkText,
  href,
}) => {
  const finalErrorMessage = getFinalErrorMessage(errors, errorMessage, id)
  const controlledTextFieldWithLinkClasses = useControlledTextFieldWithLinkStyles()

  return (
    <div className={controlledTextFieldWithLinkClasses.container}>
      <Controller
        name={id}
        control={control}
        defaultValue=""
        rules={rules}
        render={(
          {
            onBlur, onChange, value, name, ref,
          },
          { invalid },
        ) => (
          <>
            <div className={controlledTextFieldWithLinkClasses.row}>
              <InputLabel
                className={controlledTextFieldWithLinkClasses.label}
                htmlFor={id}
                error={invalid}
              >
                {label}
              </InputLabel>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                className={controlledTextFieldWithLinkClasses.link}
                color="default"
                component="a"
                href={href}
                underline="always"
                variant="body2"
                target="_blank"
              >
                {linkText}
              </Link>
            </div>
            <Input
              ref={ref}
              onBlur={onBlur}
              value={value}
              name={name}
              id={id}
              data-testid={id}
              placeholder={placeholder}
              state={invalid ? 'error' : undefined}
              onChange={(event) => handleValidCharsOnChange(event, onChange, validChars)}
              error={invalid}
            />
            {invalid && (
            <FormHelperText error>
              <Icon
                name="outlined-alert-cancel"
                data-testid={`${id}-icon-error`}
                size="micro"
                className={controlledTextFieldWithLinkClasses.icon}
              />
              {
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                invalid.message || finalErrorMessage
              }
            </FormHelperText>
)}
          </>
        )}
      />
    </div>
  )
}

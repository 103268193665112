import { DigitalStoreBrasilMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getDigitalStoreBrasilMessages(translate: TFunction): DigitalStoreBrasilMessages {
  return {
    title: translate('digitalStore.title'),
    description: translate('digitalStore.description'),
    convergenceTitle: translate('digitalStore.convergenceTitle'),
    convergenceDescription: translate('digitalStore.convergenceDescription'),
    nameLabel: translate('digitalStore.nameLabel'),
    verifyButtonLabel: translate('digitalStore.verifyButtonLabel'),
    hint: translate('digitalStore.hint'),
    addressLabel: translate('digitalStore.addressLabel'),
    baseUrl: translate('digitalStore.baseUrl'),
    nextButtonLabel: translate('digitalStore.nextButtonLabel'),
    nameTip: translate('digitalStore.nameTip'),
    genericErrorTitle: translate('digitalStore.genericErrorTitle'),
    genericError: translate('digitalStore.genericError'),
    availableName: translate('digitalStore.availableName'),
    unavailableName: translate('digitalStore.unavailableName'),
    shareStoreDescription: translate('digitalStore.shareStoreDescription'),
    helpInformation: {
      title: translate('digitalStore.helpInformation.title'),
      text: translate('digitalStore.helpInformation.text'),
    },
  }
}

import React, { useContext, useEffect, useState } from 'react'
import { Container } from '@/main/components/pages-structures'
import { FormContext, FormContextProps, IdentityContext } from '@/main/contexts'
import { Button, CardHeader, CardContent } from '@/main/components'
import { IndicationInfo } from '@/data/use-cases/indication/remote-search-indication'
import {
  Typography,
} from '@naturacosmeticos/natds-web'
import {
  IndicationInfoContainer,
  SearchContainer,
  SearchField,
} from '@/main/pages/indication/indication-page.styles'
import { IndicationMessages } from '@/main/pages/indication/indication-translation-wrapper'
import {
  ModalWithPersonCheckbox,
} from '@/main/components/modal-with-person-checkbox'
import { ModalPersonMessages } from '@/domain/models/messages/messages'
import { searchIndication } from './search-indication'

type IndicationPageProps = {
  messages: IndicationMessages
}

export const IndicationPage: React.FC<IndicationPageProps> = ({ messages }) => {
  const {
    person, goToPreviousPage, goToNextPage, onSubmit,
  } = useContext<FormContextProps>(FormContext)

  const {
    companyId,
    countryId,
  } = useContext(IdentityContext)

  const initialSelectedIndication : IndicationInfo = {
    sellerId: person?.introducerSellerInformation?.introducerSellerId,
    name: null,
  }

  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [isLoadingContainer, setIsLoadingContainer] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isIndicationSelected, setIsIndicationSelected] = useState(false)

  const [indicationInfo, setIndicationInfo] = useState<IndicationInfo>({})
  const [selectedIndication, setSelectedIndication] = useState<IndicationInfo>(initialSelectedIndication)
  const [sellerId, setSellerId] = useState<string>(initialSelectedIndication.sellerId ?? '')
  const [indicationErrorMessage, setIndicationErrorMessage] = useState<string>('')
  const isSearchButtonDisabled = sellerId.length === 0

  useEffect(() => {
    if (person?.introducerSellerInformation?.introducerSellerId) {
      setIsLoadingContainer(true)
      searchIndication({
        id: person.introducerSellerInformation.introducerSellerId,
        companyId,
        countryId,
      }).then((indicationInfo: IndicationInfo) => {
        setSelectedIndication(indicationInfo)
        setIndicationErrorMessage('')
      }).catch((err) => {
        setIndicationErrorMessage(messages.modal[err.message])
      }).finally(() => {
        setIsLoadingContainer(false)
      })
    }
  }, [person])

  const onCloseModalIndication = () => {
    setIsModalOpen(false)
  }

  const openModalIndication = () => {
    setIsModalOpen(true)
  }

  const onSearchClick = () => {
    setIsSearchLoading(true)
    openModalIndication()
    searchIndication({
      id: sellerId,
      companyId,
      countryId,
    }).then((indicationInfo: IndicationInfo) => {
      setIndicationInfo(indicationInfo)
      setIndicationErrorMessage('')
    }).catch((err) => {
      setIndicationErrorMessage(messages.modal[err.message])
    }).finally(() => {
      setIsSearchLoading(false)
    })
  }

  const confirmIndication = () => {
    setSelectedIndication(indicationInfo)
    onCloseModalIndication()
    setIsIndicationSelected(false)
  }

  const onSubmitIndication = () => {
    const data = {
      introducerSellerId: selectedIndication?.sellerId ?? null,
      introducerSellerName: selectedIndication?.name ?? null,
    }
    onSubmit(data, goToNextPage)
  }

  const confirmationError = () => {
    onCloseModalIndication()
    setSelectedIndication({ sellerId: null, name: null })
    setSellerId('')
  }

  const messagesModal: ModalPersonMessages = {
    labelCode: messages.modal.labelCode,
    searchNotFound: messages.modal.searchIndicationNotFound,
    searchError: messages.modal.searchIndicationError,
    okButtonLabel: messages.modal.okButtonLabel,
    cancelButtonLabel: messages.modal.cancelButtonLabel,
    confirmButtonLabel: messages.modal.confirmButtonLabel,
    alertTitle: messages.modal.alertTitle,
    instructionsTitle: messages.modal.instructionsTitle,
  }

  return (
    <Container
      previousButtonLabel={messages.previousButtonLabel}
      nextButtonLabel={messages.nextButtonLabel}
      onNextButtonClick={onSubmitIndication}
      onPreviousButtonClick={goToPreviousPage}
      isLoading={isLoadingContainer}
    >
      <CardHeader title={messages.title} subtitle={messages.subtitle} />
      <CardContent>
        <SearchContainer>
          <SearchField
            className="search-field"
            label={messages.label}
            value={sellerId}
            id="seller-id-input"
            onSetValue={setSellerId}
          />
          <Button
            color="default"
            variant="outlined"
            size="large"
            disabled={isSearchButtonDisabled}
            onClick={onSearchClick}
          >
            {messages.searchButtonLabel}
          </Button>
        </SearchContainer>

        {selectedIndication?.sellerId && (
          <IndicationInfoContainer>
            <Typography variant="body2">{messages.indicationLabel}</Typography>
            <Typography
              variant="subtitle2"
            >
              {selectedIndication.name}
            </Typography>
          </IndicationInfoContainer>
        )}

      </CardContent>

      <ModalWithPersonCheckbox
        errorMessage={indicationErrorMessage}
        info={indicationInfo}
        isItemSelected={isIndicationSelected}
        isSearchLoading={isSearchLoading}
        onItemSelected={() => setIsIndicationSelected(!isIndicationSelected)}
        confirmationError={confirmationError}
        confirmSelection={confirmIndication}
        isModalOpen={isModalOpen}
        handleCloseModal={onCloseModalIndication}
        messages={messagesModal}
      />
    </Container>
  )
}

import React from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { FormControl } from '@naturacosmeticos/natds-web/dist/Components/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import { useControlledSelectStyles } from '@/main/components/select/controlled-select.styles'
import { Icon, FormHelperText } from '@naturacosmeticos/natds-web'
import { Select, SelectOption, SelectProps } from './select'

export type ControlledSelectProps = {
  id: string;
  label: string;
  disabled?: boolean;
  defaultValue?: SelectOption;
  hasAsteriskInTheLabel?: boolean;
} & SelectProps & Pick<ControllerProps<'select'>, 'rules' | 'control'>

export const ControlledSelect: React.FC<ControlledSelectProps> = ({
  'data-testid': dataTestId,
  'aria-label': ariaLabel,
  label,
  id,
  placeholder,
  options,
  control,
  rules,
  disabled = false,
  isLoading = false,
  defaultValue = null,
  hasAsteriskInTheLabel = false,
  onChange: onChangeExtraAction,
}) => {
  const controlSelectClasses = useControlledSelectStyles({ disabled })
  return (
    <FormControl disabled={disabled} className={controlSelectClasses.container} id={`controlled-select-${id}`} data-testid={`controlled-select-${id}`}>
      <FormLabel disabled={disabled} className={controlSelectClasses.label} required={hasAsteriskInTheLabel}>
        {label}
      </FormLabel>
      <Controller
        name={id}
        aria-label={`${ariaLabel}-controller`}
        data-testid={`${dataTestId}-controller`}
        defaultValue={defaultValue}
        control={control}
        rules={rules}
        render={({
          onChange, onBlur, value, name, ref,
        }, { invalid }) => (
          <>
            <Select
              ref={ref}
              name={name}
              options={options}
              onChange={(value, actionMeta) => {
                if (onChangeExtraAction) {
                  onChangeExtraAction(value, actionMeta)
                }
                onChange(value, actionMeta)
              }}
              data-testid={dataTestId}
              aria-label={ariaLabel}
              aria-labelledby={ariaLabel}
              id={name}
              placeholder={placeholder}
              value={value}
              onBlur={onBlur}
              isSearchable
              disabled={disabled}
              isLoading={isLoading}
            />
            { invalid && (
              <FormHelperText state="error">
                <Icon
                  style={{ marginRight: '4px' }}
                  name="outlined-action-cancel"
                  color="error"
                  data-testid="error-icon"
                  size="micro"
                />
                {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
                {/* @ts-ignore */}
                { invalid.message}
              </FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  )
}

import { DocumentationMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getDocumentationMessages(translate: TFunction): DocumentationMessages {
  return {
    modal: {
      title: translate('documentation.modal.title'),
      titleAcceptLabel: translate('documentation.modal.titleAcceptLabel'),
      titleCloseLabel: translate('documentation.modal.titleCloseLabel'),
    },
    titleForAcceptTerm: translate('documentation.titleForAcceptTerm'),
    titleForGeneralInformation: translate('documentation.titleForGeneralInformation'),
    instructionsForAcceptTerm: translate('documentation.instructionsForAcceptTerm'),
    instructionsForGeneralInformation: translate('documentation.instructionsForGeneralInformation'),
    birthdayLabel: translate('documentation.birthdayLabel'),
    genderLabel: translate('documentation.genderLabel'),
    nationalityLabel: translate('documentation.nationalityLabel'),
    birthdayPlaceholder: translate('documentation.birthdayPlaceholder'),
    genderPlaceholder: translate('documentation.genderPlaceholder'),
    nationalityPlaceholder: translate('documentation.nationalityPlaceholder'),
    checkboxLabel: translate('documentation.checkboxLabel'),
    checkboxLabelLink: translate('documentation.checkboxLabelLink'),
    minAgeError: translate('documentation.minAgeError'),
    maxAgeError: translate('documentation.maxAgeError'),
    invalidDateError: translate('documentation.invalidDateError'),
    requiredError: translate('requiredErrorMessage'),
    nextButtonLabel: translate('nextButtonLabel'),
    previousButtonLabel: translate('previousButtonLabel'),
  }
}

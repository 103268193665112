import React from 'react'
import HtmlToReact, { Parser as HtmlToReactParser } from 'html-to-react'
import { Link } from '@naturacosmeticos/natds-web'

type Props = {
  stringToParse: string
}
const htmlToReactParser = new HtmlToReactParser()
const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React)

const processingInstructions = [
  {
    shouldProcessNode: (node) => node?.type === 'tag' && node?.name === 'a',
    processNode: (node, children) => <Link key="link" data-testid="link" {...node.attribs}>{children}</Link>,
  },
  {
    shouldProcessNode: () => true,
    processNode: processNodeDefinitions.processDefaultNode,
  },
]

export const ParseStringToReact: React.FC<Props> = ({ stringToParse }) => (
  <>
    {htmlToReactParser.parseWithInstructions(stringToParse, () => true, processingInstructions)}
  </>
)

import {
  GetPersonData,
  GetPersonDataParams,
  PersonData,
} from '@/domain/use-cases/person/get-person-data'
import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { NotFoundError } from '@/data/protocols/errors'
import { UnexpectedError } from '@/domain/errors'
import { CountryId } from '@/domain/models/country'
import { CompanyId } from '@/domain/models/company/company-id'

export type GetPersonDataHeaders = {
  'x-api-key': string
  countryid: CountryId
  companyid: CompanyId
}

export class RemoteGetPersonData implements GetPersonData {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient<
      GetPersonDataHeaders,
      PersonData
    >,
    private readonly xApiKey: string,
  ) {}

  get = async (params: GetPersonDataParams): Promise<PersonData | {} | unknown | null> => {
    const headers: GetPersonDataHeaders = {
      'x-api-key': this.xApiKey,
      countryid: params.countryId,
      companyid: params.companyId,
    }
    const query = { relations: params.relations.join(',') }

    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(/:personId/i, params.personId),
      headers,
      query,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body
      case HttpStatusCode.notFound:
        throw new NotFoundError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}

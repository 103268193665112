import React from 'react'
import {
  DigitalStorePage,
  DigitalStoreStatusPage,
  DigitalStoreRenamePage,
  PaymentAccountStatusPage,
  DocumentationPage,
  IndicationWrapper,
  AuthorizationCodeConfigWrapper,
  CompletedWrapper,
  AttachmentsPage,
  PreRegisterPage,
  PersonalDataPage,
  DigitalSignaturePage,
  AddressPageSwitcher,
} from '@/main/pages'
import { Page } from '@/domain/models/page/page'
import { makeAttachmentsPageApi } from './pages/attachments/api/make-attachments-page-api'

type PageSwitcherProps = {
  page: Page
}

const pageMapper = {
  [Page.PreRegister]: <PreRegisterPage />,
  [Page.PersonalData]: <PersonalDataPage />,
  [Page.DigitalSignaturePage]: <DigitalSignaturePage />,
  [Page.Address]: <AddressPageSwitcher />,
  [Page.DigitalStore]: <DigitalStorePage />,
  [Page.Documentation]: <DocumentationPage />,
  [Page.Indication]: <IndicationWrapper />,
  [Page.AuthorizationCode]: <AuthorizationCodeConfigWrapper />,
  [Page.Completed]: <CompletedWrapper />,
  [Page.DigitalStoreBrasil]: <DigitalStoreRenamePage />,
  [Page.DigitalStoreStatus]: <DigitalStoreStatusPage />,
  [Page.PaymentAccountStatus]: <PaymentAccountStatusPage />,
  [Page.Attachments]: <AttachmentsPage api={makeAttachmentsPageApi()} />,
  // When add a page: be sure the component is coming from the path '@/main/pages',
  // otherwise you can't use the describe.each in the unit test
}

export const PageSwitcher: React.FC<PageSwitcherProps> = ({ page }) => {
  const pageComponent = pageMapper[page]
  if (pageComponent) {
    return pageComponent
  }
  throw new Error(`The ${page} page is not expected in this Micro-Front-End api`)
}

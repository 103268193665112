import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '@/infra/global-state/redux/store'
import { DigitalStoreState } from '@/infra/global-state/redux/store/ducks/digital-store'
import {
  checkDigitalStoreAvailabilityRequest,
  getDigitalStoreInfoRequest,
  resetDigitalStoreStateOnChangeRequest,
  saveDigitalStoreRequest,
  setDigitalStorehelperTextKey,
  checkDigitalStoreAvailabilityError,
} from '@/infra/global-state/redux/store/ducks/digital-store/actions'
import { CardHeader } from '@naturacosmeticos/natds-web'
import { FormContext, IdentityContext } from '@/main/contexts'
import { ParseStringToReact } from '@/main/components/string-to-react'
import { Container } from '@/main/components/pages-structures'
import { AgreementProduct } from '@/domain/models/agreement/agreement-product'
import { AcceptTermsComponent } from '@/main/components/accept-terms'
import { makeRemoteUnlinkAgreement } from '@/main/factories/remote/agreement'
import { DigitalStoreMessages } from '@/domain/models/messages/messages'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { DigitalStoreConfigs } from '@/domain/models/tenant/tenant-configs'
import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import { DigitalStoreAdvantages } from '../components/digital-store-advantages'
import {
  useDigitalStorePageStyles,
  Content,
} from './digital-store-page.styles'
import { DigitalStoreUrl } from '../components/digital-store-url'
import { DigitalStoreNameValidation } from '../components/digital-store-name-validation'

type DigitalStorePageProps = {
  convergence?: boolean,
  onSave?: () => void
}

export const DigitalStorePage = ({ convergence = false, onSave }: DigitalStorePageProps) => {
  const dispatch = useDispatch()

  const digitalStorePageClasses = useDigitalStorePageStyles()
  const {
    functionId,
    role,
    businessModel,
    sourceSystem,
    countryId,
    personId,
    tenantId,
  } = useContext(IdentityContext)
  const vendor = useTenantVendor(tenantId)
  const pageMessagesOptions = { vendorName: vendor.name }
  const messages = usePageMessages(Page.DigitalStore, pageMessagesOptions).messages as DigitalStoreMessages

  const {
    error,
    isLoading,
    isAvailable,
    helperTextKey,
    savedDigitalStoreName,
    saved,
  } = useSelector<ApplicationState, DigitalStoreState >(
    (state) => ({ ...state.digitalStore }),
  )

  const { goToNextPage, goToPreviousPage } = useContext(FormContext)

  const [digitalStoreName, setDigitalStoreName] = useState(savedDigitalStoreName)
  const [lastCheckedDigitalStoreName, setLastCheckedDigitalStoreName] = useState(savedDigitalStoreName)

  const [isAgreementsAccepted, setIsAgreementsAccepted] = useState(false)
  const [userNeedsToAcceptTerms, setUserNeedsToAcceptTerms] = useState(true)
  const [agreementsId, setAgreementsId] = useState<string[]>([])

  useEffect(() => {
    setLastCheckedDigitalStoreName(digitalStoreName)
    // eslint-disable-next-line
  }, [isAvailable])

  useEffect(() => {
    dispatch(getDigitalStoreInfoRequest(personId))
  }, [dispatch, personId])

  useEffect(() => {
    setDigitalStoreName(savedDigitalStoreName)
  }, [savedDigitalStoreName])

  useEffect(() => {
    if (saved && !convergence) {
      goToNextPage()
    } else if (saved) {
      onSave()
    }
  }, [saved, countryId, convergence, goToNextPage])

  const configs = useTenantConfigs(tenantId, Page.DigitalStore) as DigitalStoreConfigs

  const shouldDisableInput = Boolean(savedDigitalStoreName) || isLoading
  const shouldDisableVerifyButton = shouldDisableInput || !digitalStoreName || error || isAvailable
  const canSaveDigitalStore = (!userNeedsToAcceptTerms || isAgreementsAccepted) && (isAvailable || savedDigitalStoreName)
  const isConsultantAutoRegister = functionId === 1 && role === 1 && businessModel === 1

  const digitalStoreSkipButtonToggle = process.env.DIGITAL_STORE_SKIP_BUTTON_TOGGLE === 'true'
  const showSkipButton = digitalStoreSkipButtonToggle && (configs.showSkipButton && !convergence && isConsultantAutoRegister)
  const userCanSkipStep = !isAgreementsAccepted && !lastCheckedDigitalStoreName

  const showLoadWhileSavingDigitalStore = isLoading && !!canSaveDigitalStore

  const checkAvailability = () => dispatch(
    checkDigitalStoreAvailabilityRequest(
      digitalStoreName,
      personId,
      countryId,
    ),
  )
  const handleSetHelperTextKey = (textKey: string) => dispatch(setDigitalStorehelperTextKey(textKey))

  const handleSaveDigitalStore = () => dispatch(
    saveDigitalStoreRequest(
      digitalStoreName,
      personId,
      countryId,
      functionId,
      role,
      businessModel,
      sourceSystem,
      convergence,
      agreementsId[0] || '',
      isAgreementsAccepted,
    ),
  )

  const checkNameCharacters = (name:string) => name.match(/^[a-z0-9_]+$/i) !== null

  const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDigitalStoreName(e.target.value)
    if (!checkNameCharacters(e.target.value)) {
      dispatch(checkDigitalStoreAvailabilityError('invalidNameSpace'))
    } else {
      dispatch(resetDigitalStoreStateOnChangeRequest())
    }
  }

  const handleNextPage = () => {
    if (savedDigitalStoreName && !convergence) {
      goToNextPage()
    } else {
      handleSaveDigitalStore()
    }
  }

  const handleSkipButtonClick = async () => {
    try {
      await unlinkAgreement()
    } catch (e) {
      console.error(e)
    } finally {
      goToNextPage()
    }

    async function unlinkAgreement() {
      const remoteUnlinkAgreement = makeRemoteUnlinkAgreement()
      return remoteUnlinkAgreement.unlink({
        personId,
        products: ['digital-store'],
      })
    }
  }

  const AdvantagesComponent = ({ convergence }) => convergence && <DigitalStoreAdvantages advantagesTexts={messages.advantages} />
  const DigitalStoreUrlComponent = ({ shouldHide }) => !shouldHide && <DigitalStoreUrl addressLabel={messages.addressLabel} baseUrl={messages.baseUrl} digitalStoreName={lastCheckedDigitalStoreName} />
  const getTextFieldState = () => {
    if (error) return 'error'
    if (isAvailable) return 'success'
    return undefined
  }

  return (
    <Container
      previousButtonLabel={!convergence ? messages.previousButtonLabel : ''}
      onPreviousButtonClick={goToPreviousPage}
      nextButtonLabel={messages.nextButtonLabel}
      onNextButtonClick={handleNextPage}
      disableNextButton={!canSaveDigitalStore}
      showSkipButton={showSkipButton}
      skipButtonLabel={messages.skipButtonLabel}
      onSkipButtonClick={handleSkipButtonClick}
      disableSkipButton={!userCanSkipStep}
      isLoading={showLoadWhileSavingDigitalStore}
    >
      <Content>
        <CardHeader
          title={!convergence ? messages.title : messages.convergenceTitle}
          classes={{
          root: digitalStorePageClasses.cardHeader,
          title: digitalStorePageClasses.title,
          subheader: digitalStorePageClasses.subtitle,
        }}
          subheaderTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
          subheader={
            <ParseStringToReact stringToParse={!convergence ? messages.description : messages.convergenceDescription} />
        }
        />
        <AdvantagesComponent convergence={convergence} />
        <DigitalStoreNameValidation
          isLoading={isLoading}
          buttonLabel={messages.verifyButtonLabel}
          inputLabel={messages.nameLabel}
          tooltipText={!convergence ? messages.nameTip : undefined}
          hintText={messages.hint}
          ButtonProps={{
            onClick: checkAvailability,
            onChange: () => handleSetHelperTextKey(''),
            disabled: shouldDisableVerifyButton,
          }}
          TextFieldProps={{
            helpText: messages[helperTextKey],
            state: getTextFieldState(),
            onChange: handleOnChangeInput,
            disabled: shouldDisableInput,
            value: digitalStoreName,
          }}
        />
        <DigitalStoreUrlComponent shouldHide={!isAvailable} />
        <AcceptTermsComponent
          personId={personId}
          countryId={countryId}
          products={[AgreementProduct.DIGITAL_STORE]}
          setAgreementId={setAgreementsId}
          setUserNeedsToAcceptTerms={setUserNeedsToAcceptTerms}
          shouldHide={!userNeedsToAcceptTerms}
          agreementLabel={messages.terms.agreementLabel}
          agreementLabelLink={messages.terms.agreementLabelLink}
          termsInfo={messages.terms.termsInfo}
          modalTitle={messages.terms.modalTitle}
          modalAccept={messages.terms.modalAccept}
          modalClose={messages.terms.modalClose}
          CheckboxProps={{
          value: isAgreementsAccepted,
          onChange: (hasAcceptedAgreement) => setIsAgreementsAccepted(hasAcceptedAgreement),
        }}
        />
      </Content>

    </Container>
  )
}

import React from 'react'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { DigitalStoreBrasilMessages } from '@/domain/models/messages/messages'
import {
  Avatar,
  Link,
  Spacing,
  GridContainer,
} from '@naturacosmeticos/natds-web'
import NatAvatar from '@/main/assets/pngs/nat-avatar.png'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import { DigitalStoreStatusBaseCard } from '../components/digital-store-status-base-card'

export const DigitalStoreRenameError: React.FC = () => {
  const messages = usePageMessages(Page.DigitalStoreBrasil).messages as DigitalStoreBrasilMessages

  return (
    <DigitalStoreStatusBaseCard
      statusMessage={messages.genericError}
      statusType="error"
      title={messages.genericErrorTitle}
    >
      <Spacing marginTop="small" marginBottom="medium">
        <GridContainer alignItems="center" direction="column">
          <Avatar size="huge" src={NatAvatar} />
          <Spacing marginTop="small" />
          <Link href="https://api.whatsapp.com/send?phone=5511930360000&text=Ol%C3%A1!%20Gostaria%20de%20falar%20com%20a%20Nat!" variant="body1" color="default" target="_blank">
            <WhatsAppIcon />
            {' '}
            Iniciar conversa
          </Link>
        </GridContainer>
      </Spacing>
    </DigitalStoreStatusBaseCard>
  )
}

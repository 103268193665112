import { TextField, ITextFieldProps } from '@naturacosmeticos/natds-web'
import React, {
 ChangeEvent, useEffect, useState,
} from 'react'

interface NumberTextFieldProps extends Partial<ITextFieldProps> {
  onSetValue: (value: string) => void
}

export const NumberTextField: React.FC<NumberTextFieldProps> = ({ onSetValue, value, ...props }) => {
  const [fieldValue, setFieldValue] = useState(value)

  useEffect(() => {
      setFieldValue(value)
  }, [value])

  const onNumberTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.match(/[^0-9]+/g)) {
      e.preventDefault()
    } else {
      onSetValue(e.target.value)
      setFieldValue(e.target.value)
    }
  }

  return (
    <TextField
      {...props}
      value={fieldValue}
      onChange={onNumberTextFieldChange}
    />
  )
}

import React from 'react'

import { Typography } from '@/main/components'

import { StyledContainer, StyledTooltip, StyledIcon } from './digital-store-name-validation-label.styles'

export type DigitalStoreNameValidationLabelProps = {
  children: React.ReactNode
}

export const DigitalStoreNameValidationLabel = ({ children, tooltipText }) => (
  <StyledContainer>
    {children}
    {tooltipText && (
      <StyledTooltip
        title={(
          <Typography variant="body2">
            { tooltipText }
          </Typography>
        )}
        enterTouchDelay={100}
        arrow
      >
        <StyledIcon
          name="outlined-action-help"
          color="primary"
          size="tiny"
          data-testid="tooltip-icon"
        />
      </StyledTooltip>
  )}
  </StyledContainer>
)

import React, { useContext, useEffect, useState } from 'react'
import { PersonalDataMessages } from '@/domain/models/messages/messages'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { PersonalDataConfigs } from '@/domain/models/tenant/tenant-configs'
import {
  EmailsType,
  FormContext,
  IdentityContext,
  IntroducerSellerInformation,
  PersonData, PhonesType,
} from '@/main/contexts'
import { Container } from '@/main/components/pages-structures'
import { CardContent, CardHeader } from '@/main/components'
import { FormProvider, useForm } from 'react-hook-form'
import {
  createPhoneNumberLengthValidation,
  EmailTextField,
  FullNameTextField,
  PhoneFields,
  ZipCodeTextField,
} from '@/main/pages/personal-data/fields'
import { RecommenderField } from '@/main/pages/personal-data/fields/recommender/recommender-field'
import { EventType } from '@/domain/models/events/events'
import { DialogAlert } from '@/main/components/dialog-alert/dialog-alert'

type FormInputs = {
  email: string,
  phoneNumber: string,
  zipCode: number,
  recommender: string,
  fullName: string,
}

type PhonesSubmit = {
  phone: string,
  countryCode?: string,
  type?: number
}

type AddressSubmit = {
  zipCode: number,
  countryName: string
}

type FormSubmit = {
  emails: EmailsType[],
  name: string,
  phones: PhonesSubmit[],
  address: AddressSubmit[],
  additionalInformation: IntroducerSellerInformation,
  shouldUseRecommender: boolean
}

const getPhoneNumber = (phones: PhonesType[], configs:PersonalDataConfigs) : string => {
  const phone = phones?.find((phone) => phone.type === configs.firstPhoneType.type)
  const areaCode = phone?.areaCode || ''
  const phoneNumber = phone?.phoneNumber || ''
  return areaCode.concat(phoneNumber)
}

const formDefaultValues = (person : PersonData, configs: PersonalDataConfigs): FormInputs => ({
  email: person?.emails[0]?.email,
  phoneNumber: getPhoneNumber(person?.phones, configs),
  zipCode: person?.addresses[0]?.zipCode,
  recommender: person?.introducerSellerInformation.introducerSellerId,
  fullName: person?.name,
 })

const formSubmitValues = (formValues: FormInputs, configs: PersonalDataConfigs, messages:PersonalDataMessages): FormSubmit => ({
  emails: [{ email: formValues.email }],
  name: formValues.fullName,
  phones: [
    {
      type: configs.firstPhoneType.type,
      countryCode: configs.countryCode,
      phone: formValues.phoneNumber,
    },
  ],
  address: [{ zipCode: formValues.zipCode, countryName: configs.countryName }],
  additionalInformation: {
    introducerSellerId: formValues.recommender === messages.recommender.noRecommenderText.label ? null : formValues.recommender,
  },
  shouldUseRecommender: configs.shouldUseRecommender,
})

export const PersonalDataPage: React.FC = () => {
  const {
    tenantId,
  } = useContext(IdentityContext)
  const [showDialogAlert, setShowDialogAlert] = useState(false)

  const messages = usePageMessages(Page.PersonalData).messages as PersonalDataMessages
  const configs = useTenantConfigs(tenantId, Page.PersonalData) as PersonalDataConfigs

  const {
    goToNextPage,
    goToPreviousPage,
    onSubmit,
    person,
  } = useContext(FormContext)
  const formMethods = useForm({
    mode: 'onTouched',
    defaultValues: formDefaultValues(person, configs),
  })
  const {
    getValues,
    formState: { isValid },
  } = formMethods

  const onSubmitPersonalData = () => {
    const formValues = getValues()
    const formattedValues = formSubmitValues(formValues, configs, messages)
    onSubmit(formattedValues, goToNextPage)
  }

  useEffect(() => {
    function openDialogAlert() {
      setShowDialogAlert(true)
    }

    window.addEventListener(
      EventType.PERSONAL_DATA_OPEN_DIALOG_EMAIL_ALREADY_EXISTS_ERROR,
      openDialogAlert,
    )

    return () => window.removeEventListener(
      EventType.PERSONAL_DATA_OPEN_DIALOG_EMAIL_ALREADY_EXISTS_ERROR,
      openDialogAlert,
    )
  }, [])

  return (
    <>
      <Container
        previousButtonLabel={messages.previousButtonLabel}
        onPreviousButtonClick={goToPreviousPage}
        nextButtonLabel={messages.nextButtonLabel}
        onNextButtonClick={onSubmitPersonalData}
        disableNextButton={!isValid}
      >
        <CardHeader
          title={messages.title}
          subtitle={messages.subtitle}
        />
        <CardContent>
          <FormProvider {...formMethods}>
            <FullNameTextField messages={messages.fullName} customProps={{ shouldInfoTextBeVisible: true, showAsteriskInRequiredLabel: configs.showAsteriskInRequiredLabel }} />
            <PhoneFields
              messages={messages.phone}
              shouldShowPhoneSelect={configs.shouldUsePhoneSelect}
              options={[
              {
                phoneType: configs.firstPhoneType.type,
                mask: configs.firstPhoneType.mask,
                validations: {
                  length: createPhoneNumberLengthValidation(configs.firstPhoneType.length),
                },
              },
            ]}
            />
            <EmailTextField messages={messages.email} customProps={{ shouldInfoTextBeVisible: true, showAsteriskInRequiredLabel: configs.showAsteriskInRequiredLabel }} />
            {configs.shouldUseRecommender && <RecommenderField messages={messages.recommender} />}
            {configs.shouldUseZipCode && (
            <ZipCodeTextField messages={messages.zipCode} showAsteriskInRequiredLabel={configs.showAsteriskInRequiredLabel} />
          )}
          </FormProvider>
        </CardContent>
      </Container>
      <DialogAlert show={showDialogAlert} messages={messages.dialog} />
    </>
  )
}

import React, { useContext, useEffect, useState } from 'react'
import { FormContext, FormContextProps, IdentityContext } from '@/main/contexts'
import { getPersonStatus } from '@/main/pages/confirmation/get-person-status'
import { usePolling } from '@/main/pages/confirmation/usePolling'
import {
  COUNTRY_POLLING_DELAY,
  COUNTRY_POLLING_TIMEOUT,
} from '@/domain/models/country/country-polling'
import {
  PersonRegisterStatus,
  PersonStatus,
} from '@/data/use-cases/person/remote-get-person-status'
import { PendingWrapper } from '@/main/pages/confirmation/pending'
import { RejectedWrapper } from '@/main/pages/confirmation/rejected'
import { LoadingWrapper } from '@/main/pages/confirmation/loading'
import { ApprovedWrapper } from '@/main/pages/confirmation/approved'
import { completeRegistrationRequest } from '@/infra/global-state/redux/store/ducks/complete-registration'
import { useDispatch } from 'react-redux'
import { RegisterConfirmationWrapper } from '@/main/pages/confirmation/register-confirmation'

export const CompletedWrapper = () => {
  const {
    personId,
    countryId,
    businessModel,
    role,
    functionId,
    companyId,
    sourceSystem,
    authorization,
  } = useContext(IdentityContext)
  const { completedInformation } = useContext<FormContextProps>(FormContext)
  const dispatch = useDispatch()

  const [personResponseStatus, setPersonResponseStatus] = useState<PersonStatus>({ personRegisterStatus: PersonRegisterStatus.LOADING })

  const DELAY_IN_SECONDS : number = COUNTRY_POLLING_DELAY
  const TIMEOUT_IN_SECONDS : number = COUNTRY_POLLING_TIMEOUT[countryId] || COUNTRY_POLLING_TIMEOUT.DEFAULT
  const shouldUsePolling : boolean = completedInformation?.shouldCheckStatus
  const isRegisterConfirmation = completedInformation?.shouldOnlyShowConfirmation
  const isLoadingOrDraft = (status) : boolean => status.personRegisterStatus === PersonRegisterStatus.LOADING
    || status.personRegisterStatus === PersonRegisterStatus.DRAFT
  const isApprovedOrRejected = (status) : boolean => status.personRegisterStatus === PersonRegisterStatus.APPROVED
    || status.personRegisterStatus === PersonRegisterStatus.REJECTED

  const setPersonStatusAsPending = () => {
    if (isLoadingOrDraft(personResponseStatus)) {
      setPersonResponseStatus({ personRegisterStatus: PersonRegisterStatus.PENDING })
    }
  }

  const { setStop, stop } = usePolling({
    callback: () => getPersonStatus({
        personId,
        countryId,
        companyId,
        functionId,
        businessModel,
        role,
      })
        .then((personStatus) => {
            setPersonResponseStatus(personStatus)
        })
        .catch(() => {}),
    delay: DELAY_IN_SECONDS,
    timeout: TIMEOUT_IN_SECONDS,
    shouldUsePolling,
    timeoutCallback: () => setPersonStatusAsPending(),
  })

  useEffect(() => {
    if (isApprovedOrRejected(personResponseStatus)) {
      setStop(true)
    }
  }, [personResponseStatus, setStop])

  useEffect(() => {
    if (personId && countryId && businessModel && role && functionId && sourceSystem && companyId) {
      dispatch(completeRegistrationRequest(personId, countryId, businessModel, role, functionId, sourceSystem, companyId, authorization))
    }
  }, [dispatch, personId, countryId, businessModel, role, functionId, sourceSystem, companyId, authorization])

  const getComponentByStatus = (status : PersonRegisterStatus) => {
    if (status === PersonRegisterStatus.PENDING && stop) {
      return <PendingWrapper />
    }
    if (status === PersonRegisterStatus.REJECTED) {
      return <RejectedWrapper />
    }
    if (status === PersonRegisterStatus.APPROVED) {
      return (
        <ApprovedWrapper
          companyId={companyId}
          countryId={countryId}
          approvedCandidateId={personResponseStatus.approvedCandidate.candidateId}
          personId={personId}
          paymentPlanId={personResponseStatus?.paymentPlan?.id}
        />
      )
    }
    return <LoadingWrapper duration={TIMEOUT_IN_SECONDS} />
  }

  return isRegisterConfirmation ? <RegisterConfirmationWrapper /> : getComponentByStatus(personResponseStatus.personRegisterStatus)
}

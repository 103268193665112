import React from 'react'
import { useI18n } from '@/main/hooks/useI18n'
import { RegisterConfirmation } from '@/main/pages'

export type RegisterConfirmationMessages = {
  title: string,
  subtitle: string,
  description: string,
  nextSteps: {
    title: string,
    step1:string
  },
  iconAlts: {
    confirmation: string,
  },
  contactInfo: {
    title: string,
    phoneNumber: string,
    email:string,
    openingHours: string,
  },
}

export const RegisterConfirmationWrapper = () => {
  const { translate } = useI18n()

  const messages : RegisterConfirmationMessages = {
    title: translate('completedPage.confirmation.title'),
    subtitle: translate('completedPage.confirmation.subtitle'),
    description: translate('completedPage.confirmation.description'),
    nextSteps: {
      title: translate('completedPage.confirmation.nextSteps.title'),
      step1: translate('completedPage.confirmation.nextSteps.step1'),
    },
    iconAlts: {
      confirmation: translate('completedPage.confirmation.iconAlts.confirmation'),
    },
    contactInfo: {
      title: translate('completedPage.confirmation.contactInfo.title'),
      phoneNumber: translate('completedPage.confirmation.contactInfo.phoneNumber'),
      email: translate('completedPage.confirmation.contactInfo.email'),
      openingHours: translate('completedPage.confirmation.contactInfo.openingHours'),
    },
  }

  return <RegisterConfirmation messages={messages} />
}

import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux'
import { Helmet } from 'react-helmet'
import { StylesProvider } from '@material-ui/styles'
import { store } from '@/infra/global-state/redux/store'
import { history } from '@/infra/global-state/redux/store/history'
import { GlobalMessages } from '@/main/components/Messages/GlobalMessages'
import { Provider as ThemeProvider } from '@naturacosmeticos/natds-web'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { createGenerateClassName } from '@material-ui/core/styles'
import { I18nextProvider } from 'react-i18next'
import { Page } from '@/domain/models/page/page'
import { GlobalStyle } from './components/Layout/GlobalStyle'
import { BaseLayout } from './components/Layout/BaseLayout'
import { initI18n } from '../infra/translation/i18next/i18n'
import {
  IdentityContext,
  IdentityInfoProps,
  AuthenticatedUserContext,
  AuthenticatedUserProps,
  FormContext,
  FormContextProps,
} from './contexts'
import { PageSwitcher } from './page-switcher'
import { useTheme } from './hooks/useTheme'

type Props = {
  identityInfo?: IdentityInfoProps,
  authenticatedUser?: AuthenticatedUserProps,
  loadMessages?: boolean
  page?: Page
} & FormContextProps

const generateClassName = createGenerateClassName({
  productionPrefix: 'registers-fe',
})

const Root: React.FC<Props> = ({
  page,
  identityInfo,
  authenticatedUser = {},
  loadMessages = false,
  onSubmit,
  person,
  goToNextPage,
  goToPreviousPage,
  bypassStep,
  completedInformation,
  hasDigitalStore,
}) => {
  const language = identityInfo?.countryId
  const i18n = initI18n(language)
  const currentTheme = useTheme(identityInfo?.companyId).light
  return (
    <div role="treeitem">
      <ThemeProvider theme={currentTheme} cssPrefix="registers-fe">
        <StylesProvider generateClassName={generateClassName} injectFirst>
          <StyledThemeProvider theme={currentTheme}>
            <Provider store={store}>
              <IdentityContext.Provider value={identityInfo}>
                <I18nextProvider i18n={i18n}>
                  <FormContext.Provider
                    value={{
                      onSubmit,
                      person,
                      goToNextPage,
                      goToPreviousPage,
                      bypassStep,
                      completedInformation,
                      hasDigitalStore,
                    }}
                  >
                    <AuthenticatedUserContext.Provider value={authenticatedUser}>
                      <ConnectedRouter history={history}>
                        <BaseLayout>
                          <Helmet>
                            <link
                              href="https://fonts.googleapis.com/css?family=Roboto&display=swap"
                              rel="stylesheet"
                            />
                          </Helmet>
                          <GlobalStyle />
                          <GlobalMessages loadMessages={loadMessages}>
                            <PageSwitcher page={page} />
                          </GlobalMessages>
                        </BaseLayout>
                      </ConnectedRouter>
                    </AuthenticatedUserContext.Provider>
                  </FormContext.Provider>
                </I18nextProvider>
              </IdentityContext.Provider>
            </Provider>
          </StyledThemeProvider>
        </StylesProvider>
      </ThemeProvider>
    </div>
  )
}

export default Root

import { action } from 'typesafe-actions'

import { CountryId } from '@/domain/models/country'
import { BUSINESS_MODELS, ROLES, FUNCTION_IDS } from '@/domain/models/person'
import { CompanyId } from '@/domain/models/company/company-id'
import { CompleteRegistrationTypes } from './protocols'

export const completeRegistrationRequest = (
  personId: string,
  countryId: CountryId,
  businessModel: BUSINESS_MODELS,
  role: ROLES,
  functionId: FUNCTION_IDS,
  sourceSystem: string,
  companyId: CompanyId,
  authorization: string,
) => action(CompleteRegistrationTypes.COMPLETE_REGISTRATION_REQUEST, {
    personId,
    countryId,
    businessModel,
    role,
    functionId,
    sourceSystem,
    companyId,
    authorization,
  })

export const completeRegistrationSuccess = (error: boolean) => action(CompleteRegistrationTypes.COMPLETE_REGISTRATION_SUCCESS, {
    error,
  })

export const completeRegistrationError = () => action(CompleteRegistrationTypes.COMPLETE_REGISTRATION_ERROR)

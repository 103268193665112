import React, { useContext } from 'react'
import { FormContext, FormContextProps } from '@/main/contexts'
import { Container } from '@/main/components/pages-structures'
import {
  CardContent,
  CardHeader,
  ControlledAddressNumberField,
  ControlledTextField,
  SelectOption,
} from '@/main/components'
import { FormProvider, useForm } from 'react-hook-form'
import { Page } from '@/domain/models/page/page'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { AsyncSelect } from '@/main/components/async-select/async-select'
import { AddressFormValues, AddressMessages } from './models'
import { adaptFormValuesWithAnotherDeliveryAddress } from './adapters/output-adapter'
import { useAddressPageStyles } from './address-page.styles'

type AvonMexicoFormValues = {
  state: SelectOption,
  colony: string
}

const geoCommercialLevelsAdapter = {
  level0: 'country',
  level1: 'zipCode',
  level2: 'state',
  level3: 'colony',
  level4: 'mainStreet',
  level5: 'number',
  level6: 'complementaryData',
}

export const AddressPage: React.FC = () => {
  const {
    onSubmit,
    goToPreviousPage = () => {},
    goToNextPage = () => {},
  } = useContext<FormContextProps>(FormContext)

  const formMethods = useForm<AddressFormValues & AvonMexicoFormValues>({
    mode: 'onChange',
  })

  // States Mock
  const statesArr = new Array(10).fill({}).map((v, idx) => ({
    value: `state${idx + 1}`, label: `state ${idx + 1}`,
  }))
  const states: Promise<SelectOption[]> = new Promise((r) => r(statesArr))

  const {
    control,
    formState: { isValid, isSubmitting },
    getValues,
  } = formMethods

  const onNextButtonClick = () => {
    const formValues = getValues()
    const parsedValue = adaptFormValuesWithAnotherDeliveryAddress(formValues, geoCommercialLevelsAdapter)
    onSubmit(parsedValue, goToNextPage)
  }
  const messages = usePageMessages(Page.Address).messages as AddressMessages

  const formRules = { required: messages.addressRequiredError }
  const addressPageClasses = useAddressPageStyles()

  return (
    <Container
      previousButtonLabel={messages.previousButtonLabel}
      onPreviousButtonClick={goToPreviousPage}
      nextButtonLabel={messages.nextButtonLabel}
      onNextButtonClick={onNextButtonClick}
      disableNextButton={!isValid}
      isLoading={isSubmitting}
    >
      <CardHeader
        title={messages.title}
        subtitle={messages.subtitle}
      />
      <CardContent>
        <FormProvider {...formMethods}>
          <ControlledTextField
            id="mainStreet"
            label={messages.mainStreet}
            control={control}
            rules={formRules}
            showAsterisk={false}
            required
          />
          <div className={addressPageClasses.formRow}>
            <div className={addressPageClasses.numberInput}>
              <ControlledAddressNumberField
                id="number"
                label={messages.number}
                placeholder=""
                requiredErrorMessage={messages.addressRequiredError}
                noNumberLabel={messages.checkBoxWithoutNumber}
                showAsterisk={false}
              />
            </div>
            <div className={addressPageClasses.complementInput}>
              <ControlledTextField
                id="complement"
                label={messages.complementaryData}
                control={control}
              />
            </div>
          </div>
          <ControlledTextField
            id="references"
            label={messages.references}
            control={control}
            placeholder={messages.placeholders.references}
          />

          <ControlledTextField
            id="colony"
            label={messages.colony}
            control={control}
            rules={formRules}
            placeholder={messages.placeholders.colony}
            showAsterisk={false}
            required
          />

          <AsyncSelect
            id="state"
            label={messages.state}
            placeholder={messages.placeholders.state}
            rules={formRules}
            control={control}
            optionsPromise={states}
          />
        </FormProvider>
      </CardContent>
    </Container>
  )
}

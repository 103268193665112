import { CountryId } from '@/domain/models/country'

export const getGenderNames = (countryId: CountryId, key: string) => {
  switch (countryId) {
    case CountryId.Malaysia:
      return genders_en[key]
    case CountryId.Chile:
    case CountryId.Colombia:
      return genders_cl[key]
    default:
      return genders_es[key]
  }
}

const genders_en = {
  male: 'Male',
  female: 'Female',
  other: 'Other',
}

const genders_cl = {
  male: 'Masculino',
  female: 'Femenino',
  other: 'Prefiero no informar',
}

const genders_es = {
  male: 'Masculino',
  female: 'Femenino',
  other: 'Otro',
}

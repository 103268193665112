import { Page } from '@/domain/models/page/page'
import { TFunction } from 'i18next'
import { PagesMessages, PossiblePageMessageOptions } from '@/domain/models/messages/messages'
import { GetPageMessages } from '@/domain/use-cases/messages/get-page-messages'
import { getPersonalDataMessages } from '@/data/use-cases/messages/page-messages/messages.personalData'

import { getDocumentationMessages } from './page-messages/messages.documentation'
import { getDigitalStoreMessages } from './page-messages/messages.digitalStore'
import { getAttachmentsMessages } from './page-messages/messages.attachments'
import { getDigitalStoreBrasilMessages } from './page-messages/messages.digitalStoreBrasil'
import { getPreRegisterMessages } from './page-messages/messages.preRegister'
import { getAddressMessages } from './page-messages/messages.address'
import { getPaymentAccountStatusMessages } from './page-messages/messages.paymentAccountStatus'
import { getPaymentAccountStatusSuccessMessages } from './page-messages/messages.paymentAccountStatusSuccess'
import { getDigitalStoreStatusNotFoundMessages } from './page-messages/messages.digitalStoreStatusNotFound'
import { getDigitalStoreStatusPaymentMessages } from './page-messages/messages.digitalStoreStatusPayment'
import { getDigitalStoreStatusDefaultContentMessages } from './page-messages/messages.digitalStoreStatusDefaultContent'
import { getDigitalStoreStatusPendingAgreementsMessages } from './page-messages/messages.digitalStoreStatusPendingAgreements'
import { getDigitalStoreStatusIntroductionStepMessages } from './page-messages/messages.digitalStoreStatusIntroductionStep'
import { getDigitalStoreStatusEmailStepMessages } from './page-messages/messages.digitalStoreStatusEmailStep'
import { getDigitalStoreStatusAccountStepMessages } from './page-messages/messages.digitalStoreStatusAccountStep'
import { getDigitalStoreStatusAccountResetStepMessages } from './page-messages/messages.digitalStoreStatusAccountResetStep'
import { getDigitalStoreStatusAccountLinkStepMessages } from './page-messages/messages.digitalStoreStatusAccountLinkStep'
import { getDigitalSignatureMessages } from './page-messages/messages.digitalSignature'

const messagesCallbackByPage: {[value in Page]: (translate: TFunction, options?: PossiblePageMessageOptions) => PagesMessages[value] } = {
    [Page.Documentation]: getDocumentationMessages,
    [Page.DigitalSignaturePage]: getDigitalSignatureMessages,
    [Page.DigitalStore]: getDigitalStoreMessages,
    [Page.PersonalData]: getPersonalDataMessages,
    [Page.PreRegister]: getPreRegisterMessages,
    [Page.Attachments]: getAttachmentsMessages,
    [Page.DigitalStoreBrasil]: getDigitalStoreBrasilMessages,
    [Page.PaymentAccountStatus]: getPaymentAccountStatusMessages,
    [Page.PaymentAccountStatusSuccess]: getPaymentAccountStatusSuccessMessages,
    [Page.Address]: getAddressMessages,
    [Page.AuthorizationCode]: () => undefined,
    [Page.Completed]: () => undefined,
    [Page.Indication]: () => undefined,
    [Page.DigitalStoreStatus]: () => undefined,
    [Page.DigitalStoreStatusNotFound]: getDigitalStoreStatusNotFoundMessages,
    [Page.DigitalStoreStatusDefaultContent]: getDigitalStoreStatusDefaultContentMessages,
    [Page.DigitalStoreStatusPayment]: getDigitalStoreStatusPaymentMessages,
    [Page.DigitalStoreStatusPendingAgreements]: getDigitalStoreStatusPendingAgreementsMessages,
    [Page.DigitalStoreStatusIntroductionStep]: getDigitalStoreStatusIntroductionStepMessages,
    [Page.DigitalStoreStatusEmailStep]: getDigitalStoreStatusEmailStepMessages,
    [Page.DigitalStoreStatusAccountStep]: getDigitalStoreStatusAccountStepMessages,
    [Page.DigitalStoreStatusAccountResetStep]: getDigitalStoreStatusAccountResetStepMessages,
    [Page.DigitalStoreStatusAccountLinkStep]: getDigitalStoreStatusAccountLinkStepMessages,
}

export const getPageMessages: GetPageMessages = (page: Page, translate: TFunction, options?: PossiblePageMessageOptions) => {
  const getMessages = messagesCallbackByPage[page]
  return getMessages(translate, options)
}

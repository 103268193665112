import { TFunction } from 'i18next'
import {
  PersonalDataMessages,
} from '@/domain/models/messages/messages'

export function getPersonalDataMessages(translate: TFunction): PersonalDataMessages {
  return {
    title: translate('personalDataPage.title'),
    subtitle: translate('personalDataPage.subtitle'),
    nextButtonLabel: translate('nextButtonLabel'),
    previousButtonLabel: translate('previousButtonLabel'),
    fullName: {
      label: translate('personalDataPage.fullName.label'),
      placeholder: translate('personalDataPage.fullName.placeholder'),
      errorMessage: translate('personalDataPage.fullName.errorMessage'),
      infoText: translate('personalDataPage.fullName.infoText'),
    },
    phone: {
      label: translate('personalDataPage.phoneType.label'),
      options: {
        cellular: {
          label: translate('personalDataPage.phoneType.cellular.label'),
          phoneNumber: {
            placeholder: translate('personalDataPage.phoneType.cellular.phoneNumber.placeholder'),
            errorMessage: {
              firstDigit: translate('personalDataPage.phoneType.cellular.phoneNumber.firstDigit'),
              length: translate('personalDataPage.phoneType.cellular.phoneNumber.length'),
            },
          },
        },
        line: {
          label: translate('personalDataPage.phoneType.line.label'),
          phoneNumber: {
            placeholder: translate('personalDataPage.phoneType.line.phoneNumber.placeholder'),
            errorMessage: {
              firstDigit: translate('personalDataPage.phoneType.line.phoneNumber.firstDigit'),
              length: translate('personalDataPage.phoneType.line.phoneNumber.length'),
            },
          },
        },
      },
      requiredErrorMessage: translate('requiredErrorMessage'),
    },
    email: {
      label: translate('personalDataPage.email.label'),
      placeholder: translate('personalDataPage.email.placeholder'),
      errorMessage: translate('personalDataPage.email.errorMessage'),
      infoText: translate('personalDataPage.email.infoText'),
      requiredErrorMessage: translate('requiredErrorMessage'),
    },
    geographicalLevels: {
      level1: translate('geographicalLevels.level1'),
      level2: translate('geographicalLevels.level2'),
      level3: translate('geographicalLevels.level3'),
      level4: translate('geographicalLevels.level4'),
      placeholders: {
        level1: translate('geographicalLevels.placeholders.level1'),
        level2: translate('geographicalLevels.placeholders.level2'),
        level3: translate('geographicalLevels.placeholders.level3'),
        level4: translate('geographicalLevels.placeholders.level4'),
      },
      requiredErrorMessage: translate('requiredErrorMessage'),
    },
    zipCode: {
      label: translate('personalDataPage.zipCode.label'),
      placeholder: translate('personalDataPage.zipCode.placeholder'),
      errorMessage: translate('personalDataPage.zipCode.errorMessage'),
      requiredErrorMessage: translate('requiredErrorMessage'),
    },
    recommender: {
      noRecommenderText: {
        label: translate('personalDataPage.recommender.noRecommenderText.label'),
      },
      input: {
        label: translate('personalDataPage.recommender.input.label'),
        placeholder: translate('personalDataPage.recommender.input.placeholder'),
      },
      button: {
        text: translate('personalDataPage.recommender.button.text'),
      },
      requiredErrorMessage: translate('requiredErrorMessage'),
      modal: {
        labelCode: translate('personalDataPage.recommender.modal.labelCode'),
        searchNotFound: translate('personalDataPage.recommender.modal.searchNotFound'),
        searchError: translate('personalDataPage.recommender.modal.searchError'),
        okButtonLabel: translate('personalDataPage.recommender.modal.okButtonLabel'),
        cancelButtonLabel: translate('personalDataPage.recommender.modal.cancelButtonLabel'),
        confirmButtonLabel: translate('personalDataPage.recommender.modal.confirmButtonLabel'),
        alertTitle: translate('personalDataPage.recommender.modal.alertTitle'),
        instructionsTitle: translate('personalDataPage.recommender.modal.instructionsTitle'),
      },
    },
    dialog: {
      infoText: translate('personalDataPage.dialog.infoText'),
      closeButtonText: translate('personalDataPage.dialog.closeButtonText'),
    },
  }
}

import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web'
import { deviceSize } from '@/utils/device-sizes'

export const useAddressPageStyles = makeStyles<Theme>((theme) => {
  const displayEqualOrWiderThenTablet = theme.breakpoints.up(deviceSize.tablet)

  return {
    cardHeader: {
      display: 'block',
      padding: 0,
    },
    title: {
      fontWeight: theme.typography.fontWeightMedium,
      marginBottom: theme.spacing(2),
    },
    subtitle: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    copyAddressCheckbox: {
      paddingTop: theme.spacing(1),
    },
    formRow: {
      [displayEqualOrWiderThenTablet]: {
        display: 'inline-flex',
        width: '100%',
        justifyContent: 'space-between',
      },
    },
    inputFullWidth: {
      [displayEqualOrWiderThenTablet]: {
        width: '100%',
      },
    },
    inputPadLeft: {
      [displayEqualOrWiderThenTablet]: {
        paddingLeft: theme.spacing(1),
      },
    },
    inputPadRight: {
      [displayEqualOrWiderThenTablet]: {
        paddingRight: theme.spacing(1),
      },
    },
    numberInput: {
      [displayEqualOrWiderThenTablet]: {
        paddingRight: theme.spacing(1),
      },
    },
    complementInput: {
      [displayEqualOrWiderThenTablet]: {
        paddingLeft: theme.spacing(1),
        width: '100%',
      },
    },
    departmentInput: {
      [displayEqualOrWiderThenTablet]: {
        paddingRight: theme.spacing(1),
        width: '100%',
      },
    },
    cityInput: {
      [displayEqualOrWiderThenTablet]: {
        paddingLeft: theme.spacing(1),
        width: '100%',
      },
    },
  }
})

import * as React from 'react'
import {
  ProgressIndicator,
} from '@naturacosmeticos/natds-web/dist/Components/ProgressIndicator'
import { StatusType } from '@/domain/use-cases/digital-store'
import { StatusIcon } from '@/main/components'
import { Card } from '@/main/pages/digital-store/components/Card/card'
import {
    StatusBlock,
    Container,
    StyledCardTitle,
    StyledTextParagraph,
  } from './digital-store-status-base-card.styles'

export interface DigitalStoreStatusBaseCardProps {
    isLoading?: boolean,
    statusType?: StatusType,
    title?: string,
    subtitle?: string,
    statusMessage: string,
    hasDigitalStoreApproved?: boolean,
}

export const DigitalStoreStatusBaseCard: React.FunctionComponent<DigitalStoreStatusBaseCardProps> = ({
    isLoading,
    statusType,
    statusMessage,
    title,
    subtitle,
    hasDigitalStoreApproved = false,
    children,
}) => {
    if (isLoading) {
        return (
          <Container isLoading>
            <ProgressIndicator />
          </Container>
        )
    }

    return (
      <Container>
        <Card>
          <StatusIcon name={statusType} data-testId="statusIcon" />
          {title && <StyledCardTitle title={title} subtitle={subtitle} isApproved={hasDigitalStoreApproved} />}
          <StatusBlock>
            <StyledTextParagraph
              alignLeft={hasDigitalStoreApproved}
              content={statusMessage}
              hasMarginBottom
              data-testid="digitalStoreStatusMessage"
            />
            {children}
          </StatusBlock>
        </Card>
      </Container>
    )
}

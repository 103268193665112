import { CountryId } from '@/domain/models/country'
import { useIsInitialRender } from '@/main/hooks/use-is-initial-render'
import { fetchGeoCommercialStructure } from '@/main/components/geo-commercial-fields/fetch/fetch-geo-commercial-structures'
import React, {
 Dispatch, SetStateAction, useEffect, useState,
} from 'react'
import { useFormContext } from 'react-hook-form'
import { AsyncSelect } from '../async-select'
import { SelectOption } from '../select'
import { useGeoCommercialStructureStyles } from './geo-commercial-structure.styles'

interface Level {
  id: string,
  label: string,
  placeholder: string
}
export interface GeoCommercialConfig {
  level1: Level,
  level2: Level,
  level3?: Level,
  level4?: Level,
  requiredErrorMessage: string,
}

type GeoCommercialStructureProps = {
  customConfig: GeoCommercialConfig;
  countryId: CountryId;
  hasAsteriskInTheLabel?: boolean;
}

export const GeoCommercialStructure: React.FC<GeoCommercialStructureProps> = ({
  customConfig: {
    level1, level2, level3, level4, requiredErrorMessage,
  }, countryId, hasAsteriskInTheLabel = false,
}) => {
  const { control, setValue, watch } = useFormContext()
  const selectedLevel1: SelectOption = watch(level1.id)
  const selectedLevel2: SelectOption = watch(level2.id)
  const selectedLevel3: SelectOption = level3 ? watch(level3.id) : null

  const [level1SelectPromise, setLevel1SelectPromise] = useState<Promise<SelectOption[]>>()
  const [level2SelectPromise, setLevel2SelectPromise] = useState<Promise<SelectOption[]>>()
  const [level3SelectPromise, setLevel3SelectPromise] = useState<Promise<SelectOption[]>>()
  const [level4SelectPromise, setLevel4SelectPromise] = useState<Promise<SelectOption[]>>()
  const { isInitialRender } = useIsInitialRender()

  useEffect(() => {
    fetchLevel1(countryId, setLevel1SelectPromise)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchLevel(selectedLevel1, countryId, setLevel2SelectPromise, 2)
    if (!isInitialRender) {
      resetGreaterLevels(setValue, level4, level3, level2)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLevel1])

  useEffect(() => {
    if (!level3) {
      return
    }
    fetchLevel(selectedLevel2, countryId, setLevel3SelectPromise, 3)
    if (!isInitialRender) {
      resetGreaterLevels(setValue, level4, level3)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLevel2])

  useEffect(() => {
    if (!level4) {
      return
    }
    fetchLevel(selectedLevel3, countryId, setLevel4SelectPromise, 4)
    if (!isInitialRender) {
      resetGreaterLevels(setValue, level4)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLevel3])

  const componentClasses = useGeoCommercialStructureStyles()
  const formRules = { required: requiredErrorMessage }

  return (
    <div data-testid="geo-commercial-structure">
      <div className={componentClasses.formRow} data-testid="geo-commercial-first-row">
        <div className={componentClasses.inputWithPair}>
          <AsyncSelect
            id={level1.id}
            name={level1.id}
            data-testid={level1.id}
            label={level1.label}
            placeholder={level1.placeholder}
            optionsPromise={level1SelectPromise}
            control={control}
            rules={formRules}
            hasAsteriskInTheLabel={hasAsteriskInTheLabel}
          />
        </div>
        <div className={componentClasses.inputWithPair}>
          <AsyncSelect
            id={level2.id}
            name={level2.id}
            data-testid={level2.id}
            label={level2.label}
            placeholder={level2.placeholder}
            optionsPromise={level2SelectPromise}
            control={control}
            disabled={!selectedLevel1}
            rules={formRules}
            hasAsteriskInTheLabel={hasAsteriskInTheLabel}
          />
        </div>
      </div>
      {level3 && (
      <div className={componentClasses.formRow} data-testid="geo-commercial-second-row">
        <div className={componentClasses.inputWithPair}>
          <AsyncSelect
            id={level3.id}
            name={level3.id}
            data-testid={level3.id}
            label={level3.label}
            placeholder={level3.placeholder}
            optionsPromise={level3SelectPromise}
            control={control}
            disabled={!selectedLevel2}
            rules={formRules}
            hasAsteriskInTheLabel={hasAsteriskInTheLabel}
          />
        </div>
        {level4 && (
        <div className={componentClasses.inputWithPair}>
          <AsyncSelect
            id={level4.id}
            name={level4.id}
            data-testid={level4.id}
            label={level4.label}
            placeholder={level4.placeholder}
            optionsPromise={level4SelectPromise}
            control={control}
            disabled={!selectedLevel3}
            rules={formRules}
            hasAsteriskInTheLabel={hasAsteriskInTheLabel}
          />
        </div>
        )}
      </div>
      )}
    </div>
  )
}
type SetLevelSelectPromise = Dispatch<SetStateAction<Promise<SelectOption[]>>>
const fetchLevel1 = (countryId: CountryId, setLevel1SelectPromise: SetLevelSelectPromise): void => {
  setLevel1SelectPromise(fetchGeoCommercialStructure({ level: 1, countryId }))
}

const resetGreaterLevels = (
  setValue: (name:string, value:any) => void,
  level4?: Level,
  level3?: Level,
  level2?: Level,
): void => {
  if (level2) setValue(level2.id, null)
  if (level3) setValue(level3.id, null)
  if (level4) setValue(level4.id, null)
}

const fetchLevel = (
  selectedLevel: SelectOption,
  countryId: CountryId,
  setLevelSelectPromise: SetLevelSelectPromise,
  level: number,
): void => {
  if (!selectedLevel) {
    return
  }
  setLevelSelectPromise(fetchGeoCommercialStructure({
    level,
    parentCode: selectedLevel.value,
    countryId,
  }))
 }

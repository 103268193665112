import React, {
  useContext, useEffect, useRef, useState,
} from 'react'
import { Container } from '@/main/components/pages-structures'
import { FormContext, IdentityContext } from '@/main/contexts'
import {
  CardContent, CardHeader, ControlledSelect, SelectOption,
} from '@/main/components'
import { AttachmentsMessageOptions, AttachmentsMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { UploadFile, ValidationResult } from '@/main/components/upload-file/upload-file'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { AttachmentsConfigs } from '@/domain/models/tenant/tenant-configs'
import { useForm } from 'react-hook-form'
import { Attachment, AttachmentType } from '@/domain/models/attachments/attachments'
import { ValidateAttachmentRequestBody } from '@/domain/use-cases/attachment'
import { usePersonDocuments } from '@/main/pages/attachments/hooks/use-person-documents'
import { useAttachmentsPageStyles } from './attachments-page.styles'
import { SubtitleWithPopover, TitleWithTag, UnexpectedErrorDialog } from './components'
import { DialogWithLoader } from './components/dialog-with-loader'
import { AttachmentsPageApi } from './api/make-attachments-page-api'

type DocumentAttachmentState = {name: string, attachments: Attachment[]}
type AttachmentsToValidateMapper = Map<AttachmentType, string>
const megaBytes = 1024 * 1024
const fileMaxSize = 5 * megaBytes

type AttachmentsPageProps = {
  api: AttachmentsPageApi
}

export const AttachmentsPage: React.FC<AttachmentsPageProps> = ({ api }) => {
  const {
    personId, tenantId, functionId, businessModel, role,
  } = useContext(IdentityContext)
  const { goToNextPage, goToPreviousPage } = useContext(FormContext)
  const componentClasses = useAttachmentsPageStyles()
  const { documents, canChooseDocument } = useTenantConfigs(tenantId, Page.Attachments) as AttachmentsConfigs
  const formMethods = useForm<{ document: string}>({ mode: 'onChange' })
  const { setValue, control } = formMethods
  const setValueRef = useRef(setValue)
  const [documentAttachment, setDocumentAttachment] = useState<DocumentAttachmentState>({ name: '', attachments: [] })
  const [documentOptions, setDocumentOptions] = useState([])
  const { messages: initialMessages, getPageMessagesWithNewOptions } = usePageMessages(Page.Attachments, createMessageOptions(documentAttachment))
  const [messages, setMessages] = useState<AttachmentsMessages>(initialMessages as AttachmentsMessages)
  const [nextButtonLabel, setNextButtonLabel] = useState<string>(messages.validateButtonLabel)
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true)
  const [isValidateDialogOpen, setIsValidateDialogOpen] = useState<boolean>(false)
  const [isUnexpectedErrorDialogOpen, setIsUnexpectedErrorDialogOpen] = useState<boolean>(false)
  const [attachmentsToValidate, setAttachmentsToValidate] = useState<AttachmentsToValidateMapper>(new Map())
  const personData = usePersonDocuments({ getPersonData: api.getPersonData })

  useEffect(() => {
    if (personData.isLoading || !personData.documents) {
      return
    }

    const preRegisterDocument = personData.documents[0]?.type
    const document = documents.find(({ type }) => type === preRegisterDocument)
    const options = documents.map(({ name }) => createSelectOption(name))

    setValueRef.current('document', createSelectOption(document.name))
    setDocumentOptions(options)
    setDocumentAttachment({ name: document.name, attachments: document.attachments })
  }, [documents, personData])

  useEffect(() => {
    const newOptions = createMessageOptions(documentAttachment)
    const newMessages = getPageMessagesWithNewOptions(newOptions) as AttachmentsMessages
    setMessages(newMessages)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentAttachment])

  useEffect(() => {
    const isReadyToValidate = isAttachmentsReadyToValidate(documentAttachment.attachments, attachmentsToValidate)
    setNextButtonDisabled(!isReadyToValidate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentAttachment, attachmentsToValidate])

  const uploadFile = async (attachmentType: number, file: File) => {
    setNextButtonLabel(messages.validateButtonLabel)

    try {
      const { downloadUrl } = await api.upload({
        attachment: file, attachmentType, personId, tenantId,
      })

      const newMapper = new Map(attachmentsToValidate)
      newMapper.set(attachmentType, downloadUrl)
      setAttachmentsToValidate(newMapper)
    } catch (e) {
      setIsUnexpectedErrorDialogOpen(true)
    }
  }

  const handleOnChangeDocument = ({ label }: SelectOption) => {
    const document = documents.find(({ name }) => name === label)
    setDocumentAttachment({ name: label, attachments: document.attachments })
    setNextButtonLabel(messages.validateButtonLabel)
    setNextButtonDisabled(true)
    setAttachmentsToValidate(new Map())
  }

  const handleOnNextButtonClick = async () => {
    if (nextButtonLabel === messages.validateButtonLabel) {
      await onValidateButtonClick()
    } else {
      onContinueButtonClick()
    }
  }

  const onValidateButtonClick = async () => {
    setIsValidateDialogOpen(true)

    try {
      const attachmentsResultValidated = await api.validate({
        personId, tenantId, businessModel, functionId, role, attachments: formatAttachmentToValidateForRequest(attachmentsToValidate),
      })

      const attachmentTypes = []
      attachmentsResultValidated.forEach((attachmentResult) => {
        const oldAttachment = documentAttachment.attachments.find((attachment) => attachment.type === attachmentResult.attachmentType)
        const updatedAttachment = attachmentResult.isValid
          ? { ...oldAttachment, message: messages.succeedValidationMessage, status: 'success' }
          : { ...oldAttachment, message: messages.failedValidationMessage, status: 'error' }
        attachmentTypes.push(updatedAttachment)
      })
      setDocumentAttachment({ ...documentAttachment, attachments: attachmentTypes })

      if (attachmentsResultValidated.every(((attachment) => attachment.isValid))) {
        setNextButtonLabel(messages.nextButtonLabel)
      }
      setIsValidateDialogOpen(false)
    } catch (e) {
      setIsValidateDialogOpen(false)
      setIsUnexpectedErrorDialogOpen(true)
    }
  }

  const onContinueButtonClick = () => {
    goToNextPage()
  }

  const onSetAttachmentState = ({ status, message }: ValidationResult, index: number) => {
    const { type, title } = documentAttachment.attachments[index]
    const newAttachment = {
      type, title, status, message,
    }

    const attachmentTypes = [...documentAttachment.attachments]
    attachmentTypes[index] = newAttachment
    setDocumentAttachment({ name: documentAttachment.name, attachments: attachmentTypes })
  }

  return (
    <Container
      previousButtonLabel={messages.previousButtonLabel}
      onPreviousButtonClick={goToPreviousPage}
      nextButtonLabel={nextButtonLabel}
      onNextButtonClick={handleOnNextButtonClick}
      disableNextButton={nextButtonDisabled}
      isLoading={personData.isLoading}
    >
      <div>
        <CardHeader
          title={(<TitleWithTag componentClasses={componentClasses} title={messages.title} tag={documentAttachment.name} />)}
          subtitle={(<SubtitleWithPopover componentClasses={componentClasses} subtitle={messages.subtitle} popoverText={messages.subtitlePopover} />)}
        />
        <CardContent>
          { documents.length > 1 && canChooseDocument
          && (
            <ControlledSelect
              id="document"
              name="document"
              label={messages.dropdownLabel}
              aria-label={messages.dropdownLabel}
              options={documentOptions}
              control={control}
              onChange={handleOnChangeDocument}
            />
          )}
          <>
            {
              documentAttachment.attachments.length > 0
              && documentAttachment.attachments.map((attachment, index) => (
                <UploadFile
                  id={`attachment-${attachment.type}`}
                  title={attachment.title}
                  subtitle={messages.subtitleUploadFile}
                  uploadButtonName={messages.uploadFileButtonName}
                  changeButtonName={messages.changeFileButtonName}
                  fileMaxSize={fileMaxSize}
                  maxSizeErrorMessage={messages.subtitleUploadFile}
                  invalidFileFormatErrorMessage="Invalid format"
                  customMessage={attachment.message}
                  status={attachment.status}
                  setAttachmentState={(validation: ValidationResult) => onSetAttachmentState(validation, index)}
                  uploadCallback={(file) => uploadFile(attachment.type, file)}
                  dataTestId={`attachment-${attachment.type}`}
                  key={`attachment-${attachment.type}`}
                  className={componentClasses.uploadFile}
                />
              ))
            }
          </>
        </CardContent>

        <DialogWithLoader
          isOpen={isValidateDialogOpen}
          text={messages.validateDialogText}
          componentClasses={componentClasses}
        />

        <UnexpectedErrorDialog
          isOpen={isUnexpectedErrorDialogOpen}
          title={messages.unexpectedErrorMessage}
          buttonText={messages.unexpectedErrorButton}
          buttonCallback={() => setIsUnexpectedErrorDialogOpen(false)}
        />
      </div>
    </Container>
  )
}

function createSelectOption(valueAndLabel: string): SelectOption {
  return { label: valueAndLabel, value: valueAndLabel }
}

function createMessageOptions(attachment: DocumentAttachmentState): AttachmentsMessageOptions {
  return { subtitleDocument: attachment.name }
}

function formatAttachmentToValidateForRequest(attachmentsToValidate: AttachmentsToValidateMapper): ValidateAttachmentRequestBody[] {
  const attachments = Array.from(attachmentsToValidate, ([attachmentType, downloadUrl]) => ({ attachmentType, downloadUrl }))
  return attachments
}

function isAttachmentsReadyToValidate(attachments: Attachment[], attachmentsToValidate: AttachmentsToValidateMapper): boolean {
  return attachments.every((attachment) => attachment.status !== 'error')
    && attachments.length === attachmentsToValidate.size
}

import { CountryId } from '@/domain/models/country'

export const getCountryNames = (countryId: CountryId, key: string) => {
  switch (countryId) {
    case CountryId.Malaysia:
      return countries_en[key]
    default:
      return countries_es[key]
  }
}

const countries_es = {
  nationality_afghan: 'Afgano',
  nationality_albanian: 'Albanés',
  nationality_american: 'Norteamericano',
  nationality_andorran: 'Andorrano',
  nationality_angolan: 'Angoleño',
  nationality_anguillan: 'Anguillan',
  nationality_antartic: 'Antártico',
  nationality_antiguans: 'Antillano',
  nationality_arab: 'Árabe',
  nationality_arubean: 'Arubiano',
  nationality_algerian: 'Argelino',
  nationality_argentinean: 'Argentino',
  nationality_armenian: 'Armenio',
  nationality_australian: 'Australiano',
  nationality_austrian: 'Austriaco',
  nationality_azerbaijani: 'Azerbaiyano',
  nationality_bahamian: 'Las Bahamas',
  nationality_bahraini: 'Bahrein',
  nationality_bangladeshi: 'Bangladesh',
  nationality_barbadian: 'Barbandense',
  nationality_batswana: 'Betchuano',
  nationality_belarusian: 'Bielo ruso',
  nationality_belgian: 'Belga',
  nationality_belizean: 'Beliceño',
  nationality_beninese: 'Beninés',
  nationality_bermudian: 'Bermudés',
  nationality_bhutanese: 'Butanés',
  nationality_bolivian: 'Boliviano',
  nationality_bosnian: 'Bosnio',
  nationality_bouvet_island: 'Ilhas Bouvet',
  nationality_brazilian: 'Brasileño',
  nationality_british: 'Británico',
  nationality_bruneian: 'Bruneano',
  nationality_bulgarian: 'Búlgaro',
  nationality_burkinabe: 'Burkina Faso',
  nationality_burmese: 'Myanmarense',
  nationality_burundian: 'Burundiano',
  nationality_cambodian: 'Camboyano',
  nationality_cameroonian: 'Camerunés',
  nationality_canadian: 'Canadiense',
  nationality_cape_verdean: 'Caboverdiano',
  nationality_central_african: 'Centrafricano',
  nationality_chadian: 'Chadiano',
  nationality_chilean: 'Chileno',
  nationality_chinese: 'Chino',
  nationality_colombian: 'Colombiano',
  nationality_comoran: 'Comorano',
  nationality_congolese: 'Congolés',
  nationality_cook_island: 'Cookiano',
  nationality_costa_rican: 'Coreano',
  nationality_corean: 'Costarricense',
  nationality_croatian: 'Croata',
  nationality_cuban: 'Cubano',
  nationality_cypriot: 'Cipriano',
  nationality_czech: 'Checo',
  nationality_danish: 'Danés',
  nationality_djibouti: 'Djibutiano',
  nationality_dominican: 'Dominicano',
  nationality_dutch: 'Holandés',
  nationality_east_timorese: 'Timor Oriental',
  nationality_ecuadorean: 'Ecuatoriano',
  nationality_egyptian: 'Egipcio',
  nationality_equatorial_guinean: 'Guineano',
  nationality_eritrean: 'Eritreu',
  nationality_estonian: 'Estonio',
  nationality_ethiopian: 'Etíope',
  nationality_fijian: 'Fiyiano',
  nationality_filipino: 'Filipino',
  nationality_finnish: 'Finlandés',
  nationality_french: 'Francés',
  nationality_gabonese: 'Gabonés',
  nationality_gambian: 'Gambiano',
  nationality_georgian: 'Georgiano',
  nationality_german: 'Alemán',
  nationality_gibraltar: 'Ghanés',
  nationality_ghanaian: 'Gibraltarian',
  nationality_greek: 'Griego',
  nationality_grenadian: 'Granadino',
  nationality_guatemalan: 'Guatemalteco',
  nationality_guinea_bissauan: 'Guiné-Bissau',
  nationality_guinean: 'Guineano',
  nationality_guyanese: 'Guayanés',
  nationality_haitian: 'Haitiano',
  nationality_honduran: 'Hondureño',
  nationality_hong_kong: 'Hong konguês',
  nationality_hungarian: 'Húngaro',
  nationality_i_kiribati: 'Kiribatiano',
  nationality_icelander: 'Islandés',
  nationality_indian: 'Indú',
  nationality_indonesian: 'Indonesio',
  nationality_iranian: 'Iraní',
  nationality_iraqi: 'Iraquí',
  nationality_irish: 'Irlandés',
  nationality_israeli: 'Israelita',
  nationality_italian: 'Italiano',
  nationality_ivorian: 'Costa de Marfil',
  nationality_jamaican: 'Jamaicano',
  nationality_japanese: 'Japonés',
  nationality_jordanian: 'Jordano',
  nationality_kazakhstani: 'Kazaja',
  nationality_kenyan: 'De Kenia',
  nationality_kittian_and_nevisian: 'De São Cristovão e Nevis',
  nationality_kuwaiti: 'Kuwaitiano',
  nationality_kyrgyz: 'Kirguises',
  nationality_laotian: 'Laosiano',
  nationality_latvian: 'Letón',
  nationality_lebanese: 'Libanés',
  nationality_liberian: 'Liberiano',
  nationality_libyan: 'Libio',
  nationality_liechtensteiner: 'Liechtensteiniano',
  nationality_lithuanian: 'Lituano',
  nationality_luxembourger: 'Luxemburgués',
  nationality_macedonian: 'Macedonio',
  nationality_malagasy: 'Madagascar',
  nationality_malawian: 'Malawiano',
  nationality_malaysian: 'Malayo',
  nationality_maldivian: 'Maldivo',
  nationality_malian: 'Malí',
  nationality_maltese: 'Maltés',
  nationality_marshallese: 'Marshallino',
  nationality_mauritanian: 'Mauritano',
  nationality_mauritian: 'Mauriciano',
  nationality_mexican: 'Mexicano',
  nationality_micronesian: 'Micronesiano',
  nationality_moldovan: 'Moldavo',
  nationality_monacan: 'Monegasco',
  nationality_montserrat: 'Mongol',
  nationality_mongolian: 'Montserrat',
  nationality_moroccan: 'Marroquí',
  nationality_mosotho: 'Lesotense',
  nationality_mozambican: 'Mozambiqueño',
  nationality_namibian: 'Namibio',
  nationality_nauruan: 'Nauruano',
  nationality_nepalese: 'Nepalés',
  nationality_new_zealander: 'Neozelandés',
  nationality_ni_vanuatu: 'Vanuatuense',
  nationality_nicaraguan: 'Nicaragüense',
  nationality_nigerian: 'Nigeriano',
  nationality_nigerien: 'Níger',
  nationality_niue_island: 'Niuean',
  nationality_norwegian: 'Norfolkiano',
  nationality_norfolk_island: 'Noruego',
  nationality_omani: 'Omaní',
  nationality_pakistani: 'Pakistaní',
  nationality_palauan: 'Palauano',
  nationality_panamanian: 'Panameño',
  nationality_papua_new_guinean: 'Papú',
  nationality_paraguayan: 'Paraguayo',
  nationality_peruvian: 'Peruano',
  nationality_polish: 'Polaco',
  nationality_portuguese: 'Portugués',
  nationality_qatari: 'Qatari',
  nationality_romanian: 'Rumano',
  nationality_russian: 'Ruso',
  nationality_rwandan: 'Ruandés',
  nationality_saint_lucian: 'Santa Elena',
  nationality_salvadoran: 'Santalucense',
  nationality_samoan: 'Salvadoreño',
  nationality_san_marinese: 'Samoano',
  nationality_san_vicent: 'Samarines',
  nationality_saint_helenians: 'Son vicentino',
  nationality_sao_tomean: 'Son tomense',
  nationality_saudi: 'Saudita',
  nationality_senegalese: 'Senegalés',
  nationality_seychellois: 'Seychellois',
  nationality_sierra_leonean: 'Leonés',
  nationality_singaporean: 'Singapur',
  nationality_slovakian: 'Eslovaco',
  nationality_slovenian: 'Esloveno',
  nationality_solomon_islander: 'Salomónica',
  nationality_somali: 'Somalí',
  nationality_south_african: 'Sudafricano',
  nationality_spanish: 'Español',
  nationality_sri_lankan: 'Cingalés',
  nationality_sudanese: 'Apátrida',
  nationality_surinamer: 'Sudanés',
  nationality_stateless: 'Surinamés',
  nationality_swazi: 'Swazilandia',
  nationality_swedish: 'Sueco',
  nationality_swiss: 'Suizo',
  nationality_syrian: 'Sírio',
  nationality_taiwanese: 'Taiwanés',
  nationality_tajik: 'Tayiko',
  nationality_tanzanian: 'Tanzano',
  nationality_thai: 'Tailandés',
  nationality_togolese: 'Togolés',
  nationality_tokelau: 'Toquelauano',
  nationality_tongan: 'Tongano',
  nationality_trinidadian_or_tobagonian: 'Trinitaria',
  nationality_tunisian: 'Tunecino',
  nationality_turkish: 'Turco',
  nationality_turkmens: 'Turcomano',
  nationality_tuvaluan: 'Turco-caicense',
  nationality_turks_caicos: 'Tuvaluano',
  nationality_ugandan: 'Ugandés',
  nationality_ukrainian: 'Ucraniano',
  nationality_us_minor_islands: 'Uruguayo',
  nationality_uruguayan: 'Ilhas Minor Outlying',
  nationality_uzbekistani: 'Uzbeko',
  nationality_vaticano: 'Vaticano',
  nationality_venezuelan: 'Venezolano',
  nationality_vietnamese: 'Vietnamita',
  nationality_wallis_futuna_island: 'Wallisiano',
  nationality_western_sahara: 'Sara occidental',
  nationality_yemenite: 'Yemenite',
  nationality_yugoslavian: 'Yugoslavo',
  nationality_zairense: 'Zaire',
  nationality_zambian: 'Zambiano',
  nationality_zimbabwean: 'Zimbabuense',
}

const countries_en = {
  nationality_afghan: 'Afghan',
  nationality_albanian: 'Albanian',
  nationality_american: 'American',
  nationality_andorran: 'Andorran',
  nationality_angolan: 'Angolan',
  nationality_anguillan: 'Anguillan',
  nationality_antartic: 'Antartic',
  nationality_antiguans: 'Antiguans',
  nationality_arab: 'Arab',
  nationality_arubean: 'Arubean',
  nationality_algerian: 'Algerian',
  nationality_argentinean: 'Argentinean',
  nationality_armenian: 'Armenian',
  nationality_australian: 'Australian',
  nationality_austrian: 'Austrian',
  nationality_azerbaijani: 'Azerbaijani',
  nationality_bahamian: 'Bahamian',
  nationality_bahraini: 'Bahraini',
  nationality_bangladeshi: 'Bangladeshi',
  nationality_barbadian: 'Barbadian',
  nationality_batswana: 'Batswana',
  nationality_belarusian: 'Belarusian',
  nationality_belgian: 'Belgian',
  nationality_belizean: 'Belizean',
  nationality_beninese: 'Beninese',
  nationality_bermudian: 'Bermudian',
  nationality_bhutanese: 'Bhutanese',
  nationality_bolivian: 'Bolivian',
  nationality_bosnian: 'Bosnian',
  nationality_bouvet_island: 'Bouvet Island',
  nationality_brazilian: 'Brazilian',
  nationality_british: 'British',
  nationality_bruneian: 'Bruneian',
  nationality_bulgarian: 'Bulgarian',
  nationality_burkinabe: 'Burkinabe',
  nationality_burmese: 'Burmese',
  nationality_burundian: 'Burundian',
  nationality_cambodian: 'Cambodian',
  nationality_cameroonian: 'Cameroonian',
  nationality_canadian: 'Canadian',
  nationality_cape_verdean: 'Cape Verdean',
  nationality_central_african: 'Central African',
  nationality_chadian: 'Chadian',
  nationality_chilean: 'Chilean',
  nationality_chinese: 'Chinese',
  nationality_colombian: 'Colombian',
  nationality_comoran: 'Comoran',
  nationality_congolese: 'Congolese',
  nationality_cook_island: 'Cook Islands',
  nationality_costa_rican: 'Costa Rican',
  nationality_corean: 'Corean',
  nationality_croatian: 'Croatian',
  nationality_cuban: 'Cuban',
  nationality_cypriot: 'Cypriot',
  nationality_czech: 'Czech',
  nationality_danish: 'Danish',
  nationality_djibouti: 'Djibouti',
  nationality_dominican: 'Dominican',
  nationality_dutch: 'Dutch',
  nationality_east_timorese: 'East Timorese',
  nationality_ecuadorean: 'Ecuadorean',
  nationality_egyptian: 'Egyptian',
  nationality_equatorial_guinean: 'Equatorial Guinean',
  nationality_eritrean: 'Eritrean',
  nationality_estonian: 'Estonian',
  nationality_ethiopian: 'Ethiopian',
  nationality_fijian: 'Fijian',
  nationality_filipino: 'Filipino',
  nationality_finnish: 'Finnish',
  nationality_french: 'French',
  nationality_gabonese: 'Gabonese',
  nationality_gambian: 'Gambian',
  nationality_georgian: 'Georgian',
  nationality_german: 'German',
  nationality_gibraltar: 'Gibraltar',
  nationality_ghanaian: 'Ghanaian',
  nationality_greek: 'Greek',
  nationality_grenadian: 'Grenadian',
  nationality_guatemalan: 'Guatemalan',
  nationality_guinea_bissauan: 'Guinea-Bissauan',
  nationality_guinean: 'Guinean',
  nationality_guyanese: 'Guyanese',
  nationality_haitian: 'Haitian',
  nationality_honduran: 'Honduran',
  nationality_hong_kong: 'Hong Kong',
  nationality_hungarian: 'Hungarian',
  nationality_i_kiribati: 'I-Kiribati',
  nationality_icelander: 'Icelander',
  nationality_indian: 'Indian',
  nationality_indonesian: 'Indonesian',
  nationality_iranian: 'Iranian',
  nationality_iraqi: 'Iraqi',
  nationality_irish: 'Irish',
  nationality_israeli: 'Israeli',
  nationality_italian: 'Italian',
  nationality_ivorian: 'Ivorian',
  nationality_jamaican: 'Jamaican',
  nationality_japanese: 'Japanese',
  nationality_jordanian: 'Jordanian',
  nationality_kazakhstani: 'Kazakhstani',
  nationality_kenyan: 'Kenyan',
  nationality_kittian_and_nevisian: 'Kittian and Nevisian',
  nationality_kuwaiti: 'Kuwaiti',
  nationality_kyrgyz: 'Kyrgyz',
  nationality_laotian: 'Laotian',
  nationality_latvian: 'Latvian',
  nationality_lebanese: 'Lebanese',
  nationality_liberian: 'Liberian',
  nationality_libyan: 'Libyan',
  nationality_liechtensteiner: 'Liechtensteiner',
  nationality_lithuanian: 'Lithuanian',
  nationality_luxembourger: 'Luxembourger',
  nationality_macedonian: 'Macedonian',
  nationality_malagasy: 'Malagasy',
  nationality_malawian: 'Malawian',
  nationality_malaysian: 'Malaysian',
  nationality_maldivian: 'Maldivian',
  nationality_malian: 'Malian',
  nationality_maltese: 'Maltese',
  nationality_marshallese: 'Marshallese',
  nationality_mauritanian: 'Mauritanian',
  nationality_mauritian: 'Mauritian',
  nationality_mexican: 'Mexican',
  nationality_micronesian: 'Micronesian',
  nationality_moldovan: 'Moldovan',
  nationality_monacan: 'Monacan',
  nationality_montserrat: 'Montserrat',
  nationality_mongolian: 'Mongolian',
  nationality_moroccan: 'Moroccan',
  nationality_mosotho: 'Mosotho',
  nationality_mozambican: 'Mozambican',
  nationality_namibian: 'Namibian',
  nationality_nauruan: 'Nauruan',
  nationality_nepalese: 'Nepalese',
  nationality_new_zealander: 'New Zealander',
  nationality_ni_vanuatu: 'Ni-Vanuatu',
  nationality_nicaraguan: 'Nicaraguan',
  nationality_nigerian: 'Nigerian',
  nationality_nigerien: 'Nigerien',
  nationality_niue_island: 'Niue Island',
  nationality_norwegian: 'Norwegian',
  nationality_norfolk_island: 'Norfolk Island',
  nationality_omani: 'Omani',
  nationality_pakistani: 'Pakistani',
  nationality_palauan: 'Palauan',
  nationality_panamanian: 'Panamanian',
  nationality_papua_new_guinean: 'Papua New Guinean',
  nationality_paraguayan: 'Paraguayan',
  nationality_peruvian: 'Peruvian',
  nationality_polish: 'Polish',
  nationality_portuguese: 'Portuguese',
  nationality_qatari: 'Qatari',
  nationality_romanian: 'Romanian',
  nationality_russian: 'Russian',
  nationality_rwandan: 'Rwandan',
  nationality_saint_lucian: 'Saint Lucian',
  nationality_salvadoran: 'Salvadoran',
  nationality_samoan: 'Samoan',
  nationality_san_marinese: 'San Marinese',
  nationality_san_vicent: 'Saint Vincent and the Grenadines',
  nationality_saint_helenians: 'Saint Helenians',
  nationality_sao_tomean: 'Sao Tomean',
  nationality_saudi: 'Saudi',
  nationality_senegalese: 'Senegalese',
  nationality_seychellois: 'Seychellois',
  nationality_sierra_leonean: 'Sierra Leonean',
  nationality_singaporean: 'Singaporean',
  nationality_slovakian: 'Slovakian',
  nationality_slovenian: 'Slovenian',
  nationality_solomon_islander: 'Solomon Islander',
  nationality_somali: 'Somali',
  nationality_south_african: 'South African',
  nationality_spanish: 'Spanish',
  nationality_sri_lankan: 'Sri Lankan',
  nationality_sudanese: 'Sudanese',
  nationality_surinamer: 'Surinamer',
  nationality_stateless: 'Stateless',
  nationality_swazi: 'Swazi',
  nationality_swedish: 'Swedish',
  nationality_swiss: 'Swiss',
  nationality_syrian: 'Syrian',
  nationality_taiwanese: 'Taiwanese',
  nationality_tajik: 'Tajik',
  nationality_tanzanian: 'Tanzanian',
  nationality_thai: 'Thai',
  nationality_togolese: 'Togolese',
  nationality_tokelau: 'Tokelau',
  nationality_tongan: 'Tongan',
  nationality_trinidadian_or_tobagonian: 'Trinidadian or Tobagonian',
  nationality_tunisian: 'Tunisian',
  nationality_turkish: 'Turkish',
  nationality_turkmens: 'Turkmens',
  nationality_tuvaluan: 'Tuvaluan',
  nationality_turks_caicos: 'Turks and Caicos Islands',
  nationality_ugandan: 'Ugandan',
  nationality_ukrainian: 'Ukrainian',
  nationality_us_minor_islands: 'United States Minor Outlying Islands',
  nationality_uruguayan: 'Uruguayan',
  nationality_uzbekistani: 'Uzbekistani',
  nationality_vaticano: 'Vaticano',
  nationality_venezuelan: 'Venezuelan',
  nationality_vietnamese: 'Vietnamese',
  nationality_wallis_futuna_island: 'Wallis and Futuna Islands',
  nationality_western_sahara: 'Western Sahara',
  nationality_yemenite: 'Yemenite',
  nationality_yugoslavian: 'Yugoslavian',
  nationality_zairense: 'Zairense',
  nationality_zambian: 'Zambian',
  nationality_zimbabwean: 'Zimbabwean',
}

// Country IDs in ISO 3166-1 alpha-2 standard
export enum CountryIdAlpha2 {
  Peru = 'PE',
  Malaysia = 'MY',
  Chile = 'CL',
  Brasil = 'BR',
  Colombia = 'CO',
  Argentina = 'AR'
}

export type CountryIdsAlpha2Key = keyof typeof CountryIdAlpha2

export const COUNTRIES_IDS_ALPHA_2_KEYS = new Map<CountryIdAlpha2, CountryIdsAlpha2Key>(
  Object.entries(CountryIdAlpha2).map(([key, value]: [CountryIdsAlpha2Key, CountryIdAlpha2]) => [value, key]),
)

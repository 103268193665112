import React from 'react'
import { useI18n } from '@/main/hooks/useI18n'
import { CountryId } from '@/domain/models/country/country-id'
import { initI18n } from '@/infra/translation/i18next/i18n'
import { store } from '@/infra/global-state/redux/store'
import { Provider } from 'react-redux'
import { DigitalStoreBrasilSuccessMessages, DigitalStoreBrasilSuccessPage } from './digital-store-brasil-success-page'

export const DigitalStoreBrasilSuccessPageWrapper = ({ savedDigitalStoreName, personId }) => {
  const { translate } = useI18n()
  initI18n(CountryId.Brasil)

  const messages: DigitalStoreBrasilSuccessMessages = {
    convergenceTitle: translate('digitalStore.convergenceTitle'),
    convergenceDescription: translate('digitalStore.convergenceDescription'),
    helpInformationTitle: translate('digitalStore.helpInformation.title'),
    helpInformationText: translate('digitalStore.helpInformation.text'),
    shareStoreDescription: translate('digitalStore.shareStoreDescription'),
    addressLabel: translate('digitalStore.addressLabel'),
    baseUrl: translate('digitalStore.baseUrl'),
  }

  return (
    <Provider store={store}>
      <DigitalStoreBrasilSuccessPage
        messages={messages}
        savedDigitalStoreName={savedDigitalStoreName}
        personId={personId}
      />
    </Provider>
  )
}

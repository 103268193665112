import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { CompanyId } from '@/domain/models/company/company-id'
import { CountryId } from '@/domain/models/country'

export enum SearchIndicationStatus {
  SUCCESS = 'SUCCESS',
  NOT_FOUND = 'NOT_FOUND',
  ERROR = 'ERROR',
}

export type IndicationInfo = {
  sellerId?: string
  name?: string
}

export type RemoteSearchIndicationParams = {
  id: string,
  companyId: CompanyId,
  countryId: CountryId
}

export type RemoteSearchIndicationResponse = {
  data?: IndicationInfo
  message: SearchIndicationStatus
  status: string
}

export class RemoteSearchIndication {
  constructor(
    private readonly httpGetClient : HttpGetClient,
    private readonly xApiKey: string,
  ) {}

  async search(params: RemoteSearchIndicationParams) : Promise<RemoteSearchIndicationResponse> {
    const response = await this.httpGetClient.get({
      headers: {
        'x-api-key': this.xApiKey,
        companyId: params.companyId,
        countryId: params.countryId,
      },
      url: '/indication/search',
      query: {
        id: params.id,
      },
    })

    if (response.statusCode === HttpStatusCode.ok) {
      return response.body
    }

    return {
      message: response.body.error.message,
      status: response.body.error.status,
    }
  }
}

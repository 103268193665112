import { ControlledTextField } from '@/main/components'
import { Checkbox } from '@/main/components/checkbox'
import { useFormContext } from 'react-hook-form'
import React, { useState } from 'react'

type ControlledAddressNumberFieldProps = {
  id: string,
  label: string,
  placeholder: string,
  requiredErrorMessage: string,
  noNumberLabel: string,
  showAsterisk?: boolean,
  validChars?: RegExp
}

export const ControlledAddressNumberField: React.FC<ControlledAddressNumberFieldProps> = ({
  id,
  label,
  placeholder,
  requiredErrorMessage,
  noNumberLabel,
  showAsterisk,
  validChars,
}) => {
  const { control, setValue, watch } = useFormContext()
  const numberValue = watch(id)
  const [noNumber, setNoNumber] = useState<boolean>(numberValue === 'S/N')

  const handleNoNumber = (event) => {
    const checked = Boolean(event.target.checked)

    setNoNumber(checked)

    const numberValue = checked ? 'S/N' : ''

    setValue(id, numberValue, { shouldValidate: true, shouldDirty: true })
  }

  const requiredRule = { required: requiredErrorMessage }

  return (
    <div>
      <ControlledTextField
        id={id}
        label={label}
        control={control}
        rules={requiredRule}
        placeholder={placeholder}
        disabled={noNumber}
        showAsterisk={showAsterisk}
        validChars={validChars}
        required
      />
      <Checkbox
        label={noNumberLabel}
        onChange={(e) => handleNoNumber(e)}
        checked={noNumber}
      />
    </div>
  )
}

import { TFunction } from 'i18next'
import {
  PreRegisterMessages,
} from '@/domain/models/messages/messages'

export function getPreRegisterMessages(translate: TFunction): PreRegisterMessages {
  return {
    title: translate('preRegister.title'),
    subtitle: translate('preRegister.subtitle'),
    nextButtonLabel: translate('nextButtonLabel'),
    document: {
      label: translate('preRegister.document.label'),
      placeholder: translate('preRegister.document.placeholder'),
      errorMessage: translate('preRegister.document.errorMessage'),
      requiredErrorMessage: translate('preRegister.document.requiredErrorMessage'),
    },
    link: {
      text: translate('preRegister.link.text'),
    },
    dialog: {
      infoText: translate('preRegister.dialog.infoText'),
      closeButtonText: translate('preRegister.dialog.closeButtonText'),
    },
  }
}

import React from 'react'

import { DigitalStoreCurrentStatus } from '@/domain/models/digital-store'

import { CountryId } from '@/domain/models/country/country-id'
import { DigitalStoreStatusNotFound } from '@/main/pages/digital-store/components/digital-store-status-not-found/digital-store-status-not-found'
import { DigitalStorePage } from '../../../digital-store-page'

import { DigitalStoreStatusDefaultContent } from '../../digital-store-status-default-content'
import { DigitalStoreStatusPendingAgreements } from '../../digital-store-status-pending-agreements'
import { DigitalStoreStatusPayment } from '../../digital-store-status-payment'

const getSelfHelpContentIfAllowed = () => {
  const { ENABLE_DIGITAL_STORE_WITH_MERCADO_PAGO_PERU } = process.env
  const isAllowedSelfHelpContent = ENABLE_DIGITAL_STORE_WITH_MERCADO_PAGO_PERU

  return isAllowedSelfHelpContent ? DigitalStoreStatusPayment : DigitalStoreStatusDefaultContent
}
  const defaultStatusComponents = {
    [DigitalStoreCurrentStatus.PENDING_AGREEMENTS]: DigitalStoreStatusPendingAgreements,
    __default__: DigitalStoreStatusDefaultContent,
  }

  const peruStatusComponents = {
    ...defaultStatusComponents,
    [DigitalStoreCurrentStatus.ACTIVE]: getSelfHelpContentIfAllowed(),
    [DigitalStoreCurrentStatus.PENDING_BANK_ACCOUNT]: getSelfHelpContentIfAllowed(),
    [DigitalStoreCurrentStatus.PENDING_PAYMENT_ACCOUNT]: getSelfHelpContentIfAllowed(),
    [DigitalStoreCurrentStatus.SUSPENDED]: getSelfHelpContentIfAllowed(),
    [DigitalStoreCurrentStatus.NOT_STARTED]: ({ onSave }) => <DigitalStorePage convergence onSave={onSave} />,
  }

  const NotMappedCountryComponents = {
    __default__: DigitalStoreStatusNotFound,
  }

  const statusComponentPerCountry = {
    [CountryId.Peru]: peruStatusComponents,
    [CountryId.Malaysia]: defaultStatusComponents,
    __default__: NotMappedCountryComponents,
  }

  export const getStoreStatusPageByStatus = ({ digitalStoreStatus, countryId }) => {
    const statusComponents = statusComponentPerCountry[countryId] || statusComponentPerCountry.__default__
    return statusComponents[digitalStoreStatus]
      ? statusComponents[digitalStoreStatus]
      : statusComponents.__default__
  }

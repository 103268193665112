import { createGlobalStyle } from 'styled-components'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'
import { colorPalette } from '@/utils/color-palette'

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;
    background-color: white;
  }

  :not(a) > strong {
      color: #333;
  }

  @media ${minDeviceSizeForMediaQueries.mobileXL} {
    body {
      background-color: ${colorPalette.light150};
    }
  }
`

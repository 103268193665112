import { useState } from 'react'
import useSWR from 'swr'
import { makeRemoteFetchDigitalStoreStatuses } from '@/main/factories/remote/digital-store/make-remote-fetch-digital-store-statuses'
import { RemoteFetchDigitalStoreStatuses } from '@/data/use-cases/digital-store'
import { DigitalStoreStatus } from '@/domain/use-cases/digital-store'

const api: RemoteFetchDigitalStoreStatuses = makeRemoteFetchDigitalStoreStatuses()

export const makeGetDigitalStoreStatusesSWR = (countryId, personId, lang) => [
  '/accounts/registers/v1/digital-store/fetch-statuses',
  countryId,
  personId,
  lang,
]

export function useGetDigitalStoreStatusesSWR({
  countryId,
  personId,
  lang = 'en-US',
  shouldFetchOnStart = true,
}) {
  const [isRevalidating, setIsRevalidating] = useState(false)

  const { data, error, mutate } = useSWR<DigitalStoreStatus>(shouldFetchOnStart
    ? makeGetDigitalStoreStatusesSWR(countryId, personId, lang) : null,
    async (url, countryId, personId, lang) => {
      const digitalStoreStatus = await api.load({ countryId, personId, lang })
      return digitalStoreStatus
    })

  const reFetch = () => {
    setIsRevalidating(true)
    mutate().then(() => {
      setIsRevalidating(false)
    })
  }

  return {
    digitalStoreStatus: data,
    isLoading: (!error && !data) || isRevalidating,
    hasError: Boolean(error),
    error,
    reFetch,
  }
}

import { useState } from 'react'
import { CountryId } from '@/domain/models/country'
import { makeSaveDigitalStore } from '@/main/factories/remote'

const saveDigitalStoreRemote = makeSaveDigitalStore()

type UseSaveDigitalStoreParams = {
  countryId: CountryId
  digitalStoreName: string
  personId: string
}

type SaveDigitalStoreParams = {
  agreementId: string
  accepted: boolean
}

export const useSaveDigitalStore = (
  params: UseSaveDigitalStoreParams,
) => {
    const [saved, setSaved] = useState(false)
    const saveDigitalStore = async (agreementsParams:SaveDigitalStoreParams) => {
      const digitalStoreInfo = { ...agreementsParams, ...params }
      const isSaved = await saveDigitalStoreRemote.save(digitalStoreInfo)
      setSaved(isSaved)
    }

  return {
    saveDigitalStore,
    saved,
  }
}

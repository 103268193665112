import { PhoneType } from '@/domain/models/person'
import {
  COUNTRIES_IDS_KEYS,
  CountryId,
  CountryPhoneCode,
} from '@/domain/models/country'
import { Document } from '../../documents/documents'
import { Link } from '../../link'
import { PagesTenantConfigs, PersonalDataConfigs, PreRegisterConfigs } from './configs.d'

const personalData: PersonalDataConfigs = {
  shouldUseZipCode: true,
  shouldUseRecommender: true,
  shouldUsePhoneSelect: false,
  showAsteriskInRequiredLabel: false,
  firstPhoneType: {
    type: PhoneType.CELLULAR,
    mask: [/[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    length: 10,
  },
  countryCode: CountryPhoneCode.Mexico,
  countryName: COUNTRIES_IDS_KEYS.get(CountryId.Mexico).toLocaleUpperCase(),
}

export const preRegister: PreRegisterConfigs = {
  shouldShowCurp: true,
  documents: [
    {
      type: Document.Curp,
      name: 'curp',
      regex: new RegExp(/^[a-z]\w{0,17}$/mi),
      validations: {
        length: (value: string) => value.length === 18,
      },
    },
  ],
  link: {
    href: Link.consultCurp,
  },
}

export const avonMexConfigs: PagesTenantConfigs = {
  documentation: null,
  personalData,
  attachments: null,
  preRegister,
  digitalStore: {
    showSkipButton: false,
  },
  address: {
    shouldUsePageWrapper: false,
  },
}

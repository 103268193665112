import React from 'react'
import styled from 'styled-components'
import {
  StyledCardTitle,
} from '@/main/pages/digital-store/components/digital-store-status-base-card.styles'
import { Button, StatusIcon } from '@/main/components'
import { Card } from '@/main/pages/digital-store/components/Card/card'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { DigitalStoreStatusNotFoundMessages } from '@/domain/models/messages/messages'

const ContainerCard = styled(Card)`
  text-align: center;
  padding: 40px 64px;
`

export type DigitalStoreStatusNotFoundMessagesType = {
  title: string
  subtitle: string
  buttonLabel: string
}

export const DigitalStoreStatusNotFound: React.FC = () => {
  const messages = usePageMessages(Page.DigitalStoreStatusNotFound).messages as DigitalStoreStatusNotFoundMessages

  return (
    <ContainerCard>
      <StatusIcon name="error" />
      <StyledCardTitle title={messages.title} subtitle={messages.subtitle} />
      <Button onClick={() => window.location.assign(window.location.origin)} color="primary" variant="contained">
        { messages.buttonLabel }
      </Button>
    </ContainerCard>
  )
}

import { DialogTitle } from '@/main/components/dialog'
import { Button, Dialog, DialogActions } from '@naturacosmeticos/natds-web'
import React from 'react'

type UnexpectedErrorDialogProps = {
  isOpen: boolean
  title: string
  buttonText: string
  buttonCallback: () => void
}

export const UnexpectedErrorDialog: React.FC<UnexpectedErrorDialogProps> = ({
    isOpen, title, buttonText, buttonCallback,
  }) => (
    <Dialog
      open={isOpen}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogActions>
        <Button
          color="primary"
          onClick={buttonCallback}
          variant="text"
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
)

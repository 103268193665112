import React, { useState } from 'react'
import { ControlledTextField } from '@/main/components'
import { useFormContext } from 'react-hook-form'
import { Typography } from '@naturacosmeticos/natds-web'
import { Checkbox } from '@/main/components/checkbox'
import {
  FieldContainer, RecommenderFieldContainer, SearchButton,
} from '@/main/pages/personal-data/fields/recommender/recommender-field.styles'
import { ModalWithPersonCheckbox } from '@/main/components/modal-with-person-checkbox'
import { ModalPersonMessages } from '@/domain/models/messages/messages'

export type RecommenderFieldMessages = {
  noRecommenderText: {
    label: string
  },
  input: {
    label: string,
    placeholder: string,
  }
  button: {
    text: string
  },
  requiredErrorMessage: string,
  modal: ModalPersonMessages
}

type RecommenderFieldProps = {
  messages: RecommenderFieldMessages,
  showAsteriskInRequiredLabel?: boolean,
  hasNoRecommender?: boolean,
  id?: string
}

export const RecommenderField: React.FC<RecommenderFieldProps> = ({
  messages, showAsteriskInRequiredLabel = false, hasNoRecommender = false, id = 'recommender',
}) => {
  const { control, setValue, getValues } = useFormContext()
  const [noRecommender, setNoRecommender] = useState(hasNoRecommender)
  const recommenderRules = new RegExp(/^\d{0,15}$/)
  const shouldButtonBeDisabled = noRecommender || !getValues(id)
  const searchErrorMessage = ''
  const [isPersonSelected, setIsPersonSelected] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleNoRecommender = (event) => {
    const checked = Boolean(event.target.checked)
    setNoRecommender(checked)
    const value = checked ? messages.noRecommenderText.label : ''
    setValue(id, value, { shouldValidate: true, shouldDirty: true })
  }

  return (
    <>
      <FieldContainer>
        <RecommenderFieldContainer>
          <ControlledTextField
            id={id}
            label={messages.input.label}
            control={control}
            placeholder={messages.input.placeholder}
            validChars={recommenderRules}
            rules={{ required: messages.requiredErrorMessage as string }}
            showAsterisk={showAsteriskInRequiredLabel}
            disabled={noRecommender}
            required
          />
        </RecommenderFieldContainer>

        <SearchButton
          color="primary"
          variant="contained"
          size="large"
          disabled={shouldButtonBeDisabled}
          className="search-recommender-button"
          onClick={() => setIsModalOpen(true)}
        >
          {messages.button.text}
        </SearchButton>
      </FieldContainer>
      <Checkbox
        label={<Typography variant="body2">{messages.noRecommenderText.label}</Typography>}
        onChange={(e) => handleNoRecommender(e)}
        checked={noRecommender}
      />
      <ModalWithPersonCheckbox
        errorMessage={searchErrorMessage}
        info={{
          sellerId: '1234',
          name: 'Name Test',
        }}
        isItemSelected={isPersonSelected}
        isSearchLoading={false}
        onItemSelected={() => setIsPersonSelected(!isPersonSelected)}
        confirmationError={() => setIsModalOpen(!isModalOpen)}
        confirmSelection={() => setIsModalOpen(!isModalOpen)}
        isModalOpen={isModalOpen}
        handleCloseModal={() => setIsModalOpen(!isModalOpen)}
        messages={messages.modal}
      />
    </>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react'
import {
  AuthorizationCodeInfo,
  ResendButton,
  ContainerHorizontal,
  ResendInstructionsText,
  ResendInstructionsContainer,
  VerticalContainer,
  ResendContainer,
  StyledTextField,
} from '@/main/pages/authorization-code/authorization-code-page.styles'
import { FormContext, IdentityContext } from '@/main/contexts'
import { Container } from '@/main/components/pages-structures'
import { ParseStringToReact } from '@/main/components/string-to-react'
import { StatusIcon, CardContent, CardHeader } from '@/main/components'
import {
  AuthorizationCodeMessages,
} from '@/main/pages/authorization-code/authorization-code-configuration-wrapper'
import { SendAuthorizationCode } from '@/domain/use-cases/authorization-code/authorization-code'
import { AcceptAuthorizationCode } from '@/domain/use-cases/authorization-code/accept-authorization-code'
import { isAuthorizationCodeEmailToggleActive } from '@/toggles-config'
import { PhoneType } from '@/domain/models/person'

type AuthorizationCodePageProps = {
  messages: AuthorizationCodeMessages
  sendAuthorizationCode: SendAuthorizationCode
  acceptAuthorizationCode: AcceptAuthorizationCode
}

export const AuthorizationCodePage: React.FC<AuthorizationCodePageProps> = (props) => {
  const { messages, sendAuthorizationCode, acceptAuthorizationCode } = props
  const COUNTDOWN = 30

  const [authorizationCode, setAuthorizationCode] = useState('')
  const [helperText, setHelperText] = useState('')
  const [authorizationCodeFieldState, setAuthorizationCodeFieldState] = useState(undefined)
  const [showCooldownResend, setShowCooldownResend] = useState(false)
  const [countdown, setCountdown] = useState(COUNTDOWN)
  const [isSendingAuthorizationCode, setIsSendingAuthorizationCode] = useState(false)
  const {
    countryId,
    userId,
    personId,
    role,
    businessModel,
    sourceSystem,
    functionId,
    companyId,
    authorization,
    phoneNumber,
    email,
    phoneType,
  } = useContext(IdentityContext)
  const { goToNextPage, goToPreviousPage, bypassStep } = useContext(FormContext)
  const formattedResendInstructions = messages.resendCodeMessage
    .replace('{timeLeft}', countdown.toString())
  const authorizationCodeSuccess = authorizationCodeFieldState === 'success'
  const authorizationCodeLoading = authorizationCodeFieldState === 'loading'
  const authorizationCodeDisabled = authorizationCodeSuccess || authorizationCodeLoading
  const resendButtonDisabled = showCooldownResend || authorizationCodeSuccess

  const startCountdown = () => {
    setCountdown(COUNTDOWN)
    setShowCooldownResend(true)
  }

  const sendAuthorizationCodeHandler = () => sendAuthorizationCode.sendAuthorizationCode({
      phoneNumber,
      userId,
      personId,
      countryId,
      authorization,
      companyId,
      businessModel,
      role,
      functionId,
      sourceSystem,
      email,
      phoneType,
    })

  useEffect(() => {
    let timer

    if (showCooldownResend) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1)
      }, 1000)

      if (countdown === 0) setShowCooldownResend(false)
    }

    return () => clearTimeout(timer)
  }, [showCooldownResend, countdown])

  useEffect(() => {
    if (!isSendingAuthorizationCode && authorization) {
        setIsSendingAuthorizationCode(true)

        sendAuthorizationCodeHandler()
        .then((shouldDisplayPage) => (shouldDisplayPage ? startCountdown() : bypassStep()))
        .finally(() => setIsSendingAuthorizationCode(false))
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resendAuthorizationCode = async () => {
    if (!resendButtonDisabled) {
      await sendAuthorizationCodeHandler()
      startCountdown()
    }
  }

  const validateAuthorizationCode = async (event) => {
    const sanitizedAuthorizationCode = event.target.value.replace(/[^0-9]/, '').substr(0, 6)
    setAuthorizationCode(sanitizedAuthorizationCode)

    const validAuthorizationCodeLength = 6
    if (sanitizedAuthorizationCode.length === validAuthorizationCodeLength) {
      setAuthorizationCodeFieldState('loading')

      const validAuthorizationCode = await acceptAuthorizationCode.accept({
        countryId,
        personId,
        confirmationCode: sanitizedAuthorizationCode,
        businessModel,
        role,
        functionId,
        sourceSystem,
        companyId: Number(companyId),
        userId,
      })

      if (validAuthorizationCode) {
        setAuthorizationCodeFieldState('success')
        setHelperText(messages.authorizationCodeMessages.success)
      } else {
        setAuthorizationCodeFieldState('error')
        setHelperText(messages.authorizationCodeMessages.error)
      }
    } else {
      setAuthorizationCodeFieldState(undefined)
      setHelperText('')
    }
  }
  const getAuthorizationCodeInstructions = () => {
    if (isAuthorizationCodeEmailToggleActive()) {
      return phoneType === PhoneType.CELLULAR
        ? messages.authorizationInstructions.emailAndPhoneNumber
        : messages.authorizationInstructions.email
    }
    return messages.authorizationInstructions.phoneNumber
  }
  return (
    <Container
      previousButtonLabel={messages.previousButtonLabel}
      onPreviousButtonClick={goToPreviousPage}
      nextButtonLabel={messages.nextButtonLabel}
      onNextButtonClick={goToNextPage}
      disableNextButton={!authorizationCode || !authorizationCodeSuccess}
      isLoading={isSendingAuthorizationCode}
    >
      <CardHeader title={messages.title} />
      <CardContent>
        <AuthorizationCodeInfo variant="body1" color="textSecondary">
          <ParseStringToReact stringToParse={getAuthorizationCodeInstructions()} />
        </AuthorizationCodeInfo>

        <ContainerHorizontal>
          <VerticalContainer>
            <StyledTextField
              label={messages.authorizationCode.label}
              helpText={helperText}
              value={authorizationCode}
              onChange={validateAuthorizationCode}
              placeholder={messages.authorizationCode.placeholder}
              state={authorizationCodeFieldState}
              inputProps={{ disabled: authorizationCodeDisabled }}
              id="authorization-code"
            />
          </VerticalContainer>

          <ResendContainer>
            <ResendInstructionsContainer>
              {showCooldownResend && (
                <>
                  <StatusIcon name="pending" size="33px" marginBottom="0" />
                  <ResendInstructionsText variant="body2" color="textSecondary">
                    {formattedResendInstructions}
                  </ResendInstructionsText>
                </>
              )}
            </ResendInstructionsContainer>
            <div>
              <ResendButton href="#" onClick={resendAuthorizationCode} type="button" variant="button" disabled={resendButtonDisabled} color="default" className="resend-button">
                {messages.resendCodeButton}
              </ResendButton>
            </div>
          </ResendContainer>
        </ContainerHorizontal>
      </CardContent>
    </Container>
  )
}

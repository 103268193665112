import {
  AddressUseEnum,
  PersonData,
} from '@/main/contexts'
import { AddressFormValues } from '@/main/pages/address/models'
import { GeoCommercialLevelsAdapter } from './geo-commercial-levels-adapter.d'
import { GeographicalLevelsMapperFactory } from './geographical-levels-mapper'

export const adaptDefaultValues = <T>(person: PersonData, levels: GeoCommercialLevelsAdapter): AddressFormValues & Partial<T> => {
  const amountOfAddresses: number = person?.addresses?.length ?? 0
  const mailingAddressInfo = amountOfAddresses > 0
    ? getMailingAddressInfo(person, levels)
    : {}
  const deliveryAddressInfo = amountOfAddresses > 1
    ? getDeliveryAddressInfo(person, levels)
    : {}
  return {
    ...mailingAddressInfo,
    ...deliveryAddressInfo,
  }
}

const getZipCodeByAddressUse = (person: PersonData, desiredAddressUse: { id: number }) => {
  const address = person.addresses
    .find((address) => address.addressUse
      .find((addressUse) => addressUse.id === desiredAddressUse.id))
  return address.zipCode
}

const getMailingAddressInfo = (person: PersonData, levels: GeoCommercialLevelsAdapter) => {
  const mailingAddressLevels = getAddressLevelsByAddressUse(person, AddressUseEnum.MAILING)
  const mailingAddressLevelsMapper = GeographicalLevelsMapperFactory.createMapper(Object.keys(levels).length, mailingAddressLevels)
  const zipCode = getZipCodeByAddressUse(person, AddressUseEnum.MAILING)
  const mailingInfo = {
    street: mailingAddressLevelsMapper.street(),
    number: mailingAddressLevelsMapper.number(),
    complement: mailingAddressLevelsMapper.complement(),
    references: mailingAddressLevelsMapper.references(),
  }

  return zipCode ? { ...mailingInfo, zipCode } : mailingInfo
}

const getDeliveryAddressInfo = (person: PersonData, levels: GeoCommercialLevelsAdapter) => {
  const deliveryAddressLevels = getAddressLevelsByAddressUse(person, AddressUseEnum.DELIVERY)
  const deliveryAddressLevelsMapper = GeographicalLevelsMapperFactory.createMapper(Object.keys(levels).length, deliveryAddressLevels)
  const deliveryZipCode = getZipCodeByAddressUse(person, AddressUseEnum.DELIVERY)
  const deliveryInfo = {
    [levels.level1]: deliveryAddressLevelsMapper.geoCommercialStructureLevel1(),
    [levels.level2]: deliveryAddressLevelsMapper.geoCommercialStructureLevel2(),
    [levels.level3]: deliveryAddressLevelsMapper.geoCommercialStructureLevel3(),
    [levels.level4]: deliveryAddressLevelsMapper.geoCommercialStructureLevel4(),
    deliveryStreet: deliveryAddressLevelsMapper.street(),
    deliveryNumber: deliveryAddressLevelsMapper.number(),
    deliveryComplement: deliveryAddressLevelsMapper.complement(),
    deliveryReferences: deliveryAddressLevelsMapper.references(),
  }
  cleanObject(deliveryInfo)

  return deliveryZipCode ? { ...deliveryInfo, deliveryZipCode } : deliveryInfo
}

const cleanObject = (object: any): any => {
  delete object.undefined
}

const getAddressLevelsByAddressUse = (person: PersonData, desiredAddressUse: { id: number }) => {
  const address = person.addresses
    .find((address) => address.addressUse
      .find((addressUse) => addressUse.id === desiredAddressUse.id))
  return address.geographicalLevels
}

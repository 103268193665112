import React, { useContext } from 'react'
import { AddressPageWrapper, AddressPage } from '@/main/pages'
import { IdentityContext } from '@/main/contexts'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { Page } from '@/domain/models/page/page'
import { AddressConfigs } from '@/domain/models/tenant/tenant-configs'

export const AddressPageSwitcher = () => {
  const { tenantId } = useContext(IdentityContext)
  const {
    shouldUsePageWrapper,
  } = useTenantConfigs(tenantId, Page.Address) as AddressConfigs

  if (shouldUsePageWrapper === null) {
    throw new Error(`Address Page of the tenant ${tenantId} was not migrated to micro frontend`)
  }

  return shouldUsePageWrapper ? <AddressPageWrapper /> : <AddressPage />
}

import React, { useEffect, useState } from 'react'
import { Checkbox } from '@naturacosmeticos/natds-web'
import { ProgressIndicator } from '@naturacosmeticos/natds-web/dist/Components/ProgressIndicator'
import { Modal } from '@/main/components/modal'
import { makeRemoteGetAgreementByProduct } from '@/main/factories/remote/agreement'
import { CountryId } from '@/domain/models/country/country-id'
import { AgreementProduct } from '@/domain/models/agreement/agreement-product'
import { TermsAgreementLabel } from '@/main/components/accept-terms/terms-agreement-label'
import {
  ContainerHorizontal,
  ContainerVertical,
  StyledFormControlLabel,
  StyledTextParagraph,
} from './accept-terms-component.styles'

type CheckboxProps = {
  onChange?: (...event: any[]) => void,
  onBlur?: () => void,
  ref?: React.Ref<any>,
  value?: boolean
}

export type digitalStoreAcceptTerms = {
  countryId: CountryId
  products: AgreementProduct[]
  personId: string
  setAgreementId: (string) => void
  setUserNeedsToAcceptTerms: (string) => void
  shouldHide?: boolean
  agreementLabel: string
  agreementLabelLink: string
  termsInfo?: string
  modalTitle: string
  modalAccept: string
  modalClose: string
  CheckboxProps: CheckboxProps
  onLoading?: (boolean) => void
}

export const AcceptTermsComponent: React.FC<digitalStoreAcceptTerms> = ({
  personId,
  countryId,
  products,
  setAgreementId,
  setUserNeedsToAcceptTerms,
  shouldHide = false,
  agreementLabel,
  agreementLabelLink,
  termsInfo = null,
  modalTitle,
  modalAccept,
  modalClose,
  CheckboxProps,
  onLoading,
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [agreements, setAgreements] = useState([])
  const [modalOpened, setModalOpened] = useState(false)

  useEffect(() => onLoading && onLoading(isLoading), [isLoading, onLoading])

  useEffect(() => {
    getAgreement()
    async function getAgreement() {
      const remoteGetAgreementByProduct = makeRemoteGetAgreementByProduct()
      try {
        const response = await remoteGetAgreementByProduct.get({
          personId,
          countryId,
          products,
        })
        if (response.agreements) {
          setAgreements(response.agreements ?? [])
          setAgreementId(response.agreements?.map((agreement) => agreement.agreementId))
        }
        setUserNeedsToAcceptTerms(response.userNeedsToAcceptTerms)
      } catch (e) {
        setUserNeedsToAcceptTerms(false)
      } finally {
        setIsLoading(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId, personId])

  const toggleModalOpened = (): void => {
    setModalOpened(!modalOpened)
  }
  const acceptTerms = (): void => {
    setModalOpened(!modalOpened)
    CheckboxProps.onChange(true)
  }

  return !shouldHide && (
    <ContainerHorizontal>
      <Modal
        closeModal={setModalOpened}
        modalOpened={modalOpened}
        onAccept={acceptTerms}
        content={agreements.map((agreement) => <div key={agreement.agreementId}>{agreement.rawText}</div>)}
        title={modalTitle}
        closeLabel={modalClose}
        acceptLabel={modalAccept}
      />
      {isLoading
        ? <ProgressIndicator size={24} />
        : (
          <ContainerVertical>
            <StyledTextParagraph content={termsInfo} />
            <ContainerHorizontal>
              <StyledFormControlLabel
                control={(
                  <Checkbox
                    color="secondary"
                    checked={CheckboxProps.value}
                    onBlur={CheckboxProps.onBlur}
                  />
                )}
                label={(
                  <TermsAgreementLabel
                    agreeText={agreementLabel}
                    agreeLinkText={agreementLabelLink}
                    onClick={(e) => {
                      e.preventDefault()
                      toggleModalOpened()
                    }}
                  />
                )}
                inputRef={CheckboxProps.ref}
                value={CheckboxProps.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => CheckboxProps.onChange(e.target.checked)}
              />
            </ContainerHorizontal>
          </ContainerVertical>
        )}
    </ContainerHorizontal>
  )
}

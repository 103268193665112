import { DigitalStoreMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'
import { DigitalStoreMessageOptions } from '../../../../domain/models/messages/messages.d'

export function getDigitalStoreMessages(translate: TFunction, options: DigitalStoreMessageOptions): DigitalStoreMessages {
  return {
    title: translate('digitalStore.title', options),
    description: translate('digitalStore.description', options),
    convergenceTitle: translate('digitalStore.convergenceTitle', options),
    convergenceDescription: translate('digitalStore.convergenceDescription', options),
    nameLabel: translate('digitalStore.nameLabel', options),
    verifyButtonLabel: translate('digitalStore.verifyButtonLabel', options),
    hint: translate('digitalStore.hint', options),
    addressLabel: translate('digitalStore.addressLabel', options),
    baseUrl: translate('digitalStore.baseUrl', options),
    nextButtonLabel: translate('digitalStore.nextButtonLabel', options),
    nameTip: translate('digitalStore.nameTip', options),
    errorSaving: translate('digitalStore.errorSaving', options),
    genericError: translate('digitalStore.genericError', options),
    invalidNameSpace: translate('digitalStore.invalidNameSpace', options),
    unavailableName: translate('digitalStore.unavailableName', options),
    availableName: translate('digitalStore.availableName', options),
    advantages: {
      title: translate('digitalStore.advantages.title', options),
      firstItemTitle: translate('digitalStore.advantages.firstItemTitle', options),
      firstItemDescription: translate('digitalStore.advantages.firstItemDescription', options),
      secondItemTitle: translate('digitalStore.advantages.secondItemTitle', options),
      secondItemDescription: translate('digitalStore.advantages.secondItemDescription', options),
      thirdItemTitle: translate('digitalStore.advantages.thirdItemTitle', options),
      thirdItemDescription: translate('digitalStore.advantages.thirdItemDescription', options),
    },
    terms: {
      agreementLabel: translate('digitalStore.terms.agreementLabel', options),
      agreementLabelLink: translate('digitalStore.terms.agreementLabelLink', options),
      termsInfo: translate('digitalStore.terms.termsInfo', options),
      modalTitle: translate('digitalStore.terms.modalTitle', options),
      modalAccept: translate('digitalStore.terms.modalAccept', options),
      modalClose: translate('digitalStore.terms.modalClose', options),
    },
    previousButtonLabel: translate('previousButtonLabel', options),
    skipButtonLabel: translate('digitalStore.skipButtonLabel', options),
  }
}

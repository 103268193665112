import styled from 'styled-components'
import { FormControl, Button } from '@/main/components'

export const Wrapper = styled.div`
  display: grid;
  grid-row-gap: ${(props) => props.theme.spacing * 2}px;
`

export const Container = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: ${(props) => props.theme.spacing}px;
`

export const StyledFormControl = styled(FormControl)`
  margin-right: ${(props) => props.theme.spacing}px;
`

export const StyledButton = styled(Button)`
  &.verify-button {
    margin-left: ${({ theme }) => theme.spacing}px;
  }
`

import styled from 'styled-components'

export const ContactInfoContainer = styled.div`
  margin: 28px 0 60px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ContactInfoData = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 32px;
  min-height: 80px;
  justify-content: space-between;
`

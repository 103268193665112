export enum Document {
  Cpf = 1,
  Rg = 2,
  Rne = 3,
  PassportBrazil = 4,
  CitizenCard = 9,
  ForeignCard = 10,
  NationalRegistry = 19,
  Rut = 20,
  Ruc = 27,
  PassportPeru = 32,
  ForeignRegistry = 33,
  MyKAD = 34,
  PassportMalaysia = 35,
  DniArgentina = 36,
  Curp = 38,
}

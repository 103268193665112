import React, { useContext, useState, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  parse, format, isValid, differenceInYears,
} from 'date-fns'
import { AuthenticatedUserContext, IdentityContext, FormContext } from '@/main/contexts'
import { Container } from '@/main/components/pages-structures'
import { SelectOption } from '@/main/components/select/select'

import {
  StyledFormContainer,
  StyledFormItem,
} from '@/main/pages/documentation/documentation-page.styles'
import { AcceptTermsComponent } from '@/main/components/accept-terms'
import { CountryId } from '@/domain/models/country'
import { CountryCodes, DefaultNationalityByCountryId } from '@/main/factories/presenters/country-codes'
import { getCountryNames } from '@/main/factories/presenters/country-labels'
import { genderCodes } from '@/main/factories/presenters/gender-codes'
import { getGenderNames } from '@/main/factories/presenters/gender-labels'
import {
  makeRemoteAcceptAgreement,
} from '@/main/factories/remote/agreement'
import { ControlledSelect } from '@/main/components/select/'
import { CardContent, CardHeader, ControlledTextField } from '@/main/components'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { DocumentationMessages } from '@/domain/models/messages/messages'
import { DocumentationConfigs } from '@/domain/models/tenant/tenant-configs'
import { FormControl } from '@naturacosmeticos/natds-web'

type FormInputs = {
  birthday: string
  gender: SelectOption
  nationalityId: SelectOption
  acceptedAgreements: boolean
}

type FormDefaultValuesParams = {
  birthday?: string,
  gender?: number,
  nationalityId?: number,
  nationalityOptions?: SelectOption[],
  genderOptions?: SelectOption[],
}

const formatDate = (isoDate: string) => {
  if (isoDate) {
    try {
      const date = isoDate.substring(0, 10)
      const parsedDate = parse(date, 'yyyy-MM-dd', new Date())

      return format(parsedDate, 'dd/MM/yyyy')
    } catch (err) {
      return isoDate
    }
  }

  return isoDate
}

const formDefaultValues = ({
  birthday, gender, nationalityId, nationalityOptions, genderOptions,
}: FormDefaultValuesParams): FormInputs => ({
  birthday: formatDate(birthday),
  gender: genderOptions.find((option) => option.value === gender),
  nationalityId: nationalityOptions.find((option) => option.value === nationalityId),
  acceptedAgreements: false,
})

const getNationalityOptions = (countryId: CountryId) => {
  const defaultNationalityOption = getDefaultNationalityOption(countryId, DefaultNationalityByCountryId[countryId])
  const nationalitiesOptions = getCountryOptionsWithoutDefaultOption(countryId, DefaultNationalityByCountryId[countryId])

  return [defaultNationalityOption, ...nationalitiesOptions]

  function getDefaultNationalityOption(countryId: CountryId, key: string) {
    return {
      value: CountryCodes[key],
      label: getCountryNames(countryId, key),
    }
  }

  function getCountryOptionsWithoutDefaultOption(countryId: CountryId, key: string) {
    const countryCodes = { ...CountryCodes }
    delete countryCodes[key]
    const countryKeys = Object.keys(countryCodes)

    return countryKeys.map((key) => ({
      value: CountryCodes[key],
      label: getCountryNames(countryId, key),
    }))
  }
}

const getGenderOptions = (countryId: CountryId) => (Object.keys(genderCodes)).map((key) => ({
  value: genderCodes[key],
  label: getGenderNames(countryId, key),
}))

export const DocumentationPage: React.FC = () => {
  const {
    personId,
    countryId,
    tenantId,
  } = useContext(IdentityContext)
  const messages = usePageMessages(Page.Documentation).messages as DocumentationMessages
  const configs = useTenantConfigs(tenantId, Page.Documentation) as DocumentationConfigs
  const {
    person,
    onSubmit,
    goToNextPage = () => {},
    goToPreviousPage = () => {},
  } = useContext(FormContext)
  const {
    authenticated,
  } = useContext(AuthenticatedUserContext)
  const nationalityOptions = useMemo(() => getNationalityOptions(countryId), [countryId])
  const genderOptions = useMemo(() => getGenderOptions(countryId), [countryId])
  const {
    formState, control, getValues,
  } = useForm<FormInputs>({
    mode: 'onTouched',
    defaultValues: formDefaultValues({
      birthday: person?.birthday,
      nationalityId: person?.nationalityId,
      gender: person?.gender,
      genderOptions,
      nationalityOptions,
    }),
  })

  const [isLoading, setIsLoading] = useState(false)
  const [agreementsId, setAgreementsId] = useState<string[]>([])

  const canGoToNextPage = formState.isValid

  const handleNextButtonClick = () => {
    setIsLoading(true)
    const values = getValues()
    const parsedValues = parseFormValues(values)

    acceptAgreements({ agreementsId })
      .finally(() => {
        onSubmit(parsedValues, () => {
          setIsLoading(false)
          goToNextPage()
        })
      })

    function parseFormValues(values: FormInputs) {
      return {
        birthday: values.birthday,
        gender: values.gender.value,
        nationalityId: values.nationalityId.value,
      }
    }

    async function acceptAgreements({ agreementsId }: { agreementsId: string[] }) {
      const remoteAcceptAgreement = makeRemoteAcceptAgreement()
      return await Promise.all(agreementsId.map(async (agreementId) => await remoteAcceptAgreement.accept({
        personId,
        agreementId,
      })))
    }
  }
  const shouldAcceptTerms = !authenticated || configs.shouldAcceptTermsWhenAuthenticated

  return (
    <Container
      previousButtonLabel={messages.previousButtonLabel}
      onPreviousButtonClick={goToPreviousPage}
      nextButtonLabel={messages.nextButtonLabel}
      onNextButtonClick={handleNextButtonClick}
      disableNextButton={!canGoToNextPage}
      isLoading={isLoading}
    >
      <CardHeader
        title={shouldAcceptTerms ? messages.titleForAcceptTerm : messages.titleForGeneralInformation}
        subtitle={shouldAcceptTerms ? messages.instructionsForAcceptTerm : messages.instructionsForGeneralInformation}
      />
      <CardContent>
        <StyledFormContainer>
          <StyledFormItem flex gridArea="birthday">
            <FormControl>
              <ControlledTextField
                id="birthday"
                label={messages.birthdayLabel}
                mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                control={control}
                placeholder={messages.birthdayPlaceholder}
                rules={{
                  required: messages.requiredError,
                  validate: {
                    invalidDate: (birthday) => isValid(parse(birthday, 'dd/MM/yyyy', new Date())) || messages.invalidDateError,
                    minAge: (birthday) => differenceInYears(new Date(), parse(birthday, 'dd/MM/yyyy', new Date())) >= 18 || messages.minAgeError,
                    maxAge: (birthday) => differenceInYears(new Date(), parse(birthday, 'dd/MM/yyyy', new Date())) <= 100 || messages.maxAgeError,
                  },
                }}
              />
            </FormControl>
          </StyledFormItem>
          <StyledFormItem gridArea="gender">
            <FormControl>
              <ControlledSelect
                aria-label={messages.genderLabel}
                id="gender"
                label={messages.genderLabel}
                placeholder={messages.genderPlaceholder}
                options={genderOptions}
                rules={{
                  required: messages.requiredError,
                }}
                control={control}
              />
            </FormControl>
          </StyledFormItem>
          <StyledFormItem gridArea="nationality">
            <ControlledSelect
              aria-label={messages.nationalityLabel}
              id="nationalityId"
              label={messages.nationalityLabel}
              placeholder={messages.nationalityPlaceholder}
              options={nationalityOptions}
              rules={{
                required: messages.requiredError,
              }}
              control={control}
            />
          </StyledFormItem>
          {
            shouldAcceptTerms && (
              <StyledFormItem gridArea="terms">
                <FormControl>
                  <Controller
                    name="acceptedAgreements"
                    control={control}
                    rules={{ required: messages.requiredError }}
                    render={({
                      onChange, onBlur, value, ref,
                    }) => (
                      <AcceptTermsComponent
                        countryId={countryId}
                        products={configs.agreementProducts}
                        personId={personId}
                        setAgreementId={setAgreementsId}
                        setUserNeedsToAcceptTerms={() => true}
                        agreementLabel={messages.checkboxLabel}
                        agreementLabelLink={messages.checkboxLabelLink}
                        modalTitle={messages.modal.title}
                        modalAccept={messages.modal.titleAcceptLabel}
                        modalClose={messages.modal.titleCloseLabel}
                        CheckboxProps={{
                          onChange: (e) => {
                            onBlur()
                            onChange(e)
                          },
                          onBlur,
                          value,
                          ref,
                        }}
                      />
                    )}
                  />
                </FormControl>
              </StyledFormItem>
            )
          }
        </StyledFormContainer>
      </CardContent>
    </Container>
  )
}

import React, { useState } from 'react'
import { ControlledSelect, ControlledTextField } from '@/main/components'
import { RequiredErrorMessage } from '@/domain/models/messages/messages.d'

import { useFormContext } from 'react-hook-form'
import { getPhoneTypesProps, PhoneTypeSelectOption } from '@/main/pages/personal-data/fields/phone/phone-type/phone-type-props'
import { Mask } from '@naturacosmeticos/natds-web/dist/Components/Input/Input.props'
import { PhoneType } from '@/domain/models/person'
import { usePhoneFieldsStyles } from './phone-fields.styles'

export type PhoneNumberMessages = {
  placeholder: string,
  errorMessage: {
    firstDigit: string,
    length: string,
  },
}
export type PhoneTypeOptionMessages = {
  label: string,
  phoneNumber: PhoneNumberMessages,
}
export type PhoneTypeOptionsMessages = {
  cellular: PhoneTypeOptionMessages,
  line: PhoneTypeOptionMessages,
}
export type PhoneFieldsMessages = {
  label: string,
  options: PhoneTypeOptionsMessages,
} & RequiredErrorMessage

export type PhoneTypeOptionProps = {
  phoneType: PhoneType,
  mask: Mask,
  validations: {
    firstDigit?: (value: string) => boolean,
    length?: (value: string) => boolean,
  }
}
export type PhoneFieldsFieldProps = {
  phoneTypeId?: string;
  phoneNumberId?: string;
  messages: PhoneFieldsMessages;
  options: PhoneTypeOptionProps[];
  shouldShowPhoneSelect?: boolean
}

export const PhoneFields: React.FC<PhoneFieldsFieldProps> = ({
  messages, options, phoneTypeId = 'phoneType', phoneNumberId = 'phoneNumber', shouldShowPhoneSelect = true,
 }) => {
  const {
    phoneTypeOptions,
    phoneTypeDefaultValue,
    phoneTypeDisabled,
  } = getPhoneTypesProps(messages.options, options)
  const {
    control, errors, setValue, clearErrors,
  } = useFormContext()
  const phoneFieldsClasses = usePhoneFieldsStyles()

  const [selectedPhoneType, setSelectedPhoneType] = useState(phoneTypeDefaultValue)

  const handlePhoneTypeOnChange = (selectedOption: PhoneTypeSelectOption) => {
    if (selectedOption.value !== selectedPhoneType.value) {
      setSelectedPhoneType(selectedOption)
      setValue('phoneNumber', '')
      clearErrors('phoneNumber')
    }
  }

  return (
    <div className={phoneFieldsClasses.formRow} data-testid={`${phoneTypeId}-${phoneNumberId}`}>
      {shouldShowPhoneSelect && (
        <div className={phoneFieldsClasses.phoneTypeInput} data-testid="phoneType-select">
          <ControlledSelect
            id={phoneTypeId}
            label={messages.label}
            aria-label={messages.label}
            options={phoneTypeOptions}
            control={control}
            defaultValue={phoneTypeDefaultValue}
            disabled={phoneTypeDisabled}
            onChange={handlePhoneTypeOnChange}
          />
        </div>
      )}

      <div className={shouldShowPhoneSelect ? phoneFieldsClasses.phoneNumberInputWithSelect : phoneFieldsClasses.phoneNumberInput}>
        <ControlledTextField
          id={phoneNumberId}
          label={shouldShowPhoneSelect ? ' ' : messages.label}
          aria-label={shouldShowPhoneSelect ? ' ' : messages.label}
          control={control}
          placeholder={selectedPhoneType.phoneNumber.placeholder}
          rules={
            {
              required: messages.requiredErrorMessage as string,
              validate: selectedPhoneType.phoneNumber.validations,
            }
          }
          errorMessage={{
            firstDigit: selectedPhoneType.phoneNumber.errorMessage.firstDigit,
            length: selectedPhoneType.phoneNumber.errorMessage.length,
          }}
          mask={selectedPhoneType.phoneNumber.mask}
          errors={errors}
        />
      </div>
    </div>
  )
}

import { GetTenantVendor } from '@/domain/use-cases/tenant/get-tenant-vendor'
import { getMercadoPagoDetails } from '@/data/use-cases/vendor/vendor-details/detail.MERCADO_PAGO'
import { VendorDetails, VendorLinkingSteps } from '@/domain/models/vendor/vendor-details/details.d'
import { TenantId } from '@/domain/models/tenant/tenant-id'
import { VendorId } from '@/domain/models/vendor/vendor-id'

export const vendorByTenantId: {[ key in TenantId ]: VendorId} = {
    [TenantId.NaturaARG]: null,
    [TenantId.NaturaCOL]: null,
    [TenantId.NaturaPER]: VendorId.MERCADO_PAGO,
    [TenantId.NaturaCHL]: null,
    [TenantId.NaturaMYS]: null,
    [TenantId.AvonMEX]: null,
}

const vendorIdMapper: {[ key in VendorId ]: (linkingSteps: VendorLinkingSteps) => VendorDetails} = {
    [VendorId.MERCADO_PAGO]: getMercadoPagoDetails,
}

export const getTenantVendor: GetTenantVendor = (tenantId: TenantId, linkingSteps: VendorLinkingSteps) => {
    const vendorId = vendorByTenantId[tenantId]
    if (vendorId && vendorId !== null) {
        const getVendor = vendorIdMapper[vendorId]
        return getVendor(linkingSteps)
    }
    throw new Error(`TenantId ${tenantId} does not have vendor.`)
}

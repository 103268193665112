import React, { useContext, useEffect } from 'react'
import { ProgressIndicator } from '@naturacosmeticos/natds-web/dist/Components/ProgressIndicator'
import i18n from 'i18next'

import { useGetDigitalStoreStatusesSWR } from '@/main/hooks/swrServices'
import { IdentityContext } from '@/main/contexts/identity-context'
import { makeConvertCountryIdFromAlpha2ToAlpha3Presenter } from '@/main/factories/presenters'
import { CountryIdAlpha2 } from '@/domain/models/country/country-id-alpha-2'
import { makeGetLanguageKeyByCountryPresenter } from '@/main/factories/presenters/make-get-language-key-by-country-presenter'
import { DigitalStoreCurrentStatus } from '@/domain/models/digital-store'

import { Container } from './digital-store-status-page.styles'

import { getStoreStatusPageByStatus } from './strategies/get-store-status-page-by-status/get-store-status-page-by-status'

const convertCountryIdFromAlpha2ToAlpha3Presenter = makeConvertCountryIdFromAlpha2ToAlpha3Presenter()
const getLanguageKeyByCountryPresenter = makeGetLanguageKeyByCountryPresenter()

const countriesWithPaymmentAccountStatusPage = [
  CountryIdAlpha2.Peru,
]

const isCountryWithCustomAgreementsFlow = (countryId): boolean => countriesWithPaymmentAccountStatusPage.includes(countryId)

export const DigitalStoreStatusPage = () => {
  const { countryId, personId } = useContext(IdentityContext)

  const country3AlphaCode = convertCountryIdFromAlpha2ToAlpha3Presenter.handle({ countryId: countryId as unknown as CountryIdAlpha2 })

  const { digitalStoreStatus, isLoading, reFetch } = useGetDigitalStoreStatusesSWR({
    countryId: country3AlphaCode,
    personId,
    lang: getLanguageKeyByCountryPresenter.handle({ countryId: country3AlphaCode }),
  })

  const hasDigitalStoreApproved = digitalStoreStatus?.type === 'approved'
  const countryIdAsString = countryId as string
  const hasCustomFlowAgreementsEnabled = isCountryWithCustomAgreementsFlow(countryIdAsString)

  const props = {
    hasCustomFlowAgreementsEnabled,
    hasDigitalStoreApproved,
    isLoading,
    digitalStoreStatus,
    onSave: reFetch,
  }
  const digitalStoreStatusValue = digitalStoreStatus?.status || DigitalStoreCurrentStatus.NOT_STARTED
  const PageComponent = getStoreStatusPageByStatus({ digitalStoreStatus: digitalStoreStatusValue, countryId: country3AlphaCode })

  useEffect(() => {
    i18n.changeLanguage(country3AlphaCode)
  }, [])

  if (isLoading) {
    return (
      <Container>
        <ProgressIndicator />
      </Container>
    )
  }
  return <PageComponent {...props} />
}

import { HttpStatusCode, HttpPostClient } from '@/data/protocols/http'
import {
  StartRenameDigitalStoreWorkflow,
  StartRenameDigitalStoreWorkflowParams,
} from '@/domain/use-cases/digital-store/start-rename-digital-store-workflow'
import { UnexpectedError } from '@/domain/errors'

import { BadRequestError, ConflictError } from '@/data/protocols/errors'

export type StartRenameDigitalStoreWorkflowHttpRequestHeaders = {
  personId: string
  'x-api-key': string
}

export type StartRenameDigitalStoreWorkflowHttpRequestBody = {
  personId: string,
}

export class RemoteStartRenameDigitalStoreWorkflow implements StartRenameDigitalStoreWorkflow {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient<
      StartRenameDigitalStoreWorkflowHttpRequestHeaders,
      StartRenameDigitalStoreWorkflowHttpRequestBody
    >,
    private readonly xApiKey: string,
  ) {}

  post = async (params: StartRenameDigitalStoreWorkflowParams): Promise<unknown> => {
    const headers: StartRenameDigitalStoreWorkflowHttpRequestHeaders = {
      'x-api-key': this.xApiKey,
      personId: params.personId,
    }

    const body: StartRenameDigitalStoreWorkflowHttpRequestBody = {
      personId: params.personId,
    }

    const httpResponse = await this.httpPostClient.post({
      url: this.url,
      headers,
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body.saved
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      case HttpStatusCode.conflict:
        throw new ConflictError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}

import { TFunction } from 'i18next'
import {
  DigitalSignatureMessages,
} from '@/domain/models/messages/messages'

export function getDigitalSignatureMessages(translate: TFunction): DigitalSignatureMessages {
  return {
    title: translate('digitalSignaturePage.title'),
    subtitle: translate('digitalSignaturePage.subtitle'),
    signatureButton: translate('digitalSignaturePage.signatureButton'),
    redoSignatureButton: translate('digitalSignaturePage.redoSignatureButton'),
    previewOfContractButton: translate('digitalSignaturePage.previewOfContractButton'),
    previousButtonLabel: translate('previousButtonLabel'),
    nextButtonLabel: translate('nextButtonLabel'),
    signaturePadBoard: {
      title: translate('digitalSignaturePage.signaturePadBoard.title'),
      clearButton: translate('digitalSignaturePage.signaturePadBoard.clearButton'),
      previousButton: translate('digitalSignaturePage.signaturePadBoard.previousButton'),
      nextButton: translate('digitalSignaturePage.signaturePadBoard.nextButton'),
    },
  }
}

import React, {
 useContext, useEffect, useState,
} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Container } from '@/main/components/pages-structures'
import { CardHeader } from '@naturacosmeticos/natds-web/dist/Components/CardHeader'
import { Divider } from '@naturacosmeticos/natds-web/dist/Components/Divider'
import { Spacing } from '@naturacosmeticos/natds-web/dist/Components/Spacing'
import {
  ControlledTextField, GeoCommercialConfig,
  GeoCommercialStructure,
  SelectOption,
} from '@/main/components'
import { Checkbox } from '@/main/components/checkbox'
import { useAddressPageStyles } from '@/main/pages/address/address-page.styles'
import { FormContext, FormContextProps } from '@/main/contexts'
import { adaptDefaultValues } from '@/main/pages/address/adapters/input-adapter'
import { AddressFormValues } from '@/main/pages/address/models'
import { CountryId } from '@/domain/models/country'
import { AddressPageProps } from '@/main/pages/address/address-page-props'
import {
  adaptFormValuesWithAnotherDeliveryAddress,
  adaptFormValuesWithDeliveryAddressCopied,
} from '@/main/pages/address/adapters/output-adapter'

const geoCommercialLevelsAdapter = {
  level1: 'department',
  level2: 'city',
}

type ChileGeoCommercialLevelsIds = {
  department?: SelectOption,
  city?: SelectOption,
}

export const ChileAddressPage: React.FC<AddressPageProps> = ({ messages }) => {
  const {
   person, onSubmit, goToPreviousPage = () => {}, goToNextPage = () => {},
  } = useContext<FormContextProps>(FormContext)

  const defaultValues = adaptDefaultValues<ChileGeoCommercialLevelsIds>(person, geoCommercialLevelsAdapter)
  const formMethods = useForm<AddressFormValues & ChileGeoCommercialLevelsIds>({
    mode: 'onTouched',
    defaultValues,
  })
  const {
    control, formState: { isValid, isSubmitting }, getValues,
  } = formMethods
  const [copyAddress, setCopyAddress] = useState<boolean>(true)

  useEffect(() => {
    if (!defaultValues.department) {
      return
    }
    setCopyAddress(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formRules = { required: messages.addressRequiredError }

  const addressPageClasses = useAddressPageStyles()

  const onNextButtonClick = () => {
    const formValues = getValues()
    const parsedValue = copyAddress ? adaptFormValuesWithDeliveryAddressCopied(formValues)
       : adaptFormValuesWithAnotherDeliveryAddress(formValues, geoCommercialLevelsAdapter)
    onSubmit(parsedValue, goToNextPage)
  }

  const geoCommercialConfig: GeoCommercialConfig = {
    requiredErrorMessage: messages.addressRequiredError,
    level1: {
      id: 'department',
      label: messages.geographicalLevels.level1,
      placeholder: messages.geographicalLevels.placeholders.level1,
    },
    level2: {
      id: 'city',
      label: messages.geographicalLevels.level2,
      placeholder: messages.geographicalLevels.placeholders.level2,
    },
  }
  return (
    <Container
      previousButtonLabel={messages.previousButtonLabel}
      onPreviousButtonClick={goToPreviousPage}
      nextButtonLabel={messages.nextButtonLabel}
      onNextButtonClick={onNextButtonClick}
      disableNextButton={!isValid}
      isLoading={isSubmitting}
    >
      <CardHeader
        classes={{
          root: addressPageClasses.cardHeader,
          title: addressPageClasses.title,
          subheader: addressPageClasses.subtitle,
        }}
        title={messages.title}
        subheader={messages.subtitle}
        subheaderTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
      />
      <FormProvider {...formMethods}>
        <div className={addressPageClasses.formRow}>
          <div className={`${addressPageClasses.inputFullWidth} ${addressPageClasses.inputPadRight}`}>
            <ControlledTextField
              id="street"
              label={messages.mainStreet}
              control={control}
              rules={formRules}
              placeholder={messages.placeholders.mainStreet}
              required
              showAsterisk={false}
            />
          </div>
          <div className={`${addressPageClasses.inputFullWidth} ${addressPageClasses.inputPadLeft}`}>
            <ControlledTextField
              id="number"
              label={messages.number}
              placeholder={messages.placeholders.number}
              control={control}
              rules={formRules}
              required
              showAsterisk={false}
            />
          </div>
        </div>
        <ControlledTextField
          id="complement"
          label={messages.complementaryData}
          control={control}
          placeholder={messages.placeholders.complementaryData}
        />
        <ControlledTextField
          id="references"
          label={messages.references}
          control={control}
          rules={formRules}
          placeholder={messages.placeholders.references}
          required
          showAsterisk={false}
        />
        <div className={addressPageClasses.copyAddressCheckbox}>
          <Checkbox
            label={messages.confirmDeliveryAddressAsResidenceAddress}
            checked={copyAddress}
            onChange={(e) => setCopyAddress(e.target.checked)}
          />
        </div>
        {!copyAddress && (
          <>
            <Spacing marginY="semi">
              <Divider />
            </Spacing>
            <CardHeader
              classes={{
                root: addressPageClasses.cardHeader,
                title: addressPageClasses.title,
              }}
              title={messages.delivery.title}
            />
            <GeoCommercialStructure customConfig={geoCommercialConfig} countryId={CountryId.Chile} />
            <div className={addressPageClasses.formRow}>
              <div className={`${addressPageClasses.inputFullWidth} ${addressPageClasses.inputPadRight}`}>
                <ControlledTextField
                  id="deliveryStreet"
                  label={messages.mainStreet}
                  control={control}
                  rules={formRules}
                  placeholder={messages.placeholders.mainStreet}
                  required
                  showAsterisk={false}
                />
              </div>
              <div className={`${addressPageClasses.inputFullWidth} ${addressPageClasses.inputPadLeft}`}>
                <ControlledTextField
                  id="deliveryNumber"
                  label={messages.number}
                  placeholder={messages.placeholders.number}
                  control={control}
                  rules={formRules}
                  required
                  showAsterisk={false}
                />
              </div>
            </div>
            <ControlledTextField
              id="deliveryComplement"
              label={messages.complementaryData}
              control={control}
              placeholder={messages.placeholders.complementaryData}
            />
            <ControlledTextField
              id="deliveryReferences"
              label={messages.references}
              control={control}
              rules={formRules}
              placeholder={messages.placeholders.references}
              required
              showAsterisk={false}
            />
          </>
        )}
      </FormProvider>
    </Container>
  )
}

import { RemoteStartRenameDigitalStoreWorkflow } from '@/data/use-cases/digital-store/remote-start-rename-digital-store-workflow'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { StartRenameDigitalStoreWorkflow } from '@/domain/use-cases/digital-store'

export const makeStartRenameDigitalStoreWorkflow = ():StartRenameDigitalStoreWorkflow => {
  const apiUrl = '/create-store-reason'
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  const remoteSaveDigitalStore = new RemoteStartRenameDigitalStoreWorkflow(
    apiUrl,
    httpGetClient,
    xApiKey,
  )
  return remoteSaveDigitalStore
}

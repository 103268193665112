import React from 'react'
import styled from 'styled-components'
import { Checkbox as DSCheckbox, FormControlLabel } from '@naturacosmeticos/natds-web'

type checkboxProps = {
  label: React.ReactNode,
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  value?: boolean,
  inputRef?: React.Ref<any>,
  onBlur?: () => void,
  checked?: boolean,
  id?: string,
}

export const StyledFormControlLabel = styled(FormControlLabel)`
  &.checkbox-root {
    margin: 0;
    align-items: flex-start;
  }

  .MuiCheckbox-root {
    margin-right: ${({ theme }) => `${theme.sizes.tiny}px`};
    padding: 0;

    .MuiSvgIcon-root {
      width: 2rem;
      height: 2rem;
    }
  }
`

export const Checkbox: React.FC<checkboxProps> = ({
  label,
  onChange,
  value,
  inputRef,
  onBlur,
  checked,
}) => (
  <StyledFormControlLabel
    className="checkbox-root"
    control={(
      <DSCheckbox
        color="secondary"
        checked={checked}
        onBlur={onBlur}
      />
    )}
    label={label}
    inputRef={inputRef}
    value={value}
    onChange={onChange}
  />
)

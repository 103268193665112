import { RemoteCompleteRegistration } from '@/data/use-cases/complete-registration'
import { AxiosHttpClient } from '@/infra/http/axios-http-client'

export const makeCompleteRegistration = () => {
  const apiUrl = '/people/complete-registration'
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  const remoteCompleteRegistration = new RemoteCompleteRegistration(
    apiUrl,
    httpGetClient,
    xApiKey,
  )
  return remoteCompleteRegistration
}

import React, { useContext, useEffect, useState } from 'react'
import { CircularProgress } from '@naturacosmeticos/natds-web'
import i18n from 'i18next'
import { useGetDigitalStoreStatusesSWR } from '@/main/hooks/swrServices'
import { DigitalStoreCurrentStatus } from '@/domain/models/digital-store'
import { IdentityContext } from '@/main/contexts'
import { DigitalStoreRenameForm } from './digital-store-rename-form'
import { DigitalStoreBrasilSuccessPageWrapper } from '../components/digital-store-brasil-success-page/digital-store-brasil-success-page-wrapper'
import { DigitalStoreRenameError } from './digital-store-rename-error'

export const DigitalStoreRenamePage = () => {
  const {
    personId,
  } = useContext(IdentityContext)

  const decodedPersonId = atob(personId)
  const [renamed, setRenamed] = useState(false)
  const [savedName, setSavedName] = useState('')

  const { digitalStoreStatus, isLoading } = useGetDigitalStoreStatusesSWR({
    countryId: 'BRA',
    personId: decodedPersonId,
    lang: 'pt-BR',
  })

  useEffect(() => {
    i18n.changeLanguage('BRA')
  }, [])

  const onSave = (newSavedName) => {
    setRenamed(true)
    setSavedName(newSavedName)
  }

  if (renamed) {
    return <DigitalStoreBrasilSuccessPageWrapper savedDigitalStoreName={savedName} personId={decodedPersonId} />
  }

  if (isLoading) {
    return (
      <CircularProgress />
    )
  }

  if (digitalStoreStatus.status === DigitalStoreCurrentStatus.NOT_STARTED) {
    return <DigitalStoreRenameForm personId={decodedPersonId} onSave={onSave} />
  }

  return <DigitalStoreRenameError />
}

import { action } from 'typesafe-actions'
import { MessagesTypes, messagesResponseType } from './protocols'

export const getMessagesByCountryRequest = (country: string) => action(MessagesTypes.GET_MESSAGES_BY_COUNTRY_REQUEST, {
  country,
})

export const getMessagesByCountryResponse = (messages: messagesResponseType) => action(MessagesTypes.GET_MESSAGES_BY_COUNTRY_RESPONSE, {
  messages,
})

// Country IDs in ISO 3166-1 alpha-3 standard
export enum CountryId {
  Brasil = 'BRA',
  Peru = 'PER',
  Malaysia = 'MYS',
  Chile = 'CHL',
  Colombia = 'COL',
  Argentina = 'ARG',
  Mexico = 'MEX'
}

export type countryIdsAlpha3Key = keyof typeof CountryId

export const COUNTRIES_IDS_KEYS = new Map<CountryId, countryIdsAlpha3Key>(
  Object.entries(CountryId).map(([key, value]: [countryIdsAlpha3Key, CountryId]) => [value, key]),
)

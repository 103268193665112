export enum DigitalStoreTypes {
  CHECK_DIGITAL_STORE_AVAILABILITY_REQUEST = '@digital-store/CHECK_DIGITAL_STORE_AVAILABILITY_REQUEST',
  CHECK_DIGITAL_STORE_AVAILABILITY_SUCCESS = '@digital-store/CHECK_DIGITAL_STORE_AVAILABILITY_SUCCESS',
  CHECK_DIGITAL_STORE_AVAILABILITY_ERROR = '@digital-store/CHECK_DIGITAL_STORE_AVAILABILITY_ERROR',
  SAVE_DIGITAL_STORE_REQUEST = '@digital-store/SAVE_DIGITAL_STORE_REQUEST',
  SAVE_DIGITAL_STORE_SUCCESS = '@digital-store/SAVE_DIGITAL_STORE_SUCCESS',
  SAVE_DIGITAL_STORE_ERROR = '@digital-store/SAVE_DIGITAL_STORE_ERROR',
  GET_DIGITAL_STORE_INFO_REQUEST = '@digital-store/GET_DIGITAL_STORE_INFO_REQUEST',
  GET_DIGITAL_STORE_INFO_SUCCESS = '@digital-store/GET_DIGITAL_STORE_INFO_SUCCESS',
  SET_DIGITAL_STORE_HELPER_TEXT = '@digital-store/SET_DIGITAL_STORE_HELPER_TEXT',
  RESET_DIGITAL_STORE_ON_CHANGE_REQUEST = '@digital-store/RESET_DIGITAL_STORE_ON_CHANGE_REQUEST',
  RESET_DIGITAL_STORE_ON_CHANGE_SUCCESS = '@digital-store/RESET_DIGITAL_STORE_ON_CHANGE_SUCCESS',
}

export interface DigitalStoreState {
  readonly error: boolean
  readonly helperTextKey: string
  readonly savedDigitalStoreName: string
  readonly isAvailable: boolean
  readonly isLoading: boolean
  readonly saved: boolean
}

import { action } from 'typesafe-actions'

import { CountryId } from '@/domain/models/country'
import { FUNCTION_IDS, ROLES, BUSINESS_MODELS } from '@/domain/models/person'
import { DigitalStoreTypes } from './protocols'

export const checkDigitalStoreAvailabilityRequest = (
  digitalStoreName: string,
  personId: string,
  country: string,
) => action(DigitalStoreTypes.CHECK_DIGITAL_STORE_AVAILABILITY_REQUEST, {
  digitalStoreName,
  personId,
  country,
})

export const checkDigitalStoreAvailabilitySuccess = (
  isAvailable: boolean,
  helperTextKey: string,
  error: boolean,
) => action(DigitalStoreTypes.CHECK_DIGITAL_STORE_AVAILABILITY_SUCCESS, {
  isAvailable,
  helperTextKey,
  error,
})

export const checkDigitalStoreAvailabilityError = (helperTextKey: string) => action(DigitalStoreTypes.CHECK_DIGITAL_STORE_AVAILABILITY_ERROR, {
  helperTextKey,
})

export const saveDigitalStoreRequest = (
  digitalStoreName: string,
  personId: string,
  country: CountryId,
  functionId: FUNCTION_IDS,
  role: ROLES,
  businessModel: BUSINESS_MODELS,
  sourceSystem: string,
  convergence = false,
  agreementId: string,
  accepted = false,
) => action(DigitalStoreTypes.SAVE_DIGITAL_STORE_REQUEST, {
  digitalStoreName,
  personId,
  country,
  functionId,
  role,
  businessModel,
  sourceSystem,
  convergence,
  agreementId,
  accepted,
})

export const saveDigitalStoreSuccess = (
  saved: boolean,
  helperTextKey: string,
  error: boolean,
) => action(DigitalStoreTypes.SAVE_DIGITAL_STORE_SUCCESS, {
  saved,
  helperTextKey,
  error,
})

export const saveDigitalStoreError = (helperTextKey: string) => action(DigitalStoreTypes.SAVE_DIGITAL_STORE_ERROR, {
  helperTextKey,
})

export const getDigitalStoreInfoRequest = (personId: string) => action(DigitalStoreTypes.GET_DIGITAL_STORE_INFO_REQUEST, {
  personId,
})

export const getDigitalStoreInfoSuccess = (digitalStoreName: string) => action(DigitalStoreTypes.GET_DIGITAL_STORE_INFO_SUCCESS, {
  digitalStoreName,
})

export const setDigitalStorehelperTextKey = (helperTextKey: string) => action(DigitalStoreTypes.SET_DIGITAL_STORE_HELPER_TEXT, {
  helperTextKey,
})

export const resetDigitalStoreStateOnChangeRequest = () => action(DigitalStoreTypes.RESET_DIGITAL_STORE_ON_CHANGE_REQUEST)

export const resetDigitalStoreStateOnChangeSuccess = () => action(DigitalStoreTypes.RESET_DIGITAL_STORE_ON_CHANGE_SUCCESS)

import {
  HelpInformation,
  Typography,
  StatusIcon,
} from '@/main/components'
import {
  makeStartRenameDigitalStoreWorkflow,
} from '@/main/factories/remote/digital-store/make-remote-start-rename-digital-store'
import React, { useEffect } from 'react'
import {
  Container,
  StyledCard,
  StyledCardTitle,
} from './digital-store-brasil-success-page.styles'

export type DigitalStoreBrasilSuccessMessages = {
  convergenceTitle: string,
  convergenceDescription: string,
  helpInformationTitle: string,
  helpInformationText: string,
  shareStoreDescription: string,
  addressLabel: string,
  baseUrl: string
}

type DigitalStoreBrasilSuccessMessagesProps = {
  messages: DigitalStoreBrasilSuccessMessages,
  savedDigitalStoreName: string,
  personId: string,
}

const httpClient = makeStartRenameDigitalStoreWorkflow()

export const DigitalStoreBrasilSuccessPage: React.FC<DigitalStoreBrasilSuccessMessagesProps> = ({ messages, savedDigitalStoreName, personId }) => {
  const personalDigitalStoreAdress = `${messages.baseUrl}${savedDigitalStoreName}`

  useEffect(() => {
    httpClient.post({ personId })
    .catch(() => {})
  }, [])

  return (
    <Container>
      <StyledCard raised>
        <StatusIcon name="success" />
        <StyledCardTitle title={messages.convergenceTitle} status="success" />
        <Typography className="gutter">{messages.convergenceDescription}</Typography>
        <Typography className="gutter">
          <Typography variant="subtitle1" className="styled-text-paragraph">
            {messages.shareStoreDescription}
          </Typography>
          <Typography>
            {messages.addressLabel}
          </Typography>
          <Typography />
          <Typography variant="subtitle1">
            {personalDigitalStoreAdress}
          </Typography>
        </Typography>
        <HelpInformation
          title={messages.helpInformationTitle}
          content={messages.helpInformationText}
          hasMarginTop
        />
      </StyledCard>
    </Container>
  )
}

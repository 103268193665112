import React from 'react'
import {
  TextField,
} from '@/main/components'

import { ProgressIndicator } from '@naturacosmeticos/natds-web/dist/Components/ProgressIndicator'
import { DigitalStoreHintText } from '../digital-store-hint-text'

import { DigitalStoreNameValidationLabel } from './digital-store-name-validation-label'
import {
  Container, StyledFormControl, StyledButton, Wrapper,
} from './digital-store-name-validation.styles'

export type DigitalStoreNameValidationProps = {
  className?: string
  isLoading: boolean
  buttonLabel: string
  tooltipText: string
  inputLabel: React.ReactNode
  hintText: string
  ButtonProps?: {
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    onChange?: React.FormEventHandler<HTMLButtonElement>
    disabled?: boolean
  }
  TextFieldProps?: {
    helpText?: string
    state?: string
    onChange?: React.FormEventHandler<HTMLInputElement>
    disabled?: boolean
    value?: string
  }
}

export const DigitalStoreNameValidation = ({
 className, isLoading, buttonLabel, tooltipText, hintText, inputLabel, ButtonProps, TextFieldProps,
}: DigitalStoreNameValidationProps) => (
  <Wrapper className={className}>
    <Container>
      <StyledFormControl fullWidth>
        <TextField
          id="digital-store-name"
          label={<DigitalStoreNameValidationLabel tooltipText={tooltipText}>{inputLabel}</DigitalStoreNameValidationLabel>}
          {...TextFieldProps}
        />
      </StyledFormControl>
      <StyledButton
        className="verify-button"
        variant="contained"
        color="primary"
        size="large"
        {...ButtonProps}
      >
        {isLoading ? (
          <div>
            <ProgressIndicator
              data-testid="loading-verify"
              size={24}
            />
          </div>
         ) : buttonLabel}
      </StyledButton>
    </Container>
    {hintText ? <DigitalStoreHintText content={hintText} /> : null}
  </Wrapper>
)

import styled from 'styled-components'
import { TextField } from '@/main/components/text-field'
import { Link, Typography } from '@naturacosmeticos/natds-web'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'

export const AuthorizationCodeInfo = styled(Typography)`
  margin-bottom: 32px;
  max-width: 457px;
`

export const CardTitle = styled(Typography)`
  font-weight: 500;
`

export const ResendButton = styled(Link)<{disabled: boolean}>`
  @media ${minDeviceSizeForMediaQueries.tablet} {
    &.resend-button {
      margin-left: ${({ theme }) => `${theme.sizes.mediumx}px`};
    }
  }

  ${(props) => props.disabled && `
    &.resend-button {
      color: #0000003D;
      cursor: default;
      &:hover {
        text-decoration: none;
      }
    }
  `};
`

export const ContainerHorizontal = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    flex-direction: row;
  }
`

export const ResendInstructionsText = styled(Typography)`
  max-width: 230px;
  margin-left: 20px;
`

export const VerticalContainer = styled.div`
  flex: 1;
  margin-top: ${({ theme }) => `${theme.sizes.semi}px`};
`

export const ResendContainer = styled(VerticalContainer)`
  display: flex;
  flex-direction: column;
`
export const ResendInstructionsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    min-height: 40px;
  }
  margin-top: 15px;
`

export const StyledTextField = styled(TextField)`
  margin-bottom: 24px;
  > div {
    max-width: 202px;
  };
`

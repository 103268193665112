import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ProgressIndicator,
} from '@naturacosmeticos/natds-web/dist/Components/ProgressIndicator'
import { IdentityContext } from '@/main/contexts'
import {
  getMessagesByCountryRequest,
  MessagesState,
} from '@/infra/global-state/redux/store/ducks/messages'
import { ApplicationState } from '@/infra/global-state/redux/store'
import { Container } from './GlobalMessages.styles'

const getLocalizationCountry = (url, identityInfo): string => (
  identityInfo?.countryId || url.split('/')[1]
)

export const GlobalMessages = ({ children, loadMessages }) => {
  const dispatch = useDispatch()
  const identityInfo = useContext(IdentityContext)
  const country = getLocalizationCountry(window.location.pathname, identityInfo)

  const {
    isLoading,
  } = useSelector<ApplicationState, MessagesState>(
    (state) => state.messages,
  )

  useEffect(() => {
    if (loadMessages) {
      dispatch(getMessagesByCountryRequest(country))
    }
  }, [dispatch, country, loadMessages])

  const CustomCircularProgress = () => (
    <Container>
      <ProgressIndicator />
    </Container>
  )

  return loadMessages && isLoading ? <CustomCircularProgress /> : children
}

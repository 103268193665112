import { RequiredErrorMessage } from '@/domain/models/messages/messages'
import { ControlledTextField } from '@/main/components'
import React from 'react'
import { ControllerProps, useFormContext } from 'react-hook-form'

export type DocumentMessages = {
  label: string,
  placeholder: string,
  errorMessage: string,
} & RequiredErrorMessage

type DocumentTextFieldProps = {
  messages: DocumentMessages,
  id: string,
  validChars?: RegExp
  showAsteriskInRequiredLabel?: boolean,
} & Pick<ControllerProps<'input'>, 'rules'>

export const DocumentTextField: React.FC<DocumentTextFieldProps> = ({
  messages,
  id,
  validChars,
  rules,
  showAsteriskInRequiredLabel,
}) => {
  const { control } = useFormContext()

  const {
    label,
    placeholder,
    errorMessage,
  } = messages

  return (
    <ControlledTextField
      id={id}
      control={control}
      label={label}
      placeholder={placeholder}
      errorMessage={errorMessage}
      rules={rules}
      validChars={validChars}
      showAsterisk={showAsteriskInRequiredLabel}
      required
    />
  )
}

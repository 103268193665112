import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { GetMessagesByCountry } from '@/domain/use-cases/messages'
import { RemoteGetMessagesByCountry } from '@/data/use-cases/messages'

export const makeGetMessagesByCountry = (): GetMessagesByCountry => {
  const apiUrl = '/messages'
  const axiosBaseUrl = process.env.REGISTERS_BFF_URL
  const xApiKey = process.env.REGISTERS_BFF_X_API_KEY
  const httpGetClient = new AxiosHttpClient(axiosBaseUrl)

  const remoteGetMessagesByCountry = new RemoteGetMessagesByCountry(
    apiUrl,
    httpGetClient,
    xApiKey,
  )
  return remoteGetMessagesByCountry
}

import { AttachmentType } from '@/domain/models/attachments/attachments'
import { PagesTenantConfigs, DocumentationConfigs, AttachmentsConfigs } from './configs.d'
import { AgreementProduct } from '../../agreement/agreement-product'
import { Document } from '../../documents/documents'

const documentation: DocumentationConfigs = {
  shouldAcceptTermsWhenAuthenticated: false,
  agreementProducts: [AgreementProduct.NATURA],
}

const attachments: AttachmentsConfigs = {
  documents: [
    {
      type: Document.DniArgentina,
      name: 'DNI',
      attachments:
        [
          { title: 'Frente', type: AttachmentType.DniArgentinaFront },
          { title: 'Dorso', type: AttachmentType.DniArgentinaBack },
        ],
    },
  ],
  canChooseDocument: false,
}

export const naturaArgConfigs: PagesTenantConfigs = {
  documentation,
  personalData: null,
  attachments,
  preRegister: null,
  digitalStore: {
    showSkipButton: false,
  },
  address: {
    shouldUsePageWrapper: true,
  },
}

import { call, put } from 'redux-saga/effects'
import { makeGetMessagesByCountry } from '@/main/factories/remote'
import { GetMessagesByCountryParams } from '@/domain/use-cases/messages'
import { defaultMessages } from '@/utils/constants'
import { makeConvertCountryIdFromAlpha2ToAlpha3Presenter } from '@/main/factories/presenters'
import { getMessagesByCountryResponse } from '../actions'

const convertCountryIdFromAlpha2ToAlpha3Presenter = makeConvertCountryIdFromAlpha2ToAlpha3Presenter()

export function* getMessagesByCountry(action) {
  const { country } = action.payload
  const countryId = (country as string).length === 2 ? convertCountryIdFromAlpha2ToAlpha3Presenter.handle({ countryId: country }) : country

  try {
    const getMessagesByCountry = makeGetMessagesByCountry()
    const params: GetMessagesByCountryParams = {
      countryId,
    }
    const messages = yield call(getMessagesByCountry.get, params)
    yield put(getMessagesByCountryResponse(messages))
  } catch (errorResponse) {
    yield put(getMessagesByCountryResponse(defaultMessages))
  }
}

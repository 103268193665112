import React, { useContext } from 'react'
import { AuthenticatedUserContext, IdentityContext } from '@/main/contexts'
import { useI18n } from '@/main/hooks/useI18n'
import { AddressMessages } from '@/main/pages/address/models'
import { CountryId } from '@/domain/models/country'
import { ChileAddressPage } from '@/main/pages/address/chl/address-page.chl'
import { ColombiaAddressPage } from './col/address-page.col'
import { ArgentinaAddressPage } from './arg/address-page.arg'

const addressPageStrategy = (countryId, messages, authenticated) => {
  switch (countryId) {
    case CountryId.Argentina:
      return () => <ArgentinaAddressPage messages={messages} shouldDisplayZipCodeLink={!authenticated} />
    case CountryId.Colombia:
      return () => <ColombiaAddressPage messages={messages} />
    case CountryId.Chile:
      return () => <ChileAddressPage messages={messages} />
    default:
      throw new Error(`${countryId} is not configured`)
  }
}

export const AddressPageWrapper = () => {
  const { countryId } = useContext(IdentityContext)
  const { authenticated } = useContext(AuthenticatedUserContext)
  const { translate } = useI18n()

  const messages : AddressMessages = {
    title: translate('addressPage.title'),
    subtitle: translate('addressPage.subtitle'),
    zipCode: translate('addressPage.zipCode'),
    mainStreet: translate('addressPage.mainStreet'),
    number: translate('addressPage.number'),
    complementaryData: translate('addressPage.complementaryData'),
    checkBoxWithoutNumber: translate('addressPage.checkBoxWithoutNumber'),
    referencesHelperText: translate('addressPage.referencesHelperText'),
    references: translate('addressPage.references'),
    confirmDeliveryAddressAsResidenceAddress: translate('addressPage.confirmDeliveryAddressAsResidenceAddress'),
    nextButtonLabel: translate('nextButtonLabel'),
    previousButtonLabel: translate('previousButtonLabel'),
    optionalTextLabel: translate('optionalTextLabel'),
    addressRequiredError: translate('requiredErrorMessage'),
    zipCodeDigitsError: translate('addressPage.zipCodeDigitsError'),
    zipCodeLink: {
      text: translate('addressPage.zipCodeLink.text'),
      url: translate('addressPage.zipCodeLink.url'),
    },
    geographicalLevels: {
      level1: translate('geographicalLevels.level1'),
      level2: translate('geographicalLevels.level2'),
      level3: translate('geographicalLevels.level3'),
      level4: translate('geographicalLevels.level4'),
      placeholders: {
        level1: translate('geographicalLevels.placeholders.level1'),
        level2: translate('geographicalLevels.placeholders.level2'),
        level3: translate('geographicalLevels.placeholders.level3'),
        level4: translate('geographicalLevels.placeholders.level4'),
      },
    },
    delivery: {
      title: translate('addressPage.delivery.title'),
    },
    placeholders: {
      number: translate('addressPage.placeholders.number'),
      zipCode: translate('addressPage.placeholders.zipCode'),
      mainStreet: translate('addressPage.placeholders.mainStreet'),
      complementaryData: translate('addressPage.placeholders.complementaryData'),
      references: translate('addressPage.placeholders.references'),
    },
  }

  const AddressPage = addressPageStrategy(countryId, messages, authenticated)

  return AddressPage ? <AddressPage /> : null
}

import styled from 'styled-components'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 8rem);
  justify-content: space-between;
  position: relative;


  @media ${minDeviceSizeForMediaQueries.tablet} {
    height: calc(100vh - 9.6rem);
  }
`

type StatusBarProps = {
 status: string
}

export const StatusBar = styled.div<StatusBarProps>`
  position: fixed;
  top: 82px;
  width: 100%;
  height: 5px;
  background-color: ${({ status, theme }) => theme.color[status]};

  @media ${minDeviceSizeForMediaQueries.tablet} {
    top: 96px;
  }
`

import React from 'react'
import { Typography } from '@/main/components'

type DigitalStoreHintTextProps = {
  className?: string
  content: string
}

export const DigitalStoreHintText: React.FC<DigitalStoreHintTextProps> = ({ className, content }) => (
  <div className={className}>
    <Typography
      color="textSecondary"
      variant="caption"
    >
      {content}
    </Typography>
  </div>
)

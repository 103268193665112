import { HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import { HttpGetClient } from '@/data/protocols/http/http-get-client'
import { CountryId } from '@/domain/models/country'
import { BadRequestError } from '@/data/protocols/errors'
import { GetMessagesByCountry, GetMessagesByCountryParams, GetMessagesByCountryOutput } from '@/domain/use-cases/messages/get-messages-by-country'

export type GetMessagesByCountryHttpRequestHeaders = {
  countryId: CountryId,
  'x-api-key': string
}

export type GetMessagesByCountryHttpRequestResponseBody = {
  [key: string]: string
}

export class RemoteGetMessagesByCountry
  implements GetMessagesByCountry {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient<
      GetMessagesByCountryHttpRequestHeaders,
      GetMessagesByCountryHttpRequestResponseBody
      >,
      private readonly xApiKey: string,
  ) {}

  get = async (
    params: GetMessagesByCountryParams,
  ): Promise<GetMessagesByCountryOutput> => {
    const headers: GetMessagesByCountryHttpRequestHeaders = {
      countryId: params.countryId,
      'x-api-key': this.xApiKey,
    }
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      headers,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}

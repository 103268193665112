import React, { useEffect, useState } from 'react'
import { defaultMessages } from '@/utils/constants'
import {
  FalseLink,
  StyledLabel,
} from './accept-terms-component.styles'

type termsAgreementLabel = {
  agreeText: string
  agreeLinkText?: string
  onClick: any
}

export const TermsAgreementLabel: React.FC<termsAgreementLabel> = ({
  agreeText = defaultMessages.digitalStoreTermsAcceptance,
  agreeLinkText,
  onClick,
}) => {
  const [infoElement, setInfoElement] = useState(
    <StyledLabel>{agreeText}</StyledLabel>,
  )

  useEffect(() => {
    const [textBefore, textAfter] = agreeText.split(agreeLinkText)
    if (textAfter == null) {
      setInfoElement(
        <StyledLabel>
          <FalseLink onClick={onClick}>{agreeText}</FalseLink>
        </StyledLabel>,
      )
    } else {
      const newInfoElement = (
        <StyledLabel>
          {textBefore}
          <FalseLink onClick={onClick}>{agreeLinkText}</FalseLink>
          {textAfter}
        </StyledLabel>
      )
      setInfoElement(newInfoElement)
    }
  }, [agreeLinkText, agreeText, onClick])

  return infoElement
}

import {
  UploadAttachmentParams,
  UploadAttachmentResponseBody,
  ValidateAttachmentParams,
  ValidateAttachmentResponseBody,
} from '@/domain/use-cases/attachment'
import { GetPersonDataParams, PersonData } from '@/domain/use-cases/person'
import { makeGetPersonData } from '@/main/factories/remote'
import { makeRemoteValidateAttachment, makeRemoteUploadAttachment } from '@/main/factories/remote/attachment'

export type AttachmentsPageApi = {
  upload: (params: UploadAttachmentParams) => Promise<UploadAttachmentResponseBody>
  getPersonData: (data: GetPersonDataParams) => Promise<PersonData | {} | null | unknown>
  validate: (params: ValidateAttachmentParams) => Promise<ValidateAttachmentResponseBody[]>
}
export const makeAttachmentsPageApi = (): AttachmentsPageApi => {
  const validator = makeRemoteValidateAttachment()
  const validate = (params: ValidateAttachmentParams) => validator.validate(params)

  const uploader = makeRemoteUploadAttachment()
  const upload = (params: UploadAttachmentParams) => uploader.upload(params)

  const personDataGetter = makeGetPersonData()
  const getPersonData = (data: GetPersonDataParams) => personDataGetter.get(data)

  return { upload, getPersonData, validate }
}

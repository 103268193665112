import { Document } from '@/domain/models/documents/documents'
import { EventType } from '@/domain/models/events/events'
import { PreRegisterMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { PreRegisterConfigs } from '@/domain/models/tenant/tenant-configs'
import { CardContent, CardHeader } from '@/main/components'
import { ExternalLink } from '@/main/components/external-link/external-link'
import { Container } from '@/main/components/pages-structures'
import { FormContext, IdentityContext } from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import React, { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { DialogAlert } from '@/main/components/dialog-alert/dialog-alert'
import { DocumentTextField } from './fields/document/document-text-field'

type FormInputs = {
  curp: string,
}

type FormParsedValues = {
  documents: {
    type: Document,
    document: string,
  }[]
}

const showDialogInitialState = false
const showLoadingInitialState = false
const documentTypeSelectedInitialState = Document.Curp

export const PreRegisterPage: React.FC = () => {
  const { onSubmit, goToNextPage } = useContext(FormContext)
  const { tenantId } = useContext(IdentityContext)

  const [showDialogAlert, setShowDialogAlert] = useState(showDialogInitialState)
  const [showLoading, setShowLoading] = useState(showLoadingInitialState)
  const [documentTypeSelected] = useState<Document>(documentTypeSelectedInitialState)

  const formMethods = useForm<FormInputs>({
    mode: 'onTouched',
  })

  const {
    getValues,
    formState,
  } = formMethods

  const {
    nextButtonLabel,
    title,
    subtitle,
    document,
    link: linkMessage,
    dialog,
  } = usePageMessages(Page.PreRegister).messages as PreRegisterMessages

  const { requiredErrorMessage } = document

  const {
    shouldShowCurp,
    link: linkConfig,
    documents,
  } = useTenantConfigs(tenantId, Page.PreRegister) as PreRegisterConfigs

  const curp = documents.find(({ type }) => type === Document.Curp)

  const handleSubmit = () => {
    setShowLoading(true)

    const values = getValues()
    const parsedValues = parsedFormValues(values)

    onSubmit(parsedValues, () => {
      setShowLoading(false)
      goToNextPage()
    })
  }

  const parsedFormValues = (values: FormInputs): FormParsedValues => ({
    documents: [{
      type: documentTypeSelected,
      document: values.curp,
    }],
  })

  useEffect(() => {
    function openDialogAlert() {
      setShowDialogAlert(true)
    }

    window.addEventListener(
      EventType.PRE_REGISTER_OPEN_DIALOG_ERROR,
      openDialogAlert,
    )

    return () => window.removeEventListener(
      EventType.PRE_REGISTER_OPEN_DIALOG_ERROR,
      openDialogAlert,
    )
  }, [])

  return (
    <>
      <Container
        nextButtonLabel={nextButtonLabel}
        onNextButtonClick={handleSubmit}
        isLoading={showLoading}
        disableNextButton={!formState.isValid}
      >
        <CardHeader
          title={title}
          subtitle={subtitle}
        />
        <CardContent>
          <FormProvider {...formMethods}>
            {shouldShowCurp
              && (
                <DocumentTextField
                  id={curp.name}
                  messages={document}
                  validChars={curp.regex}
                  rules={{
                    required: requiredErrorMessage,
                    validate: curp.validations.length,
                  }}
                  showAsteriskInRequiredLabel={false}
                />
              )}
            {linkConfig
              && (
                <ExternalLink text={linkMessage.text} href={linkConfig.href} />
              )}
          </FormProvider>
        </CardContent>
      </Container>
      <DialogAlert show={showDialogAlert} messages={dialog} />
    </>
  )
}

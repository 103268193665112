import {
  IndicationInfo,
  RemoteSearchIndicationParams, SearchIndicationStatus,
} from '@/data/use-cases/indication/remote-search-indication'
import { makeRemoteSearchIndication } from '@/main/factories/remote/indication'

export const searchIndication = async (params: RemoteSearchIndicationParams): Promise<IndicationInfo> => {
  const remoteSearchIndication = makeRemoteSearchIndication()

  const indicationResponse = await remoteSearchIndication.search({
    id: params.id,
    companyId: params.companyId,
    countryId: params.countryId,
  })

  switch (indicationResponse.status) {
    case SearchIndicationStatus.SUCCESS:
      return indicationResponse.data
    case SearchIndicationStatus.NOT_FOUND:
      throw new Error('searchIndicationNotFound')
    default:
      throw new Error('searchIndicationError')
  }
}

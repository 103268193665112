export enum MessagesTypes {
  GET_MESSAGES_BY_COUNTRY_REQUEST = '@messages/GET_MESSAGES_BY_COUNTRY_REQUEST',
  GET_MESSAGES_BY_COUNTRY_RESPONSE = '@messages/GET_MESSAGES_BY_COUNTRY_RESPONSE',
}

export type messagesResponseType = {
  [key: string]: string
}

export interface MessagesState {
  readonly messages: messagesResponseType
  readonly isLoading: boolean
}

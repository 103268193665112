import { PagesTenantConfigs, DocumentationConfigs, AttachmentsConfigs } from './configs.d'
import { AgreementProduct } from '../../agreement/agreement-product'
import { AttachmentType } from '../../attachments/attachments'
import { Document } from '../../documents/documents'

const documentation: DocumentationConfigs = {
  shouldAcceptTermsWhenAuthenticated: false,
  agreementProducts: [AgreementProduct.NATURA, AgreementProduct.BUREAU],
}

const attachments: AttachmentsConfigs = {
  documents: [
    {
      type: Document.CitizenCard,
      name: 'Cédula de Ciudadanía',
      attachments:
        [
          { title: 'Frente', type: AttachmentType.CitizenCardFront },
          { title: 'Reverso', type: AttachmentType.CitizenCardBack },
        ],
    },
    {
      type: Document.ForeignCard,
      name: 'Cédula de Extranjería',
      attachments:
        [
          { title: 'Frente', type: AttachmentType.ForeignCardFront },
          { title: 'Reverso', type: AttachmentType.ForeignCardBack },
        ],
    },
  ],
  canChooseDocument: false,
}

export const naturaColConfigs: PagesTenantConfigs = {
  documentation,
  personalData: null,
  attachments,
  preRegister: null,
  digitalStore: {
    showSkipButton: false,
  },
  address: {
    shouldUsePageWrapper: true,
  },
}

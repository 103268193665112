import { PagesTenantConfigs } from './configs.d'

export const naturaMysConfigs: PagesTenantConfigs = {
  documentation: null,
  personalData: null,
  attachments: null,
  preRegister: null,
  digitalStore: {
    showSkipButton: false,
  },
  address: {
    shouldUsePageWrapper: null,
  },
}

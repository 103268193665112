import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web'

export const useAttachmentsPageStyles = makeStyles<Theme>((theme) => ({
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  headerTag: {
    marginLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  subtitleIcon: {
    marginBottom: '-6px',
    marginLeft: '3px',
  },
  uploadFile: {
    marginTop: theme.spacing(2),
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '52px',
  },
}))

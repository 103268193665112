import React, {
 useContext, useEffect, useState,
} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Container } from '@/main/components/pages-structures'
import { Divider } from '@naturacosmeticos/natds-web/dist/Components/Divider'
import { Spacing } from '@naturacosmeticos/natds-web/dist/Components/Spacing'
import {
  CardHeader,
  ControlledAddressNumberField,
  ControlledTextField,
  CardContent, CardTitle, GeoCommercialConfig, GeoCommercialStructure, SelectOption,
} from '@/main/components'
import { Checkbox } from '@/main/components/checkbox'
import { useAddressPageStyles } from '@/main/pages/address/address-page.styles'
import { FormContext, FormContextProps } from '@/main/contexts'
import { adaptDefaultValues } from '@/main/pages/address/adapters/input-adapter'
import {
  adaptFormValuesWithAnotherDeliveryAddress,
  adaptFormValuesWithDeliveryAddressCopied,
} from '@/main/pages/address/adapters/output-adapter'
import { CountryId } from '@/domain/models/country'
import { AddressFormValues } from '@/main/pages/address/models'
import { AddressPageProps } from '@/main/pages/address/address-page-props'
import { ControlledTextFieldWithLink } from '@/main/components/text-field/text-field-with-link/controlled-text-field-with-link'

export function createZipCodeLengthValidation(lengthValue: number): (value: number) => boolean {
  return (value) => value.toString().length >= lengthValue
}

const geoCommercialLevelsAdapter = {
  level1: 'level1',
  level2: 'level2',
  level3: 'level3',
  level4: 'level4',
}

type ArgentinaGeoCommercialLevelsIds = {
  level1?: SelectOption,
  level2?: SelectOption,
  level3?: SelectOption,
  level4?: SelectOption,
}

type CustomArgentinaAddressPageProps = {
  shouldDisplayZipCodeLink: boolean,
}

export const ArgentinaAddressPage: React.FC<AddressPageProps & CustomArgentinaAddressPageProps> = ({ messages, shouldDisplayZipCodeLink }) => {
  const {
   person, onSubmit, goToPreviousPage = () => {}, goToNextPage = () => {},
  } = useContext<FormContextProps>(FormContext)

  const defaultValues = adaptDefaultValues<ArgentinaGeoCommercialLevelsIds>(person, geoCommercialLevelsAdapter)
  const formMethods = useForm<AddressFormValues & ArgentinaGeoCommercialLevelsIds>({
    mode: 'onTouched',
    defaultValues,
  })
  const {
    control, formState: { isValid, isSubmitting }, getValues, errors,
  } = formMethods
  const [copyAddress, setCopyAddress] = useState<boolean>(true)

  const zipCodeValidChars = new RegExp(/^\d{0,4}$/)
  const addressNumberValidChars = new RegExp(/^\d+$/)

  useEffect(() => {
    if (!defaultValues.level2) {
      return
    }
    setCopyAddress(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formRules = { required: messages.addressRequiredError }
  const zipCodeRules = {
    rules: {
      ...formRules,
      validate: createZipCodeLengthValidation(4),
    },
    errors: {
      validate: messages.zipCodeDigitsError,
    },
  }
  const makeZipCodeField = (id) => {
    const props = {
      id,
      label: messages.zipCode,
      control,
      rules: zipCodeRules.rules,
      errorMessage: zipCodeRules.errors,
      placeholder: messages.placeholders.zipCode,
      validChars: zipCodeValidChars,
      errors,
    }
    return (shouldDisplayZipCodeLink ? (
      <ControlledTextFieldWithLink
        {...props}
        linkText={messages.zipCodeLink.text}
        href={messages.zipCodeLink.url}
      />
      ) : (
        <ControlledTextField
          {...props}
          showAsterisk={false}
          required
        />
      )
    )
  }

  const addressPageClasses = useAddressPageStyles()

  const onNextButtonClick = () => {
    const formValues = getValues()
    const parsedValue = copyAddress ? adaptFormValuesWithDeliveryAddressCopied(formValues)
       : adaptFormValuesWithAnotherDeliveryAddress(formValues, geoCommercialLevelsAdapter)
    onSubmit(parsedValue, goToNextPage)
  }

  const geoCommercialConfig: GeoCommercialConfig = {
    level1: {
      id: 'level1',
      label: messages.geographicalLevels.level1,
      placeholder: messages.geographicalLevels.placeholders.level1,
    },
    level2: {
      id: 'level2',
      label: messages.geographicalLevels.level2,
      placeholder: messages.geographicalLevels.placeholders.level2,
    },
    level3: {
      id: 'level3',
      label: messages.geographicalLevels.level3,
      placeholder: messages.geographicalLevels.placeholders.level3,
    },
    level4: {
      id: 'level4',
      label: messages.geographicalLevels.level4,
      placeholder: messages.geographicalLevels.placeholders.level4,
    },
    requiredErrorMessage: messages.addressRequiredError,
  }

  return (
    <Container
      previousButtonLabel={messages.previousButtonLabel}
      onPreviousButtonClick={goToPreviousPage}
      nextButtonLabel={messages.nextButtonLabel}
      onNextButtonClick={onNextButtonClick}
      disableNextButton={!isValid}
      isLoading={isSubmitting}
    >
      <CardHeader
        title={messages.title}
        subtitle={messages.subtitle}
      />
      <CardContent>
        <FormProvider {...formMethods}>
          {makeZipCodeField('zipCode')}
          <ControlledTextField
            id="street"
            label={messages.mainStreet}
            control={control}
            rules={formRules}
            placeholder={messages.placeholders.mainStreet}
            showAsterisk={false}
            required
          />
          <div className={addressPageClasses.formRow}>
            <div className={addressPageClasses.numberInput}>
              <ControlledAddressNumberField
                id="number"
                label={messages.number}
                placeholder={messages.placeholders.number}
                requiredErrorMessage={messages.addressRequiredError}
                noNumberLabel={messages.checkBoxWithoutNumber}
                showAsterisk={false}
                validChars={addressNumberValidChars}
              />
            </div>
            <div className={addressPageClasses.complementInput}>
              <ControlledTextField
                id="complement"
                label={messages.complementaryData}
                control={control}
                placeholder={messages.placeholders.complementaryData}
                optionalText={messages.optionalTextLabel}
              />
            </div>
          </div>
          <ControlledTextField
            id="references"
            label={messages.references}
            control={control}
            placeholder={messages.placeholders.references}
            showAsterisk={false}
            infoText={messages.referencesHelperText}
            optionalText={messages.optionalTextLabel}
          />
          <div className={addressPageClasses.copyAddressCheckbox}>
            <Checkbox
              label={messages.confirmDeliveryAddressAsResidenceAddress}
              checked={copyAddress}
              onChange={(e) => setCopyAddress(e.target.checked)}
            />
          </div>
          {!copyAddress && (
            <>
              <Spacing marginY="semi">
                <Divider />
              </Spacing>
              <CardTitle
                title={messages.delivery.title}
              />
              {makeZipCodeField('deliveryZipCode')}
              <GeoCommercialStructure customConfig={geoCommercialConfig} countryId={CountryId.Argentina} />
              <ControlledTextField
                id="deliveryStreet"
                label={messages.mainStreet}
                control={control}
                rules={formRules}
                placeholder={messages.placeholders.mainStreet}
                showAsterisk={false}
                required
              />
              <div className={addressPageClasses.formRow}>
                <div className={addressPageClasses.numberInput}>
                  <ControlledAddressNumberField
                    id="deliveryNumber"
                    label={messages.number}
                    placeholder={messages.placeholders.number}
                    requiredErrorMessage={messages.addressRequiredError}
                    noNumberLabel={messages.checkBoxWithoutNumber}
                    showAsterisk={false}
                    validChars={addressNumberValidChars}
                  />
                </div>
                <div className={addressPageClasses.complementInput}>
                  <ControlledTextField
                    id="deliveryComplement"
                    label={messages.complementaryData}
                    control={control}
                    placeholder={messages.placeholders.complementaryData}
                    optionalText={messages.optionalTextLabel}
                  />
                </div>
              </div>
              <ControlledTextField
                id="deliveryReferences"
                label={messages.references}
                control={control}
                placeholder={messages.placeholders.references}
                showAsterisk={false}
                infoText={messages.referencesHelperText}
                optionalText={messages.optionalTextLabel}
              />
            </>
          )}
        </FormProvider>
      </CardContent>
    </Container>
  )
}

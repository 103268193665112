import styled from 'styled-components'
import { NumberTextField } from '@/main/components/text-field/number-text-field'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'

export const SearchContainer = styled.div`
  display: flex;
  align-items: baseline;
  width: 100%;
`

export const SearchField = styled(NumberTextField)`
  &.search-field {
    margin-right: ${({ theme }) => `${theme.sizes.small}px`};
    flex: 1;
  }

  @media ${minDeviceSizeForMediaQueries.tablet} {
    &.search-field {
      flex: 0.6;
    }
  }
`

export const IndicationInfoContainer = styled.div`
  margin-top: ${({ theme }) => `${theme.sizes.small}px`};
`

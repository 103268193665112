import React from 'react'
import { Controller, ControllerProps, FieldErrors } from 'react-hook-form'
import { TextField, FormHelperText } from '@naturacosmeticos/natds-web'
import { useControlledTextFieldStyles } from '@/main/components/text-field/controlled-text-field.styles'
import { Mask } from '@naturacosmeticos/natds-web/dist/Components/Input/Input.props'
import { handleValidCharsOnChange } from './helper/handle-valid-chars'
import { getFinalErrorMessage, StringOrObjectOfStrings } from './helper/error-message-getter'

export type ControlledTextFieldProps = {
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  errorMessage?: StringOrObjectOfStrings;
  validChars?: RegExp
  infoText?: string;
  mask?: Mask;
  errors?: FieldErrors;
  showAsterisk?: boolean;
  optionalText?: string
} & Pick<ControllerProps<'input'>, 'rules' | 'control'>

export const ControlledTextField: React.FC<ControlledTextFieldProps> = ({
  id,
  control,
  label,
  placeholder,
  required,
  disabled,
  rules,
  errorMessage,
  validChars,
  infoText,
  mask,
  errors,
  showAsterisk = true,
  optionalText,
}) => {
  const controlledTextFieldClasses = useControlledTextFieldStyles()

  const getLabel = (labelText) => {
    if (required && showAsterisk) {
      return `${labelText} *`
    }
    if (!required && optionalText) {
      return `${labelText} ${optionalText}`
    }
    return labelText
  }

  const finalErrorMessage = getFinalErrorMessage(errors, errorMessage, id)
  const labelFormatted = getLabel(label)

  return (
    <div className={controlledTextFieldClasses.container}>
      <Controller
        name={id}
        control={control}
        defaultValue=""
        rules={rules}
        render={(
          {
            onBlur, onChange, value, name, ref,
          },
          { invalid },
        ) => (
          <>
            <TextField
              ref={ref}
              onBlur={onBlur}
              onChange={(event) => handleValidCharsOnChange(event, onChange, validChars)}
              value={value}
              name={name}
              id={id}
              data-testid={id}
              label={labelFormatted}
              placeholder={placeholder}
              disabled={disabled}
              state={invalid ? 'error' : undefined}
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              // @ts-ignore
              helpText={invalid ? invalid.message || finalErrorMessage : undefined}
              mask={mask}
            />
            <FormHelperText>{infoText}</FormHelperText>
          </>
        )}
      />
    </div>
  )
}

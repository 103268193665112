import { call, put } from 'redux-saga/effects'
import { GetDigitalStoreInfoParams } from '@/domain/use-cases/digital-store'
import { makeGetDigitalStoreInfo } from '@/main/factories/remote/digital-store'
import { getDigitalStoreInfoSuccess } from '../actions'

export function* getDigitalStoreInfo(action) {
  const { personId } = action.payload
  try {
    const getDigitalStoreInfo = makeGetDigitalStoreInfo()
    const params: GetDigitalStoreInfoParams = {
      personId,
    }
    const digitalStoreName = yield call(getDigitalStoreInfo.get, params)
    yield put(getDigitalStoreInfoSuccess(digitalStoreName))
  } catch (errorResponse) {
    yield put(getDigitalStoreInfoSuccess(''))
  }
}

import { TextParagraph } from '@/main/components'
import styled from 'styled-components'

export const DigitalStoreUrlSection = styled.div`
  font-family: Roboto;
  line-height: 20px;
  color: #777777;
`

export const DigitalStoreAddressLabel = styled(TextParagraph)`
  font-size: 1.2rem;
`

export const BoldTextParagraph = styled(TextParagraph)`
  font-weight: bold;
  color: #333333;
  font-size: 1.4rem;
`

import styled from 'styled-components'
import {
  DialogContent as NatDsDialogContent,
} from '@/main/components/dialog'

export const DialogContent = styled(NatDsDialogContent)`
  &.dialog-content {
    padding: 0;

    &:first-child {
      padding: 0;
    }
  }
`

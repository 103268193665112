import styled from 'styled-components'
import { Button } from '@/main/components'

export const RecommenderFieldContainer = styled.div`
  flex: 1;
`

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

export const SearchButton = styled(Button)`
  &.search-recommender-button {
    margin: 0 0 0 ${({ theme }) => `${theme.sizes.small}px`};
  }
`

import React from 'react'

import { TextParagraph, Spacing, Divider } from '@/main/components'

import {
 Container, StyledCardHeader, AdvantageBlock, AdvantagesTitle, AdvantageItemTitle, AdvantagesTitleBar, AdvantageItem,
} from './digital-store-advantages.styles'

export const DigitalStoreAdvantages = ({ advantagesTexts }) => (
  <Container>
    <AdvantagesTitle>
      <AdvantagesTitleBar />
      <StyledCardHeader
        title={advantagesTexts.title}
      />
    </AdvantagesTitle>
    <AdvantageBlock>
      <AdvantageItem>
        <AdvantageItemTitle>
          {advantagesTexts.firstItemTitle}
        </AdvantageItemTitle>
        <TextParagraph
          content={advantagesTexts.firstItemDescription}
        />
      </AdvantageItem>
      <AdvantageItem>
        <AdvantageItemTitle>
          {advantagesTexts.secondItemTitle}
        </AdvantageItemTitle>
        <TextParagraph
          content={advantagesTexts.secondItemDescription}
        />
      </AdvantageItem>
      <AdvantageItem>
        <AdvantageItemTitle>
          {advantagesTexts.thirdItemTitle}
        </AdvantageItemTitle>
        <TextParagraph
          content={advantagesTexts.thirdItemDescription}
        />
      </AdvantageItem>
    </AdvantageBlock>
    <Spacing marginTop="semi">
      <Divider />
    </Spacing>
  </Container>
  )

import { PagesTenantConfigs, DocumentationConfigs, DigitalStoreConfigs } from './configs.d'
import { AgreementProduct } from '../../agreement/agreement-product'

const documentation: DocumentationConfigs = {
  shouldAcceptTermsWhenAuthenticated: true,
  agreementProducts: [AgreementProduct.NATURA],
}
const digitalStore: DigitalStoreConfigs = {
  showSkipButton: true,
}

export const naturaPerConfigs: PagesTenantConfigs = {
  documentation,
  personalData: null,
  attachments: null,
  preRegister: null,
  digitalStore,
  address: {
    shouldUsePageWrapper: null,
  },
}

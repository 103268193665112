export const CountryCodes = {
  nationality_afghan: 9,
  nationality_albanian: 10,
  nationality_american: 8,
  nationality_andorran: 12,
  nationality_angolan: 13,
  nationality_anguillan: 14,
  nationality_antartic: 15,
  nationality_antiguans: 16,
  nationality_arab: 18,
  nationality_arubean: 64,
  nationality_algerian: 19,
  nationality_argentinean: 2,
  nationality_armenian: 20,
  nationality_australian: 21,
  nationality_austrian: 22,
  nationality_azerbaijani: 23,
  nationality_bahamian: 24,
  nationality_bahraini: 27,
  nationality_bangladeshi: 25,
  nationality_barbadian: 26,
  nationality_batswana: 32,
  nationality_belarusian: 33,
  nationality_belgian: 28,
  nationality_belizean: 29,
  nationality_beninese: 30,
  nationality_bermudian: 31,
  nationality_bhutanese: 41,
  nationality_bolivian: 34,
  nationality_bosnian: 35,
  nationality_bouvet_island: 105,
  nationality_brazilian: 1,
  nationality_british: 36,
  nationality_bruneian: 37,
  nationality_bulgarian: 38,
  nationality_burkinabe: 39,
  nationality_burmese: 151,
  nationality_burundian: 40,
  nationality_cambodian: 44,
  nationality_cameroonian: 43,
  nationality_canadian: 45,
  nationality_cape_verdean: 42,
  nationality_central_african: 47,
  nationality_chadian: 48,
  nationality_chilean: 3,
  nationality_chinese: 51,
  nationality_colombian: 4,
  nationality_comoran: 55,
  nationality_congolese: 56,
  nationality_cook_island: 106,
  nationality_costa_rican: 59,
  nationality_corean: 57,
  nationality_croatian: 60,
  nationality_cuban: 61,
  nationality_cypriot: 54,
  nationality_czech: 49,
  nationality_danish: 69,
  nationality_djibouti: 70,
  nationality_dominican: 72,
  nationality_dutch: 97,
  nationality_east_timorese: 198,
  nationality_ecuadorean: 74,
  nationality_egyptian: 73,
  nationality_equatorial_guinean: 63,
  nationality_eritrean: 62,
  nationality_estonian: 78,
  nationality_ethiopian: 79,
  nationality_fijian: 80,
  nationality_filipino: 81,
  nationality_finnish: 82,
  nationality_french: 7,
  nationality_gabonese: 83,
  nationality_gambian: 84,
  nationality_georgian: 86,
  nationality_german: 11,
  nationality_ghanaian: 85,
  nationality_gibraltar: 87,
  nationality_greek: 89,
  nationality_grenadian: 88,
  nationality_guatemalan: 90,
  nationality_guinea_bissauan: 93,
  nationality_guinean: 92,
  nationality_guyanese: 91,
  nationality_haitian: 94,
  nationality_honduran: 98,
  nationality_hong_kong: 99,
  nationality_hungarian: 100,
  nationality_i_kiribati: 65,
  nationality_icelander: 116,
  nationality_indian: 111,
  nationality_indonesian: 112,
  nationality_iranian: 113,
  nationality_iraqi: 114,
  nationality_irish: 115,
  nationality_israeli: 117,
  nationality_italian: 118,
  nationality_ivorian: 58,
  nationality_jamaican: 120,
  nationality_japanese: 121,
  nationality_jordanian: 122,
  nationality_kazakhstani: 71,
  nationality_kenyan: 168,
  nationality_kittian_and_nevisian: 66,
  nationality_kuwaiti: 123,
  nationality_kyrgyz: 169,
  nationality_laotian: 124,
  nationality_latvian: 127,
  nationality_lebanese: 128,
  nationality_liberian: 129,
  nationality_libyan: 130,
  nationality_liechtensteiner: 131,
  nationality_lithuanian: 132,
  nationality_luxembourger: 133,
  nationality_macedonian: 134,
  nationality_malagasy: 138,
  nationality_malawian: 136,
  nationality_malaysian: 135,
  nationality_maldivian: 137,
  nationality_malian: 139,
  nationality_maltese: 140,
  nationality_marshallese: 107,
  nationality_mauritanian: 144,
  nationality_mauritian: 143,
  nationality_mexican: 6,
  nationality_micronesian: 145,
  nationality_moldovan: 147,
  nationality_monacan: 148,
  nationality_montserrat: 150,
  nationality_mongolian: 149,
  nationality_moroccan: 142,
  nationality_mosotho: 126,
  nationality_mozambican: 146,
  nationality_namibian: 152,
  nationality_nauruan: 153,
  nationality_nepalese: 155,
  nationality_new_zealander: 154,
  nationality_ni_vanuatu: 209,
  nationality_nicaraguan: 156,
  nationality_nigerian: 157,
  nationality_nigerien: 158,
  nationality_niue_island: 108,
  nationality_norwegian: 159,
  nationality_norfolk_island: 109,
  nationality_omani: 160,
  nationality_pakistani: 164,
  nationality_palauan: 161,
  nationality_panamanian: 162,
  nationality_papua_new_guinean: 163,
  nationality_paraguayan: 165,
  nationality_peruvian: 5,
  nationality_polish: 166,
  nationality_portuguese: 167,
  nationality_qatari: 46,
  nationality_romanian: 170,
  nationality_russian: 172,
  nationality_rwandan: 171,
  nationality_saint_lucian: 178,
  nationality_salvadoran: 174,
  nationality_samoan: 176,
  nationality_san_marinese: 175,
  nationality_san_vicent: 180,
  nationality_saint_helenians: 177,
  nationality_sao_tomean: 179,
  nationality_saudi: 182,
  nationality_senegalese: 184,
  nationality_seychellois: 183,
  nationality_sierra_leonean: 125,
  nationality_singaporean: 53,
  nationality_slovakian: 75,
  nationality_slovenian: 76,
  nationality_solomon_islander: 173,
  nationality_somali: 186,
  nationality_south_african: 191,
  nationality_spanish: 77,
  nationality_sri_lankan: 52,
  nationality_sudanese: 188,
  nationality_surinamer: 192,
  nationality_stateless: 17,
  nationality_swazi: 187,
  nationality_swedish: 189,
  nationality_swiss: 190,
  nationality_syrian: 185,
  nationality_taiwanese: 67,
  nationality_tajik: 193,
  nationality_tanzanian: 195,
  nationality_thai: 194,
  nationality_togolese: 199,
  nationality_tokelau: 110,
  nationality_tongan: 200,
  nationality_trinidadian_or_tobagonian: 68,
  nationality_tunisian: 201,
  nationality_turkish: 202,
  nationality_turkmens: 203,
  nationality_tuvaluan: 204,
  nationality_turks_caicos: 101,
  nationality_ugandan: 206,
  nationality_ukrainian: 205,
  nationality_us_minor_islands: 104,
  nationality_uruguayan: 207,
  nationality_uzbekistani: 208,
  nationality_vaticano: 210,
  nationality_venezuelan: 211,
  nationality_vietnamese: 212,
  nationality_wallis_futuna_island: 102,
  nationality_western_sahara: 181,
  nationality_yemenite: 103,
  nationality_yugoslavian: 119,
  nationality_zairense: 213,
  nationality_zambian: 214,
  nationality_zimbabwean: 215,
}

export const DefaultNationalityByCountryId = {
  PER: 'nationality_peruvian',
  MYS: 'nationality_malaysian',
  COL: 'nationality_colombian',
  CHL: 'nationality_chilean',
  ARG: 'nationality_argentinean',
}

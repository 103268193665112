import { AxiosHttpClient } from '@/infra/http/axios-http-client'
import { SendAuthorizationCode } from '@/domain/use-cases/authorization-code/authorization-code'
import { RemoteSendAuthorizationCode } from '@/data/use-cases/authorization-code'

export const makeRemoteSendAuthorizationCode = (): SendAuthorizationCode => {
    const axiosBaseUrl = process.env.REGISTERS_BFF_URL_AUTHENTICATED
    const httpPostClient = new AxiosHttpClient(axiosBaseUrl)

    return new RemoteSendAuthorizationCode(
        httpPostClient,
    )
}
